export const LOGIN_PATH = '/auth/login';
export const PATH_AFTER_LOGIN = 'vehicles';
export const MAIN_BREAKPOINT = 'md';

export const STORAGE_KEY = 'accessToken';
export const STORAGE_USER = 'accessUser';
export const STORAGE_USER_ACCOUNT = 'accessUserAccount';
export const STORAGE_EXPIRES_AT = 'tokenExpiresAt';

export const Permissions = {
  All: 0,
  Dashboard: 1,
}

export const GPSServer = {
  GPSGate: 0,
}

export const CostType = {
  OtherCost: 0,
  FixedCost: 1,
}

export const MidPointType = {
  MidPoint: 0,
  Track: 1,
}

export const JobStatusTemplate = {
  Scheduled: 0,
  Started: 1,
  On_Transit: 2,
  At_Destination: 3,
  Coming_Back: 4,
  Completed: 5,
  Cancelled: 6,
  Failed: 7,
}

export const JobBookingStatusTemplate = {
  Draft: 0,
  Scheduled: 1,
  Dispatched: 2,
  Cancelled: 3,
}

export const RouteTaskActivity = {
  Load: 0,
  Unload: 1,
}