/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, prepareDate, saveDate } from '../../utils/utils';
import Yup from '../../utils/yup';
// import {  } from 'src/utils/enums';


export const fields = {
  jobNumber: {
    id: 'jobNumber',
    label: 'Trip ID',
    placeholder: '',
  },
  tripProgress: {
    id: 'tripProgress',
    label: 'Trip progress',
    placeholder: '',
  },

  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Select vehicle',
  },
  driverId: {
    id: 'driverId',
    label: 'Driver',
    placeholder: 'Select driver',
  },
  jobDate: {
    id: 'jobDate',
    label: 'Job date',
    placeholder: 'Select date',
  },
  driverMobile: {
    id: 'driverMobile',
    label: 'Driver mobile',
    placeholder: 'Enter driver mobile',
  },
  cargoType: {
    id: 'cargoType',
    label: 'Cargo type',
    placeholder: 'Enter cargo type',
  },
  vehicleCargoVolume: {
    id: 'vehicleCargoVolume',
    label: 'Volume',
    placeholder: 'Enter volume',
  },
  trailerId: {
    id: 'trailerId',
    label: 'Trailer ID',
    placeholder: 'Enter trailer ID',
  },
  clientId: {
    id: 'clientId',
    label: 'Client',
    placeholder: 'Select client',
  },
  currentLocation: {
    id: 'currentLocation',
    label: 'Current location',
    placeholder: 'Enter current location',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    vehicleId: Yup.number().nullable().required().label(fields.vehicleId.label),
    driverId: Yup.number().nullable().required().label(fields.driverId.label),
    jobDate: Yup.string().nullable().required().label(fields.jobDate.label),
    driverMobile: Yup.string().nullable().label(fields.driverMobile.label),
    cargoType: Yup.string().nullable().label(fields.cargoType.label),
    cargoVolume: Yup.number().nullable().min(0).label(fields.vehicleCargoVolume.label),
    trailerId: Yup.string().nullable().label(fields.trailerId.label),
    clientId: Yup.number().nullable().required().label(fields.clientId.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let jobDate = prepareDate(form.jobDate);

    let jobId = (form && form.jobId) ? form.jobId : null;

    let vehicle = (form && form.vehicle) ? form.vehicle : null;
    let vehicleId = (vehicle && vehicle.vehicleId) ? vehicle.vehicleId : null;
    let vehicleName = (vehicle && vehicle.vehicleName && vehicle.vehicleName !== '') ? vehicle.vehicleName : '';

    let driver = (form && form.driver) ? form.driver : null;
    let driverId = (driver && driver.driverId) ? driver.driverId : null;
    let driverName = (driver && driver.driverName && driver.driverName !== '') ? driver.driverName : '';

    let driverMobile = (form && form.driverMobile && form.driverMobile !== '') ? form.driverMobile : '';
    let cargoType = (form && form.cargoType && form.cargoType !== '') ? form.cargoType : '';
    let cargoVolume = (form && form.volume) ? form.volume : null;
    let trailerId = (form && form.trailerId && form.trailerId !== '') ? form.trailerId : '';
  
    let client = (form && form.client) ? form.client : null;
    let clientId = (client && client.clientId) ? client.clientId : null;
    let clientName = (client && client.clientName && client.clientName !== '') ? client.clientName : '';

    let currentLocation = (form && form.currentLocation && form.currentLocation !== '') ? form.currentLocation : '';
        

    data['jobId'] = jobId;

    data['vehicleId'] = vehicleId;
    data['vehicleName'] = vehicleName;

    data['driverId'] = driverId;
    data['driverName'] = driverName;

    data['jobDate'] = jobDate;

    data['driverMobile'] = driverMobile;
    data['cargoType'] = cargoType;
    data['cargoVolume'] = cargoVolume;
    data['trailerId'] = trailerId;

    data['clientId'] = clientId;
    data['clientName'] = clientName;

    // data['currentLocation'] = currentLocation;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.jobId){
      data['jobId'] = values.jobId;
    }

    let jobDate = saveDate(values.jobDate);
    data['jobDate'] = jobDate;
    
    data['vehicleId'] = values.vehicleId;
    data['driverId'] = values.driverId;
    data['driverMobile'] = values.driverMobile;

    data['cargoType'] = values.cargoType;
    data['cargoVolume'] = values.cargoVolume;
    data['trailerId'] = values.trailerId;
    data['clientId'] = values.clientId;

    // data['currentLocation'] = values.currentLocation;
  }

  return data;
};


export interface initialValuesStruct {
  vehicleId: any,
  vehicleName: string|null,
  driverId: any,
  driverName: string|null,
  jobDate: string
  driverMobile: string|null,
  cargoType: string|null,
  cargoVolume: number|null,
  trailerId: string|null,
  clientId: any,
  clientName: string|null,
  currentLocation: string,
};
export const initialValues: initialValuesStruct = {
  vehicleId: null,
  vehicleName: '',
  driverId: null,
  driverName: '',
  jobDate: '',
  driverMobile: '',
  cargoType: '',
  cargoVolume: null,
  trailerId: '',
  clientId: null,
  clientName: '',
  currentLocation: '',
};

export type Jobs = {
  vehicleId: any,
  vehicleName: string|null,
  driverId: any,
  driverName: string|null,
  jobDate: string
  driverMobile: string|null,
  cargoType: string|null,
  cargoVolume: number|null,
  trailerId: string|null,
  clientId: any,
  clientName: string|null,
  currentLocation: string,
}


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'jobsSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.jobs = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.jobs = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('job', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('job/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('job', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('job', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('job', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();