import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../../utils/axios';
import { successAPI, errorAPI, isNumeric, viewDateTime } from '../../../utils/utils';
// import {  } from '../../../utils/enums';


export const fields = {
  arrivalTime: {
    id: 'arrivalTime',
    label: 'Arrival time',
    placeholder: '',
  },
  departureTime: {
    id: 'departureTime',
    label: 'Departure time',
    placeholder: '',
  },
  duration: {
    id: 'duration',
    label: 'Duration',
    placeholder: '',
  },
  items: {
    id: 'items',
    label: 'Items',
    placeholder: '',
  },
};


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
  formikContext = useContext(FormikContext);
  if (!formikContext) {
    throw new Error('useFormikContext must be used within a FormikProvider');
  }
  return formikContext;
};


interface DetailsItem {
  routeLabel: string|null,
  routeTaskName: string|null,
  clientLabel: string|null,
  clientName: string|null,
  arrivalTimestamp: string|null,
  departureTimestamp: string|null,
  duration: string|null,
  items: Array<any>,
};
export const initDetailsItem: DetailsItem = {
  routeLabel: '',
  routeTaskName: '',
  clientLabel: '',
  clientName: '',
  arrivalTimestamp: '',
  departureTimestamp: '',
  duration: '',
  items: [],
};

export interface initialValuesStruct {
  detailsItems: Array<DetailsItem>,
};
export const initialValues: initialValuesStruct = {
  detailsItems: [],
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let routeTasks = (form && form.routeTasks && form.routeTasks.length > 0) ? form.routeTasks : [];

    let detailsItems: Array<DetailsItem> = [];
    if(routeTasks && routeTasks.length > 0){
      routeTasks.forEach((item: any, i: number) => {
        let routeTaskName = (item && item.routeTaskName != '') ? item.routeTaskName : '';
        let isStartPoint = (item && ((item.isStartPoint === false) || (item.isStartPoint === true))) ? item.isStartPoint : false;
        
        let routeLabel = '';
        let clientLabel = '';
        if(isStartPoint){
          routeLabel = 'Loading point';
          clientLabel = 'Consignor';
        } else {
          routeLabel = 'Unloading point';
          clientLabel = 'Consignee';
        }
  
        let client = (item && item.client) ? item.client : null;
        let clientName = (client && client.clientName != '') ? client.clientName: '';
  
        let arrivalTimestamp = (item && item.arrivalTimestamp) ? viewDateTime(item.arrivalTimestamp) : null;
        let departureTimestamp = (item && item.departureTimestamp) ? viewDateTime(item.departureTimestamp) : null;
        let duration = (item && item.duration) ? item.duration : null;

        let items = (item && item.items && item.items.length > 0) ? item.items : [];  

        detailsItems.push({
          routeLabel: routeLabel,
          routeTaskName: routeTaskName,
          clientLabel: clientLabel,
          clientName: clientName,
          arrivalTimestamp: arrivalTimestamp,
          departureTimestamp: departureTimestamp,
          duration: duration,
          items: items,
        });
      });
    }

    data['detailsItems'] = detailsItems;
  }
  
  return data;
};


interface InitState {
  id: number|null,
  show: boolean,

  isLoading: boolean,
  details: any,
}


function NewReducer() {
  const name = 'jobDetailsSlice';


  const initialState: InitState = {
    id: null,
    show: false,

    isLoading: false,
    details: null,
  };


  const reducers = {
    setShow: (state: InitState, action: PayloadAction<number|null>) => {
      state.isLoading = false;
      state.details = null;

      let id = action.payload;
      if(isNumeric(id)){
        state.id = id;
        state.show = true;
      } else {
        state.id = null;
        state.show = false;
      }
    },


    startDetails: (state: InitState) => {
      state.isLoading = true;
      state.details = null;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },
  };


  const apis = {
    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await axios.get('job/' + id).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();