import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Modal,
} from 'react-bootstrap';

import { Spin } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../utils/store.tsx';
import slice from './confirmSlice.tsx';

// ----------------------------------------------------------------------

const ConfirmModal = () => {
  const { isLoading, show, title, body, cancelText, cancelVariant, confirmText, confirmVariant } = useTypedSelector((state: RootState) => state.confirmSlice);
  const dispatch = useDispatch<AppDispatch>();

  return <Modal
    show={show}
    onHide={() => {
      dispatch(slice.setHide());
    }}
    backdrop={'static'}
    keyboard={false}
    enforceFocus={false}
    centered={true}
    size={'sm'}
  >
    <Modal.Header closeButton>
      <h6 className='fw-medium'>{title}</h6>
    </Modal.Header>

    <Spin spinning={isLoading} wrapperClassName={'no-height'}>
      <Modal.Body>{body}</Modal.Body>
    </Spin>

    <Modal.Footer>
      <Row className='gx-12'>
        <Col xs={true}>
          <Button
            variant={cancelVariant}
            disabled={isLoading}
            onClick={() => {
                dispatch(slice.setHide());
            }}
          >{cancelText}</Button>
        </Col>
        <Col xs={'auto'}>
          <Button
            variant={confirmVariant}
            disabled={isLoading}
            onClick={() => {
                dispatch(slice.confirm());
            }}
          >{confirmText}</Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
}

export default ConfirmModal;
