import { 
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import { Spin } from 'antd';

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return <Container fluid className={'overflow-hidden'}>
    <Row className={'align-items-center justify-content-center text-center min-vh-100'}>
      <Col xs={12}>
        <Container>
          <Spin />
        </Container>
      </Col>
    </Row>
  </Container>
}
