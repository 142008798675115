/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, isInPolygon, danger, convertCoordToLatLng, convertLatLngToCoord, convertPositionToLatLng, getPolygonCenterPosition, validateLatLng, isNumeric, round } from '../../utils/utils';
import Yup from '../../utils/yup';
import { CostType, MidPointType } from 'src/utils/enums';

import { addRoute, addCircle, zoomCircleByCircle, clearAllCircles, RouteMarkerType, addPolygon, zoomPolygonByPolygon, clearAllPolygons, clearRoute } from '../../components/googleMap/GoogleMap';


export const RADIUS = 100;


export const fields = {
  routeId: {
    id: 'routeId',
    label: 'Name',
    placeholder: 'Route',
  },
  routeName: {
    id: 'routeName',
    label: 'Name',
    placeholder: 'Route',
  },

  routePrefix: {
    id: 'routePrefix',
    label: 'Trip ID',
    placeholder: 'Prefix',
  },
  routeRunningNumber: {
    id: 'routeRunningNumber',
    label: 'Running number',
    placeholder: 'Running number',
  },

  routeColor: {
    id: 'routeColor',
    label: 'Route',
    placeholder: 'Choose colour',
  },
  routeRadius: {
    id: 'routeRadius',
    label: 'Radius',
    placeholder: 'Radius',
  },
  totalDistance: {
    id: 'totalDistance',
    label: 'Distance',
    placeholder: '',
  },

  startPointColor: {
    id: 'startPointColor',
    label: 'Start point color',
    placeholder: 'Choose colour',
  },
  startPointName: {
    id: 'startPointName',
    label: 'Start point',
    placeholder: 'Select geofence',
    placeholderText: 'Enter start point name',
  },
  startPointCoordinates: {
    id: 'startPointCoordinates',
    label: 'Coordinates',
    placeholder: 'Coordinates',
  },
  startPointRadius: {
    id: 'startPointRadius',
    label: 'Radius',
    placeholder: 'Radius',
  },

  endPointColor: {
    id: 'endPointColor',
    label: 'End point color',
    placeholder: 'Choose colour',
  },
  endPointName: {
    id: 'endPointName',
    label: 'End point',
    placeholder: 'Select geofence',
    placeholderText: 'Enter end point name',
  },
  endPointCoordinates: {
    id: 'endPointCoordinates',
    label: 'Coordinates',
    placeholder: 'Coordinates',
  },
  endPointRadius: {
    id: 'endPointRadius',
    label: 'Radius',
    placeholder: 'Radius',
  },

  midPointColor: {
    id: 'midPointColor',
    label: 'Mid point color',
    placeholder: 'Choose colour',
  },
  midPointName: {
    id: 'midPointName',
    label: 'Mid point',
    placeholder: 'Select geofence',
    placeholderText: 'Enter mid point name',
  },
  midPointCoordinates: {
    id: 'midPointCoordinates',
    label: 'Coordinates',
    placeholder: 'Coordinates',
  },
  midPointRadius: {
    id: 'midPointRadius',
    label: 'Radius',
    placeholder: 'Radius',
  },
  
  fuelCost: {
    id: 'fuelCost',
    label: 'Fuel cost',
    placeholder: 'Enter fuel cost',
  },
  tyreCost: {
    id: 'tyreCost',
    label: 'Tyre cost',
    placeholder: 'Enter tyre cost',
  },
  
  otherCost: {
    id: 'otherCost',
    label: 'Other cost',
    placeholder: 'Enter other cost',
  },
  fixedCost: {
    id: 'fixedCost',
    label: 'Fixed cost',
    placeholder: 'Enter fixed cost',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isNewRoute: Yup.bool().oneOf([true, false]),
    
    routeId: Yup.number().nullable().label(fields.routeId.label),
    routeName: Yup.string().nullable().required().label(fields.routeName.label),

    routePrefix: Yup.string().nullable().label(fields.routePrefix.label),
    routeRunningNumber: Yup.string().when('routeName', ([routeName]) => {
      if(routeName && routeName != ''){
        return Yup.string().nullable().runningNumber().required().label(fields.routeRunningNumber.label)
      } else {
        return Yup.string().nullable().label(fields.routeRunningNumber.label)
      }
    }),

    routeColor: Yup.string().nullable().color().required().label(fields.routeColor.label),
    routeRadius: Yup.number().nullable().required().min(1).max(30).label(fields.routeRadius.label),
    totalDistance: Yup.number().nullable().label(fields.totalDistance.label),

    startPointColor: Yup.string().nullable().color().label(fields.startPointColor.label),
    startPointRadius: Yup.number().nullable().min(0).max(1000).label(fields.startPointRadius.label),
    startPointHasCoordinate: Yup.bool().oneOf([true, false]),
    startPointCoordinates: Yup.string().when(['routeName', 'startPointHasCoordinate'], ([routeName, startPointHasCoordinate]) => {
      if(startPointHasCoordinate){
        if(routeName && routeName != ''){
          return Yup.string().nullable().coordinates().required().label(fields.startPointCoordinates.label);
        } else {
          return Yup.string().nullable().coordinates().label(fields.startPointCoordinates.label);
        }
      } else {
        return Yup.string().nullable().coordinates().label(fields.startPointCoordinates.label);
      }
    }),
    startPointGeofenceId: Yup.number().nullable().label(fields.startPointName.label),
    startPointGeofenceName: Yup.string().when(['routeName', 'startPointHasCoordinate'], ([routeName, startPointHasCoordinate]) => {
      if(!startPointHasCoordinate){
        if(routeName && routeName != ''){
          return Yup.string().nullable().required().label(fields.startPointName.label)
        } else {
          return Yup.string().nullable().label(fields.startPointName.label)
        }
      } else {
        return Yup.string().nullable().label(fields.startPointName.label)
      }
    }),
    startPointName: Yup.string().when(['routeName', 'startPointHasCoordinate'], ([routeName, startPointHasCoordinate]) => {
      if(startPointHasCoordinate){
        if(routeName && routeName != ''){
          return Yup.string().nullable().required().label(fields.startPointName.label)
        } else {
          return Yup.string().nullable().label(fields.startPointName.label)
        }
      } else {
        return Yup.string().nullable().label(fields.startPointName.label)
      }
    }),
    endPointColor: Yup.string().nullable().color().label(fields.endPointColor.label),
    endPointRadius: Yup.number().nullable().min(0).max(1000).label(fields.endPointRadius.label),
    endPointHasCoordinate: Yup.bool().oneOf([true, false]),
    endPointCoordinates: Yup.string().when(['routeName', 'endPointHasCoordinate'], ([routeName, endPointHasCoordinate]) => {
      if(endPointHasCoordinate){
        if(routeName && routeName != ''){
          return Yup.string().nullable().coordinates().required().label(fields.endPointCoordinates.label);
        } else {
          return Yup.string().nullable().coordinates().label(fields.endPointCoordinates.label);
        }
      } else {
        return Yup.string().nullable().coordinates().label(fields.endPointCoordinates.label);
      }
    }),

    endPointGeofenceId: Yup.number().nullable().label(fields.endPointName.label),
    endPointGeofenceName: Yup.string().when(['routeName', 'endPointHasCoordinate'], ([routeName, endPointHasCoordinate]) => {
      if(!endPointHasCoordinate){
        if(routeName && routeName != ''){
          return Yup.string().nullable().required().label(fields.endPointName.label)
        } else {
          return Yup.string().nullable().label(fields.endPointName.label)
        }
      } else {
        return Yup.string().nullable().label(fields.endPointName.label)
      }
    }),
    endPointName: Yup.string().when(['routeName', 'endPointHasCoordinate'], ([routeName, endPointHasCoordinate]) => {
      if(endPointHasCoordinate){
        if(routeName && routeName != ''){
          return Yup.string().nullable().required().label(fields.endPointName.label)
        } else {
          return Yup.string().nullable().label(fields.endPointName.label)
        }
      } else {
        return Yup.string().nullable().label(fields.endPointName.label)
      }
    }),

    routeMidPoints: Yup.array().of(Yup.object({
      midPointColor: Yup.string().nullable().color().label(fields.midPointColor.label),
      midPointGeofenceId: Yup.number().nullable().label(fields.midPointName.label),
      midPointGeofenceName: Yup.string().when(['midPointHasCoordinate', 'type', 'midPointPolygonCoordinates'], ([midPointHasCoordinate, type, midPointPolygonCoordinates]) => {
        if(!midPointHasCoordinate){
          if(type == MidPointType.Track){
            return Yup.string().nullable().label(fields.midPointName.label)
          } else {
            if(midPointPolygonCoordinates && midPointPolygonCoordinates != ''){
              return Yup.string().nullable().required().label(fields.midPointName.label)
            } else {
              return Yup.string().nullable().label(fields.midPointName.label)
            }
          }
        } else {
          return Yup.string().nullable().label(fields.midPointName.label)
        }
      }),
      midPointName: Yup.string().when(['midPointHasCoordinate', 'type', 'midPointCoordinates'], ([midPointHasCoordinate, type, midPointCoordinates]) => {
        if(midPointHasCoordinate){
          if(type == MidPointType.Track){
            return Yup.string().nullable().label(fields.midPointName.label)
          } else {
            if(midPointCoordinates && midPointCoordinates != ''){
              return Yup.string().nullable().required().label(fields.midPointName.label)
            } else {
              return Yup.string().nullable().label(fields.midPointName.label)
            }
          }
        } else {
          return Yup.string().nullable().label(fields.midPointName.label)
        }
      }),
      midPointHasCoordinate: Yup.bool().oneOf([true, false]),
      midPointCoordinates: Yup.string().when(['routeName', 'midPointHasCoordinate'], ([routeName, midPointHasCoordinate]) => {
        if(midPointHasCoordinate){
          if(routeName && routeName != ''){
            return Yup.string().nullable().coordinates().required().label(fields.midPointCoordinates.label);
          } else {
            return Yup.string().nullable().coordinates().label(fields.midPointCoordinates.label);
          }
        } else {
          return Yup.string().nullable().coordinates().label(fields.midPointCoordinates.label);
        }
      }),
      midPointRadius: Yup.number().nullable().min(0).max(1000).label(fields.midPointRadius.label),
      type: Yup.number().nullable().min(0),
    })),

    fuelCost: Yup.number().nullable().min(0).label(fields.fuelCost.label),
    tyreCost: Yup.number().nullable().min(0).label(fields.tyreCost.label),

    otherCost: Yup.array().of(Yup.object({
      cost: Yup.number().nullable().min(0).label(fields.otherCost.label),
      type: Yup.number().nullable().min(0),
    }))
    .hasEmpty('cost'),

    fixedCost: Yup.array().of(Yup.object({
      cost: Yup.number().nullable().min(0).label(fields.fixedCost.label),
      type: Yup.number().nullable().min(0),
    }))
    .hasEmpty('cost'),
  })
  .uniqueFields([
    ['startPointGeofenceId', 'endPointGeofenceId', 'routeMidPoints.midPointGeofenceId'],
    ['startPointName', 'endPointName', 'routeMidPoints.midPointName'],
    ['startPointCoordinates', 'endPointCoordinates', 'routeMidPoints.midPointCoordinates']
  ])
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const newForm = () => {
  let form = _.cloneDeep(initialValues);
  
  if(form){
    form['routeId'] = null;
    form['routeName'] = '';
    form['isNewRoute'] = true;
    form['routeMidPoints'] = [initMidPoint];

    return form;
  } else {
    return initialValues;
  }
}
export const cloneForm = (values: any = null) => {
  let form = _.cloneDeep(values);
  
  if(form){
    form['routeId'] = null;
    form['routeName'] = '';
    form['isNewRoute'] = true;

    return form;
  } else {
    return values;
  }
}
export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let routeId = (form.routeId && form.routeId !== '') ? form.routeId : null;
    let routeName = (form.routeName && form.routeName !== '') ? form.routeName : '';

    let routePrefix = (form.routePrefix && form.routePrefix !== '') ? form.routePrefix : '';
    let routeRunningNumber = (form.routePrefix && form.routeRunningNumber !== '') ? form.routeRunningNumber : '';

    let routeColor = (form.routeColor && form.routeColor !== '') ? form.routeColor : '';
    let routeRadius = (form.routeRadius && form.routeRadius !== '') ? form.routeRadius : 4;
    let totalDistance = (form.totalDistance && form.totalDistance !== '') ? form.totalDistance : null;

    let startPointColor = (form.startPointColor && form.startPointColor !== '') ? form.startPointColor : '';
    let startPointGeofenceId = (form.startPointGeofenceId && form.startPointGeofenceId !== '') ? form.startPointGeofenceId : null;
    let startPointGeofenceName = (form.startPointGeofence && form.startPointGeofence.name && form.startPointGeofence.name !== '') ? form.startPointGeofence.name : '';
    let startPointName = (form.startPointName && form.startPointName !== '') ? form.startPointName : '';
    
    let startPointPolygon: Array<any> = [];
    if(form && form.startPointGeofence && form.startPointGeofence.polygonShape && form.startPointGeofence.polygonShape.vertices && form.startPointGeofence.polygonShape.vertices.length > 0){
      startPointPolygon = form.startPointGeofence.polygonShape.vertices.map((item: any) => {
        return { lat: item.latitude, lng: item.longitude }
      });
    }
    
    let startCoordPos = getPolygonCenterPosition(startPointPolygon);
    let startPointPolygonCoord = convertLatLngToCoord(startCoordPos?.lat, startCoordPos?.lng);
    let startPointPolygonLatitude = startCoordPos?.lat;
    let startPointPolygonLongitude = startCoordPos?.lng;
    let startPointPolygonCoordinates = startPointPolygonCoord;

    let startPointLatitude = (form.startPointLatitude && form.startPointLatitude !== '') ? form.startPointLatitude : null;
    let startPointLongitude = (form.startPointLongitude && form.startPointLongitude !== '') ? form.startPointLongitude : null;
    let startIsCoordExist = (startPointLatitude && startPointLongitude) ? true : false;
    let startPointHasCoordinate = (startPointGeofenceId != null) ? false : startIsCoordExist ? true : false;
    let startPointCoordinates = convertLatLngToCoord(startPointLatitude, startPointLongitude);
    let startPointRadius = (form.startPointRadius && form.startPointRadius !== '') ? form.startPointRadius : null;


    let endPointColor = (form.endPointColor && form.endPointColor !== '') ? form.endPointColor : '';
    let endPointGeofenceId = (form.endPointGeofenceId && form.endPointGeofenceId !== '') ? form.endPointGeofenceId : null;
    let endPointGeofenceName = (form.endPointGeofence && form.endPointGeofence.name && form.endPointGeofence.name !== '') ? form.endPointGeofence.name : '';
    let endPointName = (form.endPointName && form.endPointName !== '') ? form.endPointName : '';
    
    let endPointPolygon: Array<any> = [];
    if(form && form.endPointGeofence && form.endPointGeofence.polygonShape && form.endPointGeofence.polygonShape.vertices && form.endPointGeofence.polygonShape.vertices.length > 0){
      endPointPolygon = form.endPointGeofence.polygonShape.vertices.map((item: any) => {
        return { lat: item.latitude, lng: item.longitude }
      });
    }
    
    let endCoordPos = getPolygonCenterPosition(endPointPolygon);
    let endPointPolygonCoord = convertLatLngToCoord(endCoordPos?.lat, endCoordPos?.lng);
    let endPointPolygonLatitude = endCoordPos?.lat;
    let endPointPolygonLongitude = endCoordPos?.lng;
    let endPointPolygonCoordinates = endPointPolygonCoord;

    let endPointLatitude = (form.endPointLatitude && form.endPointLatitude !== '') ? form.endPointLatitude : null;
    let endPointLongitude = (form.endPointLongitude && form.endPointLongitude !== '') ? form.endPointLongitude : null;
    let endIsCoordExist = (endPointLatitude && endPointLongitude) ? true : false;
    let endPointHasCoordinate = (endPointGeofenceId != null) ? false : endIsCoordExist ? true : false;
    let endPointCoordinates = convertLatLngToCoord(endPointLatitude, endPointLongitude);
    let endPointRadius = (form.endPointRadius && form.endPointRadius !== '') ? form.endPointRadius : null;


    let fuelCost = (form.fuelCost && form.fuelCost !== '') ? form.fuelCost : null;
    let tyreCost = (form.tyreCost && form.tyreCost !== '') ? form.tyreCost : null;

    let routeCosts = (form.routeCosts && form.routeCosts.length > 0) ? form.routeCosts.sort((a: any, b: any) => a.sortOrder - b.sortOrder) : [];
    let otherCost = (routeCosts && routeCosts.length > 0) ? routeCosts.filter((x: any) => x.type === CostType.OtherCost).sort((a: any, b: any) => a.sortOrder - b.sortOrder) : [initOtherCost];
    let fixedCost = (routeCosts && routeCosts.length > 0) ? routeCosts.filter((x: any) => x.type === CostType.FixedCost).sort((a: any, b: any) => a.sortOrder - b.sortOrder) : [initFixedCost];
    
    let routeMidPoints = (form.routeMidPoints && form.routeMidPoints.length > 0)
      ?
      form.routeMidPoints
        .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
        .map((item: any, i: number) => {
          let midPointPolygon: Array<any> = [];
          if(item && item.midPointGeofence && item.midPointGeofence.polygonShape && item.midPointGeofence.polygonShape.vertices && item.midPointGeofence.polygonShape.vertices.length > 0){
            midPointPolygon = item.midPointGeofence.polygonShape.vertices.map((item: any) => {
              return { lat: item.latitude, lng: item.longitude }
            });
          }

          let midPointGeofenceId = (item.midPointGeofenceId && item.midPointGeofenceId !== '') ? item.midPointGeofenceId : null;

          let midCoordPos = getPolygonCenterPosition(midPointPolygon);
          let midPointPolygonCoord = convertLatLngToCoord(midCoordPos?.lat, midCoordPos?.lng);
          let midPointPolygonLatitude = midCoordPos?.lat;
          let midPointPolygonLongitude = midCoordPos?.lng;
          let midPointPolygonCoordinates = midPointPolygonCoord;

          let midPointLatitude = (item.midPointLatitude && item.midPointLatitude !== '') ? item.midPointLatitude : null;
          let midPointLongitude = (item.midPointLongitude && item.midPointLongitude !== '') ? item.midPointLongitude : null;
          let midIsCoordExist = (midPointLatitude && midPointLongitude) ? true : false;
          let midPointHasCoordinate = (midPointGeofenceId != null) ? false : midIsCoordExist ? true : false;
          let midPointCoordinates = convertLatLngToCoord(midPointLatitude, midPointLongitude);
          
          let midPointColor = (item.midPointColor && item.midPointColor !== '') ? item.midPointColor : '';

          let midPoint: MidPoint = {
            midPointColor: midPointColor,
            midPointGeofenceId: midPointGeofenceId,
            midPointGeofenceName: (item.midPointGeofence && item.midPointGeofence.name && item.midPointGeofence.name !== '') ? item.midPointGeofence.name : '',
            midPointName: (item.midPointName && item.midPointName !== '') ? item.midPointName : '',
            
            midPointPolygon: midPointPolygon,
            midPointPolygonCoordinates: midPointPolygonCoordinates,
            midPointPolygonLatitude: midPointPolygonLatitude,
            midPointPolygonLongitude: midPointPolygonLongitude,

            midPointHasCoordinate: midPointHasCoordinate,
            midPointCoordinates: midPointCoordinates,
            midPointLatitude: midPointLatitude,
            midPointLongitude: midPointLongitude,
            midPointRadius: (item.midPointRadius && item.midPointRadius !== '') ? item.midPointRadius : null,
            sortOrder: item.sortOrder,
            type: item.type
          };
          return midPoint
        })
      :
      [initMidPoint];
    
      
    data['isNewRoute'] = form.isNewRoute;

    data['routeId'] = routeId;
    data['routeName'] = routeName;

    data['routePrefix'] = routePrefix;
    data['routeRunningNumber'] = routeRunningNumber;

    data['routeColor'] = routeColor;
    data['routeRadius'] = routeRadius;
    data['totalDistance'] = totalDistance;

    data['startPointColor'] = startPointColor;
    data['startPointGeofenceId'] = startPointGeofenceId;
    data['startPointGeofenceName'] = startPointGeofenceId ? startPointGeofenceName : '';
    data['startPointName'] = startPointName;
    data['startPointPolygon'] = startPointGeofenceId ? startPointPolygon : [];
    data['startPointPolygonLatitude'] = startPointGeofenceId ? startPointPolygonLatitude : null;
    data['startPointPolygonLongitude'] = startPointGeofenceId ? startPointPolygonLongitude : null;
    data['startPointPolygonCoordinates'] = startPointGeofenceId ? startPointPolygonCoordinates : '';
    data['startPointHasCoordinate'] = startPointHasCoordinate;
    data['startPointLatitude'] = (startPointGeofenceId == null) ? startPointLatitude : null;
    data['startPointLongitude'] = (startPointGeofenceId == null) ? startPointLongitude : null;
    data['startPointCoordinates'] = (startPointGeofenceId == null) ? startPointCoordinates : '';
    data['startPointRadius'] = (startPointGeofenceId == null) ? startPointRadius : null;

    data['endPointColor'] = endPointColor;
    data['endPointGeofenceId'] = endPointGeofenceId;
    data['endPointGeofenceName'] = endPointGeofenceName;
    data['endPointName'] = endPointName;
    data['endPointPolygon'] = endPointPolygon;
    data['endPointPolygonLatitude'] = endPointPolygonLatitude;
    data['endPointPolygonLongitude'] = endPointPolygonLongitude;
    data['endPointPolygonCoordinates'] = endPointPolygonCoordinates;
    data['endPointHasCoordinate'] = endPointHasCoordinate;
    data['endPointLatitude'] = (endPointGeofenceId == null) ? endPointLatitude : null;
    data['endPointLongitude'] = (endPointGeofenceId == null) ? endPointLongitude : null;
    data['endPointCoordinates'] = endPointCoordinates;
    data['endPointRadius'] = endPointRadius;

    data['routeMidPoints'] = routeMidPoints;

    data['fuelCost'] = fuelCost;
    data['tyreCost'] = tyreCost;
    data['otherCost'] = otherCost;
    data['fixedCost'] = fixedCost;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.routeId){
      data['routeId'] = values.routeId;
    }
    data['routeName'] = values.routeName;

    data['routePrefix'] = values.routePrefix;
    data['routeRunningNumber'] = values.routeRunningNumber;

    data['routeColor'] = values.routeColor;
    data['routeRadius'] = values.routeRadius;
    data['totalDistance'] = values.totalDistance;

    data['startPointColor'] = values.startPointColor;
    data['startPointGeofenceId'] = !values.startPointHasCoordinate ? values.startPointGeofenceId : null;
    data['startPointName'] = values.startPointHasCoordinate ? values.startPointName : values.startPointGeofenceName;
    data['startPointLatitude'] = values.startPointHasCoordinate ? (values.startPointLatitude) ? values.startPointLatitude : null : null;
    data['startPointLongitude'] = values.startPointHasCoordinate ? (values.startPointLongitude) ? values.startPointLongitude : null : null;
    data['startPointRadius'] = values.startPointRadius;

    data['endPointColor'] = values.endPointColor;
    data['endPointGeofenceId'] = !values.endPointHasCoordinate ? values.endPointGeofenceId : null;
    data['endPointName'] = values.endPointHasCoordinate ? values.endPointName : values.endPointGeofenceName;
    data['endPointLatitude'] = values.endPointHasCoordinate ? (values.endPointLatitude) ? values.endPointLatitude : null : null;
    data['endPointLongitude'] = values.endPointHasCoordinate ? (values.endPointLongitude) ? values.endPointLongitude : null : null;
    data['endPointRadius'] = values.endPointRadius;
    

    let routeMidPoints: Array<any> = [];
    if(values.routeMidPoints && values.routeMidPoints.length > 0){
      values.routeMidPoints.forEach((item: any, i: number) => {
        if((values.routeMidPoints.length == 1) && (item.midPointName === null || item.midPointName === undefined || item.midPointName === '')){
          //nop
        } else {
          routeMidPoints.push({
            midPointColor: item.midPointColor,
            midPointGeofenceId: !item.midPointHasCoordinate ? item.midPointGeofenceId : null,
            midPointName: item.midPointHasCoordinate ? item.midPointName : item.midPointGeofenceName,
            midPointLatitude: item.midPointHasCoordinate ? (item.midPointLatitude) ? item.midPointLatitude : null : null,
            midPointLongitude: item.midPointHasCoordinate ? (item.midPointLongitude) ? item.midPointLongitude : null : null,
            midPointRadius: item.midPointRadius,
            type: item.type,
            sortOrder: i,
          })
        }
      });
    }
    data['routeMidPoints'] = routeMidPoints;


    data['fuelCost'] = values.fuelCost;
    data['tyreCost'] = values.tyreCost;

    let count: number = 0;
    let routeCosts: Array<Cost> = [];
    if(values.otherCost && values.otherCost.length > 0){
      values.otherCost.forEach((item: any, i: number) => {
        if((values.otherCost.length == 1) && (item.cost === null || item.cost === undefined || item.cost === '')){
          //nop
        } else {
          routeCosts.push({
            cost: item.cost,
            type: item.type,
            sortOrder: count,
          })
          count = count+1;
        }
      });
    }
    if(values.fixedCost && values.fixedCost.length > 0){
      values.fixedCost.forEach((item: any, i: number) => {
        if((values.fixedCost.length == 1) && (item.cost === null || item.cost === undefined || item.cost === '')){
          //nop
        } else {
          routeCosts.push({
            cost: item.cost,
            type: item.type,
            sortOrder: count,
          })
          count = count+1;
        }
      });
    }
    data['routeCosts'] = routeCosts;
  }

  return data;
};

export const getTotalCost = (values: any = null) => {
  let total = 0;

  let totalDistance = isNumeric(values.totalDistance) ? round(values.totalDistance, 2) : 0;

  let fuelCost = isNumeric(values.fuelCost) ? round(values.fuelCost, 2) : 0;
  let tyreCost = isNumeric(values.tyreCost) ? round(values.tyreCost, 2) : 0;

  let otherCost = 0;
  if(values.otherCost && values.otherCost.length > 0){
    values.otherCost.forEach((item: any) => {
      let cost = isNumeric(item.cost) ? round(item.cost, 2) : 0;
      otherCost = otherCost + (cost * totalDistance);
    });
  }

  let fixedCost = 0;
  if(values.fixedCost && values.fixedCost.length > 0){
    values.fixedCost.forEach((item: any) => {
      let cost = isNumeric(item.cost) ? round(item.cost, 2) : 0;
      fixedCost = fixedCost + cost;
    });
  }

  total = (fuelCost * totalDistance) + (tyreCost * totalDistance) + otherCost + fixedCost;

  return total;
}
export const showRoute = (preserveViewport: boolean = true, onFinishCallback: any = null) => {
  setTimeout(() => {
    if(formikContext && formikContext.values && 
      (
        (formikContext.values.startPointPolygonCoordinates && formikContext.values.startPointPolygonCoordinates != '') && 
        (formikContext.values.endPointPolygonCoordinates && formikContext.values.endPointPolygonCoordinates != '')
      ) || (
        (formikContext.values.startPointCoordinates && formikContext.values.startPointCoordinates != '') && 
        (formikContext.values.endPointCoordinates && formikContext.values.endPointCoordinates != '')
      ) || (
        (formikContext.values.startPointPolygonCoordinates && formikContext.values.startPointPolygonCoordinates != '') &&
        (formikContext.values.endPointCoordinates && formikContext.values.endPointCoordinates != '')
      ) || (
        (formikContext.values.startPointCoordinates && formikContext.values.startPointCoordinates != '') && 
        (formikContext.values.endPointPolygonCoordinates && formikContext.values.endPointPolygonCoordinates != '')
      )
    ){
      let startPointPolygonCoordinates = convertCoordToLatLng(formikContext.values.startPointPolygonCoordinates);
      let startPointCoordinates = convertCoordToLatLng(formikContext.values.startPointCoordinates);
      let startPoint = formikContext.values.startPointHasCoordinate ? startPointCoordinates : startPointPolygonCoordinates;
      
      let endPointPolygonCoordinates = convertCoordToLatLng(formikContext.values.endPointPolygonCoordinates);
      let endPointCoordinates = convertCoordToLatLng(formikContext.values.endPointCoordinates);
      let endPoint = formikContext.values.endPointHasCoordinate ? endPointCoordinates : endPointPolygonCoordinates;
      
      if(startPoint && endPoint){
        let midPoints: Array<any> = [];
        if(formikContext.values.routeMidPoints && formikContext.values.routeMidPoints.length > 0){
          formikContext.values.routeMidPoints.forEach((item: any, i: number) => {
            let midPointPolygonCoordinates = convertCoordToLatLng(item.midPointPolygonCoordinates);
            let midPointCoordinates = convertCoordToLatLng(item.midPointCoordinates);
            let midPoint = item.midPointHasCoordinate ? midPointCoordinates : midPointPolygonCoordinates;
            if(midPoint){
              midPoints.push({
                location: midPoint,
                stopover: (item.type === MidPointType.Track) ? false : true,
              })
            } else {
              if(formikContext.values.routeMidPoints.length > 1){
                midPoints.push({
                  location: null,
                  stopover: (item.type === MidPointType.Track) ? false : true,
                })
              }
            }
          });
        }
    
        addRoute(preserveViewport, startPoint, endPoint, midPoints, formikContext.values.routeColor, formikContext.values.routeRadius,
          // Get prev directions
          () => {
            return {
              oldDirections: formikContext.values.oldDirections,
            }
          },

          // Set total and directions
          (total: any, directions: any) => {
            formikContext.setFieldValue('totalDistance', total);
            formikContext.setFieldValue('oldDirections', directions);
          },

          // Change coordinates in form
          (requestRoute: any, responseRoute: any) => {
            clearAllCircles();
            clearAllPolygons();
        
            let routeMidPoints: Array<any> = [];
            if(requestRoute && requestRoute.midPoints && requestRoute.midPoints.length > 0){
              let routeMidPointsPrev = _.cloneDeep(formikContext.values.routeMidPoints);

              for(let i = 0; i < requestRoute.midPoints.length; i++) {
                let item = requestRoute.midPoints[i];

                let trackPointLatitude = item.position.lat;
                let trackPointLongitude = item.position.lng;

                let trackPointHasCoordinate = validateLatLng(trackPointLatitude, trackPointLongitude) ? true : false;
                let trackPointCoordinates = convertLatLngToCoord(trackPointLatitude, trackPointLongitude);
                
                let prevItem: MidPoint = routeMidPointsPrev.find((x: any) => {
                  if(x.midPointHasCoordinate){
                    let coordObj = convertCoordToLatLng(x.midPointCoordinates);
                    return coordObj ? (coordObj.lat == trackPointLatitude) && (coordObj.lng == trackPointLongitude) : false
                  } else {
                    let coordObj = convertCoordToLatLng(x.midPointPolygonCoordinates);
                    return coordObj ? (coordObj.lat == trackPointLatitude) && (coordObj.lng == trackPointLongitude) : false
                  }
                });
                
                if(prevItem){
                  routeMidPoints.push(prevItem);
                } else {
                  let trackColor = item.stopover ? '#505363' : '#ffffff';
                  let trackName = item.stopover ? '' : '';

                  let newItem: any = {
                    midPointColor: trackColor,
                    midPointName: trackName,
                    midPointPolygonLatitude: trackPointLatitude,
                    midPointPolygonLongitude: trackPointLongitude,
                    midPointLatitude: trackPointLatitude,
                    midPointLongitude: trackPointLongitude,
                    midPointHasCoordinate: trackPointHasCoordinate,
                    midPointCoordinates: trackPointCoordinates,
                    sortOrder: item.sortOrder,
                    type: item.stopover ? MidPointType.MidPoint : MidPointType.Track,
                    midPointGeofenceId: null,
                    midPointGeofenceName: trackName,
                    midPointRadius: null
                  };
                  routeMidPoints.push(newItem);
                }
              }
              formikContext.setFieldValue('routeMidPoints', routeMidPoints);
            }
            
            // Add radius circle
            // Start Point radius
            if(requestRoute && requestRoute.startPoint && requestRoute.startPoint.position){
              if(formikContext.values.startPointHasCoordinate){
                addPolygon(null, {
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  fillOpacity: 0,
                });

                let circle: any = addCircle({
                  center: requestRoute.startPoint.position,
                  radius: (formikContext.values.startPointRadius && formikContext.values.startPointRadius != '') ? parseInt(formikContext.values.startPointRadius) : 0,
                  fillColor: formikContext.values.startPointColor,
                  fillOpacity: 0.25,
                  strokeColor: formikContext.values.startPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 1,
                });
                if(circle){
                  circle.addListener('mouseover', (e: any) => {
                    circle.setEditable(true);
                  });
                  circle.addListener('mouseout', (e: any) => {
                    circle.setEditable(false);
                  });
                  circle.addListener('radius_changed', (e: any) => {
                    let radius = circle.getRadius();
                    formikContext.setFieldValue('startPointRadius', parseInt(radius));
                  });
                  circle.addListener('center_changed', (e: any) => {
                    let center = circle.getCenter();
                    let lat = center.lat();
                    let lng = center.lng();
                    let coordinatesStr = convertLatLngToCoord(lat, lng);

                    formikContext.setFieldValue('startPointCoordinates', coordinatesStr);
                    formikContext.setFieldValue('startPointLatitude', lat);
                    formikContext.setFieldValue('startPointLongitude', lng);

                    showRoute();
                  });
                } else {
                  addCircle({
                    radius: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0,
                  })
                }
              } else {
                addPolygon(formikContext.values.startPointPolygon, {
                  strokeColor: formikContext.values.startPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  fillColor: formikContext.values.startPointColor,
                  fillOpacity: 0.25,
                });
                
                addCircle({
                  radius: 0,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                })
              }
            }

            // Mid Points radius
            if(routeMidPoints && routeMidPoints.length > 0){
              for(let i = 0; i < routeMidPoints.length; i++) {
                let item = routeMidPoints[i];
                
                if(item.type === MidPointType.MidPoint){
                  if(item.midPointHasCoordinate){
                    let coordObj = (item.midPointHasCoordinate) ? convertCoordToLatLng(item.midPointCoordinates) : convertCoordToLatLng(item.midPointPolygonCoordinates);
                    
                    addPolygon(null, {
                      strokeOpacity: 0,
                      strokeWeight: 0,
                      fillOpacity: 0,
                    });

                    let circle: any = addCircle({
                      center: { lat: coordObj?.lat, lng: coordObj?.lng },
                      radius: (item.midPointRadius && item.midPointRadius != '') ? parseInt(item.midPointRadius) : 0,
                      fillColor: item.midPointColor,
                      fillOpacity: 0.25,
                      strokeColor: item.midPointColor,
                      strokeOpacity: 1.0,
                      strokeWeight: 1,
                    });
                    if(circle){
                      circle.addListener('mouseover', (e: any) => {
                        circle.setEditable(true);
                      });
                      circle.addListener('mouseout', (e: any) => {
                        circle.setEditable(false);
                      });
                      circle.addListener('radius_changed', (e: any) => {
                        let radius = circle.getRadius();
                        formikContext.setFieldValue('routeMidPoints.' + i + '.midPointRadius', parseInt(radius));
                      });
                      circle.addListener('center_changed', (e: any) => {
                        let center = circle.getCenter();
                        let lat = center.lat();
                        let lng = center.lng();
                        let coordinatesStr = convertLatLngToCoord(lat, lng);
      
                        formikContext.setFieldValue('routeMidPoints.' + i + '.midPointCoordinates', coordinatesStr);
                        formikContext.setFieldValue('routeMidPoints.' + i + '.midPointLatitude', lat);
                        formikContext.setFieldValue('routeMidPoints.' + i + '.midPointLongitude', lng);
      
                        showRoute();
                      });
                    }
                  } else {
                    addPolygon(item.midPointPolygon, {
                      strokeColor: item.midPointColor,
                      strokeOpacity: 1.0,
                      strokeWeight: 2,
                      fillColor: item.midPointColor,
                      fillOpacity: 0.25,
                    });

                    addCircle({
                      radius: 0,
                      fillOpacity: 0,
                      strokeOpacity: 0,
                    })
                  }
                } else {
                  addPolygon(null, {
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    fillOpacity: 0,
                  });

                  addCircle({
                    radius: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0,
                  })
                }
              }
            }

            // End Point radius
            if(requestRoute && requestRoute.endPoint && requestRoute.endPoint.position){
              if(formikContext.values.endPointHasCoordinate){
                addPolygon(null, {
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  fillOpacity: 0,
                });
                
                let circle: any = addCircle({
                  center: requestRoute.endPoint.position,
                  radius: (formikContext.values.endPointRadius && formikContext.values.endPointRadius != '') ? parseInt(formikContext.values.endPointRadius) : 0,
                  fillColor: formikContext.values.endPointColor,
                  fillOpacity: 0.25,
                  strokeColor: formikContext.values.endPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 1,
                });
                if(circle){
                  circle.addListener('mouseover', (e: any) => {
                    circle.setEditable(true);
                  });
                  circle.addListener('mouseout', (e: any) => {
                    circle.setEditable(false);
                  });
                  circle.addListener('radius_changed', (e: any) => {
                    let radius = circle.getRadius();
                    formikContext.setFieldValue('endPointRadius', parseInt(radius));
                  });
                  circle.addListener('center_changed', (e: any) => {
                    let center = circle.getCenter();
                    let lat = center.lat();
                    let lng = center.lng();
                    let coordinatesStr = convertLatLngToCoord(lat, lng);

                    formikContext.setFieldValue('endPointCoordinates', coordinatesStr);
                    formikContext.setFieldValue('endPointLatitude', lat);
                    formikContext.setFieldValue('endPointLongitude', lng);

                    showRoute();
                  });
                } else {
                  addCircle({
                    radius: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0,
                  })
                }
              } else {
                addPolygon(formikContext.values.endPointPolygon, {
                  strokeColor: formikContext.values.endPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  fillColor: formikContext.values.endPointColor,
                  fillOpacity: 0.25,
                });

                addCircle({
                  radius: 0,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                })
              }
            }
            

            // Return colors
            let midPointColors: Array<any> = [];
            if(routeMidPoints && routeMidPoints.length > 0){
              routeMidPoints.forEach((item: any, i: number) => {
                let midPointPolygonCoordinates = convertCoordToLatLng(item.midPointPolygonCoordinates);
                let midPointCoordinates = convertCoordToLatLng(item.midPointCoordinates);
                let midPoint = midPointCoordinates ? midPointCoordinates : midPointPolygonCoordinates;
                if(midPoint){
                  midPointColors.push(item.midPointColor ? item.midPointColor : '#505363')
                }
              });
            }

            return {
              startPointColor: formikContext.values.startPointColor ? formikContext.values.startPointColor : '#CB3A31',
              midPointColors: midPointColors,
              endPointColor: formikContext.values.endPointColor ? formikContext.values.endPointColor : '#185CFF',
            }
          },

          //On Move marker in polygon
          (obj: any) => {
            if(obj){
              let lat = obj.position.lat();
              let lng = obj.position.lng();
              let coordinates = convertLatLngToCoord(lat, lng);

              let coordinatesObj = convertPositionToLatLng(obj.position);
              let prevCoordinatesObj = convertPositionToLatLng(obj.item.position);
              let prevCoordinates = prevCoordinatesObj ? convertLatLngToCoord(prevCoordinatesObj.lat, prevCoordinatesObj.lng) : null;

              if(obj.type === RouteMarkerType.StartPoint){
                // let isInPoly = isInPolygon(formikContext.values.startPointPolygon, coordinatesObj);
                // if(isInPoly){
                  // if(formikContext.values.startPointHasCoordinate){
                  //   formikContext.setFieldValue('startPointCoordinates', coordinates);
                  // }

                  formikContext.setFieldValue('startPointCoordinates', coordinates);
                          
                  let coordinatesObj = convertCoordToLatLng(coordinates);
                  formikContext.setFieldValue('startPointLatitude', coordinatesObj?.lat);
                  formikContext.setFieldValue('startPointLongitude', coordinatesObj?.lng);
                  formikContext.setFieldValue('startPointHasCoordinate', true);

                  if(formikContext.values.startPointRadius == null || formikContext.values.startPointRadius == ''){
                    formikContext.setFieldValue('startPointRadius', RADIUS);
                  }

                // } else {
                //   formikContext.setFieldValue('startPointCoordinates', prevCoordinates);
                //   danger('Error', 'The coordinates are outside the polygon');
                // }

              } else if(obj.type === RouteMarkerType.EndPoint){
                // let isInPoly = isInPolygon(formikContext.values.endPointPolygon, coordinatesObj);
                // if(isInPoly){
                  // if(formikContext.values.endPointHasCoordinate){
                  //   formikContext.setFieldValue('endPointCoordinates', coordinates);
                  // }
                  
                  formikContext.setFieldValue('endPointCoordinates', coordinates);
                          
                  let coordinatesObj = convertCoordToLatLng(coordinates);
                  formikContext.setFieldValue('endPointLatitude', coordinatesObj?.lat);
                  formikContext.setFieldValue('endPointLongitude', coordinatesObj?.lng);
                  formikContext.setFieldValue('endPointHasCoordinate', true);

                  if(formikContext.values.endPointRadius == null || formikContext.values.endPointRadius == ''){
                    formikContext.setFieldValue('endPointRadius', RADIUS);
                  }

                // } else {
                //   formikContext.setFieldValue('endPointCoordinates', prevCoordinates);
                //   danger('Error', 'The coordinates are outside the polygon');
                // }

              } else if(obj.type === RouteMarkerType.MidPoint){
                // let isInPoly = isInPolygon(formikContext.values.routeMidPoints[(obj.index-1)].midPointPolygon, coordinatesObj);
                // if(isInPoly){
                  
                // if(formikContext.values.routeMidPoints && formikContext.values.routeMidPoints.length > 0 && formikContext.values.routeMidPoints[(obj.index-1)] && formikContext.values.routeMidPoints[(obj.index-1)].endPointHasCoordinate){
                //   formikContext.setFieldValue('routeMidPoints.' + (obj.index-1) + '.midPointCoordinates', coordinates);
                // }
                
                formikContext.setFieldValue('routeMidPoints.' + (obj.index-1) + '.midPointCoordinates', coordinates);
                          
                let coordinatesObj = convertCoordToLatLng(coordinates);
                formikContext.setFieldValue('routeMidPoints.' + (obj.index-1) + '.midPointLatitude', coordinatesObj?.lat);
                formikContext.setFieldValue('routeMidPoints.' + (obj.index-1) + '.midPointLongitude', coordinatesObj?.lng);
                formikContext.setFieldValue('routeMidPoints.' + (obj.index-1) + '.endPointHasCoordinate', true);

                if(formikContext.values.routeMidPoints && formikContext.values.routeMidPoints.length > 0 && formikContext.values.routeMidPoints[(obj.index-1)] && formikContext.values.routeMidPoints[(obj.index-1)]){
                  if(formikContext.values.routeMidPoints[(obj.index-1)].startPointRadius == null || formikContext.values.routeMidPoints[(obj.index-1)].startPointRadius == ''){
                    formikContext.setFieldValue('routeMidPoints.' + (obj.index-1) + '.midPointRadius', RADIUS);
                  }
                }

                // } else {
                //   formikContext.setFieldValue('routeMidPoints.' + (obj.index-1) + '.midPointCoordinates', prevCoordinates);
                //   danger('Error', 'The coordinates are outside the polygon');
                // }
              }

              showRoute();
            }
          },

          onFinishCallback
        )
      } else {
        clearRoute();
      }
    } else {
      clearRoute();

      let startPointPolygonCoordinates = convertCoordToLatLng(formikContext.values.startPointPolygonCoordinates);
      let startPointCoordinates = convertCoordToLatLng(formikContext.values.startPointCoordinates);
      let startPoint = formikContext.values.startPointHasCoordinate ? startPointCoordinates : startPointPolygonCoordinates;
      
      let endPointPolygonCoordinates = convertCoordToLatLng(formikContext.values.endPointPolygonCoordinates);
      let endPointCoordinates = convertCoordToLatLng(formikContext.values.endPointCoordinates);
      let endPoint = formikContext.values.endPointHasCoordinate ? endPointCoordinates : endPointPolygonCoordinates;
      
      if(startPoint){
        if(!formikContext.values.startPointHasCoordinate){
          let polygon = addPolygon(formikContext.values.startPointPolygon, {
            strokeColor: formikContext.values.startPointColor,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            fillColor: formikContext.values.startPointColor,
            fillOpacity: 0.25,
          });
          zoomPolygonByPolygon(polygon);

        } else {
          let circle: any = addCircle({
            center: { lat: formikContext.values.startPointLatitude, lng: formikContext.values.startPointLongitude },
            radius: (formikContext.values.startPointRadius && formikContext.values.startPointRadius != '') ? parseInt(formikContext.values.startPointRadius) : 0,
            fillColor: formikContext.values.startPointColor,
            fillOpacity: 0.25,
            strokeColor: formikContext.values.startPointColor,
            strokeOpacity: 1.0,
            strokeWeight: 1,
          });
          if(circle){
            zoomCircleByCircle(circle);

            circle.addListener('mouseover', (e: any) => {
              circle.setEditable(true);
            });
            circle.addListener('mouseout', (e: any) => {
              circle.setEditable(false);
            });
            circle.addListener('radius_changed', (e: any) => {
              let radius = circle.getRadius();
              formikContext.setFieldValue('startPointRadius', parseInt(radius));
            });
            circle.addListener('center_changed', (e: any) => {
              let center = circle.getCenter();
              let lat = center.lat();
              let lng = center.lng();
              let coordinatesStr = convertLatLngToCoord(lat, lng);

              formikContext.setFieldValue('startPointCoordinates', coordinatesStr);
              formikContext.setFieldValue('startPointLatitude', lat);
              formikContext.setFieldValue('startPointLongitude', lng);
            });
          }
        }
        
      } else if(endPoint){
        if(!formikContext.values.endPointHasCoordinate){
          let polygon = addPolygon(formikContext.values.endPointPolygon, {
            strokeColor: formikContext.values.endPointColor,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            fillColor: formikContext.values.endPointColor,
            fillOpacity: 0.25,
          });
          zoomPolygonByPolygon(polygon);

        } else {
          let circle: any = addCircle({
            center: { lat: formikContext.values.endPointLatitude, lng: formikContext.values.endPointLongitude },
            radius: (formikContext.values.endPointRadius && formikContext.values.endPointRadius != '') ? parseInt(formikContext.values.endPointRadius) : 0,
            fillColor: formikContext.values.endPointColor,
            fillOpacity: 0.25,
            strokeColor: formikContext.values.endPointColor,
            strokeOpacity: 1.0,
            strokeWeight: 1,
          });
          if(circle){
            zoomCircleByCircle(circle);

            circle.addListener('mouseover', (e: any) => {
              circle.setEditable(true);
            });
            circle.addListener('mouseout', (e: any) => {
              circle.setEditable(false);
            });
            circle.addListener('radius_changed', (e: any) => {
              let radius = circle.getRadius();
              formikContext.setFieldValue('endPointRadius', parseInt(radius));
            });
            circle.addListener('center_changed', (e: any) => {
              let center = circle.getCenter();
              let lat = center.lat();
              let lng = center.lng();
              let coordinatesStr = convertLatLngToCoord(lat, lng);

              formikContext.setFieldValue('endPointCoordinates', coordinatesStr);
              formikContext.setFieldValue('endPointLatitude', lat);
              formikContext.setFieldValue('endPointLongitude', lng);
            });
          }
        }
      }
    }
  });
}
export const hasRoute = () => {
  return (
    formikContext && formikContext.values && 
    (
      (formikContext.values.startPointPolygonCoordinates && formikContext.values.startPointPolygonCoordinates != '') && 
      (formikContext.values.endPointPolygonCoordinates && formikContext.values.endPointPolygonCoordinates != '')
    ) || (
      (formikContext.values.startPointCoordinates && formikContext.values.startPointCoordinates != '') && 
      (formikContext.values.endPointCoordinates && formikContext.values.endPointCoordinates != '')
    ) || (
      (formikContext.values.startPointPolygonCoordinates && formikContext.values.startPointPolygonCoordinates != '') &&
      (formikContext.values.endPointCoordinates && formikContext.values.endPointCoordinates != '')
    ) || (
      (formikContext.values.startPointCoordinates && formikContext.values.startPointCoordinates != '') && 
      (formikContext.values.endPointPolygonCoordinates && formikContext.values.endPointPolygonCoordinates != '')
    )
  ) ? true : false;
}

interface MidPoint {
  midPointColor: string,
  midPointGeofenceId: number|null,
  midPointGeofenceName: string|null,
  midPointName: string|null,
  midPointPolygon: Array<any>|null,
  midPointPolygonCoordinates: string|null,
  midPointPolygonLatitude: any,
  midPointPolygonLongitude: any,
  midPointHasCoordinate: boolean,
  midPointCoordinates: string|null,
  midPointLatitude: number|null,
  midPointLongitude: number|null,
  midPointRadius: number|null,
  sortOrder: number,
  type: number|null,
};
export const initMidPoint: MidPoint = {
  midPointColor: '#505363',
  midPointGeofenceId: null,
  midPointGeofenceName: null,
  midPointName: null,
  midPointPolygon: [],
  midPointPolygonCoordinates: '',
  midPointPolygonLatitude: '',
  midPointPolygonLongitude: '',
  midPointHasCoordinate: false,
  midPointCoordinates: null,
  midPointLatitude: null,
  midPointLongitude: null,
  midPointRadius: null,
  sortOrder: 0,
  type: MidPointType.MidPoint,
};


interface Cost {
  cost: number|null,
  type: number|null,
  sortOrder: number,
};
export const initOtherCost: Cost = {
  cost: null,
  type: CostType.OtherCost,
  sortOrder: 0,
};
export const initFixedCost: Cost = {
  cost: null,
  type: CostType.FixedCost,
  sortOrder: 0,
};


export interface initialValuesStruct {
  isNewRoute: boolean,

  routeId: number|null,
  routeName: string,

  routePrefix: string,
  routeRunningNumber: string,
  
  routeColor: string,
  routeRadius: number|null,
  totalDistance: number|null,

  startPointColor: string,
  startPointGeofenceId: number|null,
  startPointGeofenceName: string,
  startPointName: string,
  startPointPolygon: Array<any>,
  startPointPolygonCoordinate: string,
  startPointPolygonLatitude: string,
  startPointPolygonLongitude: string,
  startPointHasCoordinate: boolean,
  startPointCoordinates: string,
  startPointLatitude: string,
  startPointLongitude: string,
  startPointRadius: number|null,

  endPointColor: string,
  endPointGeofenceId: number|null,
  endPointGeofenceName: string,
  endPointName: string,
  endPointPolygon: Array<any>,
  endPointPolygonCoordinate: string,
  endPointPolygonLatitude: string,
  endPointPolygonLongitude: string,
  endPointHasCoordinate: boolean,
  endPointCoordinate: string,
  endPointLatitude: string,
  endPointLongitude: string,
  endPointRadius: number|null,

  routeMidPoints: Array<MidPoint>,

  fuelCost: number|null,
  tyreCost: number|null,
  otherCost: Array<Cost>,
  fixedCost: Array<Cost>,

  data: any,

  oldDirections: any,
};
export const initialValues: initialValuesStruct = {
  isNewRoute: false, 

  routeId: null,
  routeName: '',

  routePrefix: '',
  routeRunningNumber: '',

  routeColor: '#9179ED',
  routeRadius: 4,
  totalDistance: null,
  
  startPointColor: '#CB3A31',
  startPointGeofenceId: null,
  startPointGeofenceName: '',
  startPointName: '',
  startPointPolygon: [],
  startPointPolygonCoordinate: '',
  startPointPolygonLatitude: '',
  startPointPolygonLongitude: '',
  startPointHasCoordinate: false,
  startPointCoordinates: '',
  startPointLatitude: '',
  startPointLongitude: '',
  startPointRadius: null,
  
  endPointColor: '#185CFF',
  endPointGeofenceId: null,
  endPointGeofenceName: '',
  endPointName: '',
  endPointPolygon: [],
  endPointPolygonCoordinate: '',
  endPointPolygonLatitude: '',
  endPointPolygonLongitude: '',
  endPointHasCoordinate: false,
  endPointCoordinate: '',
  endPointLatitude: '',
  endPointLongitude: '',
  endPointRadius: null,

  routeMidPoints: [initMidPoint],

  fuelCost: null,
  tyreCost: null,
  otherCost: [initOtherCost],
  fixedCost: [initFixedCost],
  
  data: null,

  oldDirections: null,
};


interface InitState {
  isLoading: boolean,
  isCopy: boolean,
  id: any|null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'routesSlice';


  const initialState: InitState = {
    isLoading: false,
    isCopy: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsCopy: (state: InitState, action: PayloadAction<boolean>) => {
      state.isCopy = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any|null>) => {
      state.id = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.routes = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.routes = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;

      if(!state.isCopy){
        state.details = action.payload;
      } else {
        state.isCopy = false;
      }
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('route', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('route/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('route', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('route', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('route', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();