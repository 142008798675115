import axios from 'axios';

import { setSession } from '../utils/utils.tsx';
import { STORAGE_KEY } from '../utils/enums.tsx';

export const API_URL = process.env.REACT_APP_API;

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: API_URL });

let accessToken = localStorage.getItem(STORAGE_KEY);

axiosInstance.defaults.headers.common['Accept-Language'] = '';
axiosInstance.defaults.headers.common.Authorization = `${accessToken}`; //`Bearer ${accessToken}`;

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    try {
      if(error.response.status == 401){
        setSession(null, null, null, false);
        window.location.href = '/';
      }
    } catch(err) {}

    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};
