import PropTypes from 'prop-types';

import LoadingScreen from '../../components/loading/LoadingScreen';
import LoadingPage from '../../components/loading/LoadingPage';

import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

export function AuthConsumer({ children }) {
  return (
    <AuthContext.Consumer>
      {(auth) => {
        if(auth.user){
          return (auth.isLoading ? <LoadingScreen /> : children)
        } else {
          return <>
            {children}
            <LoadingPage isLoading={auth.isLoading} />
          </>
        }
      }}
    </AuthContext.Consumer>
  );
}

AuthConsumer.propTypes = {
  children: PropTypes.node,
};
