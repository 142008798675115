import PropTypes from 'prop-types';
import { useEffect, useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../utils/store.tsx';
import loginSlice from '../../pages/auth/slice.tsx';

import { AuthContext } from './auth-context';
import { isValidToken, setSession, getUser } from '../../utils/utils.tsx';
import { STORAGE_KEY, STORAGE_EXPIRES_AT, STORAGE_USER, STORAGE_USER_ACCOUNT } from '../../utils/enums.tsx';

// ----------------------------------------------------------------------

export function AuthProvider({ children }: any) {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useTypedSelector((state: RootState) => state.loginSlice);

  
  const [user, setUser] = useState(getUser());


  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);
      const tokenExpiresAt = localStorage.getItem(STORAGE_EXPIRES_AT);
      const isValid = isValidToken(accessToken, tokenExpiresAt);
      
      if (accessToken && isValid) {
        setSession(accessToken, tokenExpiresAt, user, false);

        dispatch(loginSlice.callDetailsApi((state, data) => {
          if(state){
            setSession(accessToken, tokenExpiresAt, data, true);
            setUser(data);
          }
        }));
      } else if (accessToken && !isValid) {
        // Call refresh token API
      }
    } catch (error) {}
  }, []);


  useEffect(() => {
    initialize();
  }, [initialize]);


  // LOGIN
  const login = useCallback(async (email: any, password: any, callback: (user: any) => void) => {
    const data = {
      email,
      password,
    };

    dispatch(loginSlice.callLoginApi(data, (state, data) => {
      if(state){
        const { token, tokenExpiry, user } = (data) ? data : null;
        setSession(token, tokenExpiry, user, true);
        setUser(user);
        callback(user);
      }
    }));
  }, []);

  // REGISTER
  const register = useCallback(async (email: any, password: any, accountName: any) => {
    const data = {
      email,
      password,
      accountName,
    };

    dispatch(loginSlice.callRegisterApi(data, (state, data) => {
      if(state){
        const { token, token_expires_at, user } = (data) ? data : null;
        let userAccount = (user && user.userAccount) ? user.userAccount : null;

        localStorage.setItem(STORAGE_KEY, token);
        localStorage.setItem(STORAGE_EXPIRES_AT, token_expires_at);
        localStorage.setItem(STORAGE_USER, JSON.stringify(user));
        localStorage.setItem(STORAGE_USER_ACCOUNT, JSON.stringify(userAccount));
      }
    }));
  }, []);

  // LOGOUT
  const logout = () => {
    dispatch(loginSlice.callLogoutApi());
    setSession(null, null, null, false);
    setUser(null);
    window.location.href = '/';
  };

  // SET USER DATA
  const changeUser = useCallback(async (user: any) => {
    if(user){
      localStorage.setItem(STORAGE_USER, JSON.stringify(user));
      setUser(user);
    }
  }, []);

  // LOGIN WITH TOKEN
  const loginWithToken = useCallback(async (token: any) => {
    if(token){
      localStorage.setItem(STORAGE_KEY, token);
      window.location.href = '/';
    }
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = user ? 'authenticated' : 'unauthenticated';

  const status = isLoading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: user,
      isLoading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      changeUser,
      loginWithToken,
    }),
    [login, logout, register, changeUser, loginWithToken, user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
