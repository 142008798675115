import { 
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Spin } from 'antd';

// ----------------------------------------------------------------------

const StyledContainer = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  .loading-page-overview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #C4C4C4;
    opacity: 0.4;
  }
`;

// ----------------------------------------------------------------------

export default function LoadingPage({ isLoading }: any) {
  return isLoading
  ?
  <StyledContainer fluid className={'overflow-hidden'}>
    <Row className={'align-items-center justify-content-center text-center min-vh-100'}>
      <Col xs={12}>
        <div className={'loading-page-overview'}></div>
        <Spin size="large" />
      </Col>
    </Row>
  </StyledContainer>
  :
  null
}
