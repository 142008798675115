import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';
import breakpoints from './assets/scss/bootstrap.scss';

import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <BootstrapThemeProvider
          breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
          minBreakpoint={'xs'}
        >
          <StyledThemeProvider theme={{ breakpoints: breakpoints }}>
            <App />
          </StyledThemeProvider>
        </BootstrapThemeProvider>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          pauseOnHover={true}
          theme={'light'}
        />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
