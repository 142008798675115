/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, isInPolygon, danger, convertCoordToLatLng, convertLatLngToCoord, convertPositionToLatLng, validateLatLng, isNumeric } from '../../utils/utils';
import Yup from '../../utils/yup';
// import {  } from 'src/utils/enums';


export const fields = {
  isActive: {
    id: 'isActive',
    label: 'Client status',
    placeholder: '',
  },
  clientName: {
    id: 'clientName',
    label: 'Client name',
    placeholder: 'Enter client name',
  },
  address: {
    id: 'address',
    label: 'Address',
    placeholder: 'Enter client address',
  },
  emailAddress: {
    id: 'emailAddress',
    label: 'Office email',
    placeholder: 'Enter email',
  },
  phoneNumber: {
    id: 'phoneNumber',
    label: 'Telephone',
    placeholder: 'Enter telephone',
  },
  clientCategoryId: {
    id: 'clientCategoryId',
    label: 'Category',
    placeholder: 'Choose category',
  },
  clientPaymentTermId: {
    id: 'clientPaymentTermId',
    label: 'Payment terms',
    placeholder: 'Choose payment terms',
  },
  clientCreditTermId: {
    id: 'clientCreditTermId',
    label: 'Credit terms',
    placeholder: 'Choose credit terms',
  },

  contactPersons: {
    id: 'contactPersons',
    label: 'Contacts',
    placeholder: '',
  },
  contactPersonsName: {
    id: 'contactPersonsName',
    label: 'Name',
    placeholder: 'Name',
  },
  contactPersonsEmailAddress: {
    id: 'contactPersonsEmailAddress',
    label: 'Email',
    placeholder: 'Email',
  },
  contactPersonsPhoneNumber: {
    id: 'contactPersonsPhoneNumber',
    label: 'Phone number',
    placeholder: 'Phone number',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
    
    clientName: Yup.string().nullable().required().label(fields.clientName.label),
    address: Yup.string().nullable().label(fields.address.label),
    emailAddress: Yup.string().nullable().email().label(fields.emailAddress.label),
    phoneNumber: Yup.string().nullable().label(fields.phoneNumber.label),


    clientCategoryId: Yup.number().nullable().label(fields.clientCategoryId.label),
    clientPaymentTermId: Yup.number().nullable().label(fields.clientPaymentTermId.label),
    clientCreditTermId: Yup.number().nullable().label(fields.clientCreditTermId.label),

    contactPersons: Yup.array().of(Yup.object({
      name: Yup.string().nullable().label(fields.contactPersonsName.label),
      emailAddress: Yup.string().nullable().email().label(fields.contactPersonsEmailAddress.label),
      phoneNumber: Yup.string().nullable().label(fields.contactPersonsPhoneNumber.label),
    })),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let clientId = (form.clientId) ? form.clientId : null;

    let isActive = ((form.isActive == false) || (form.isActive == true)) ? form.isActive : false;

    let clientName = (form.clientName && form.clientName !== '') ? form.clientName : '';
    let address = (form.address && form.address !== '') ? form.address : '';
    let emailAddress = (form.emailAddress && form.emailAddress !== '') ? form.emailAddress : '';
    let phoneNumber = (form.phoneNumber && form.phoneNumber !== '') ? form.phoneNumber : '';

    let clientCategory = (form.category) ? form.category : null;
    let clientCategoryId = (clientCategory && clientCategory.clientCategoryId && clientCategory.clientCategoryId !== '') ? clientCategory.clientCategoryId : null;
    let clientCategoryName = (clientCategory && clientCategory.clientCategoryName && clientCategory.clientCategoryName !== '') ? clientCategory.clientCategoryName : '';
    
    let clientPaymentTerm = (form.paymentTerm) ? form.paymentTerm : null;
    let clientPaymentTermId = (clientPaymentTerm && clientPaymentTerm.clientPaymentTermId && clientPaymentTerm.clientPaymentTermId !== '') ? clientPaymentTerm.clientPaymentTermId : null;
    let clientPaymentTermName = (clientPaymentTerm && clientPaymentTerm.clientPaymentTermName && clientPaymentTerm.clientPaymentTermName !== '') ? clientPaymentTerm.clientPaymentTermName : '';

    let clientCreditTerm = (form.creditTerm) ? form.creditTerm : null;
    let clientCreditTermId = (clientCreditTerm && clientCreditTerm.clientCreditTermId && clientCreditTerm.clientCreditTermId !== '') ? clientCreditTerm.clientCreditTermId : null;
    let clientCreditTermName = (clientCreditTerm && clientCreditTerm.clientCreditTermName && clientCreditTerm.clientCreditTermName !== '') ? clientCreditTerm.clientCreditTermName : '';


    let contactPersons = (form.contactPersons && form.contactPersons.length > 0)
      ?
      form.contactPersons.map((item: any) => {
        let name = (item && item.name && item.name !== '') ? item.name : '';
        let emailAddress = (item && item.emailAddress && item.emailAddress !== '') ? item.emailAddress : '';
        let phoneNumber = (item && item.phoneNumber && item.phoneNumber !== '') ? item.phoneNumber : '';
      
        let contactPerson: ContactPersons = {
          name: name,
          emailAddress: emailAddress,
          phoneNumber: phoneNumber,
        };
        return contactPerson
      })
    :
    [initContactPersons];


    data['isActive'] = isActive;

    data['clientId'] = clientId;
    data['clientName'] = clientName;
    data['address'] = address;
    data['emailAddress'] = emailAddress;
    data['phoneNumber'] = phoneNumber;

    data['clientCategoryId'] = clientCategoryId;
    data['clientCategoryName'] = clientCategoryName;

    data['clientPaymentTermId'] = clientPaymentTermId;
    data['clientPaymentTermName'] = clientPaymentTermName;
    
    data['clientCreditTermId'] = clientCreditTermId;
    data['clientCreditTermName'] = clientCreditTermName;

    data['contactPersons'] = contactPersons;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.clientId){
      data['clientId'] = values.clientId;
    }
    
    data['isActive'] = values.isActive;

    data['clientName'] = values.clientName;
    data['address'] = values.address;
    data['emailAddress'] = values.emailAddress;
    data['phoneNumber'] = values.phoneNumber;

    data['clientCategoryId'] = values.clientCategoryId;
    data['clientPaymentTermId'] = values.clientPaymentTermId;
    data['clientCreditTermId'] = values.clientCreditTermId;

    let contactPersons: Array<any> = [];
    if(values.contactPersons && values.contactPersons.length > 0){
      values.contactPersons.forEach((item: any, i: number) => {
        if(
          (values.contactPersons.length == 1) &&
          (item.name === null || item.name === undefined || item.name === '') && 
          (item.emailAddress === null || item.emailAddress === undefined || item.emailAddress === '') && 
          (item.phoneNumber === null || item.phoneNumber === undefined || item.phoneNumber === '')
        ){
          //nop
        } else {
          let name = (item && item.name && item.name !== '') ? item.name : '';
          let emailAddress = (item && item.emailAddress && item.emailAddress !== '') ? item.emailAddress : '';
          let phoneNumber = (item && item.phoneNumber && item.phoneNumber !== '') ? item.phoneNumber : '';

          contactPersons.push({
            name: name,
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
          })
        }
      });
    }
    data['contactPersons'] = contactPersons;
  }

  return data;
};


interface ContactPersons {
  name: string|null,
  emailAddress: string|null,
  phoneNumber: string|null,
};
export const initContactPersons: ContactPersons = {
  name: '',
  emailAddress: '',
  phoneNumber: '',
};


export interface initialValuesStruct {
  isActive: boolean,

  clientId: number|null,
  clientName: string,
  address: string,
  emailAddress: string,
  phoneNumber: string,

  clientCategoryId: any,
  clientCategoryName: string,
  
  clientPaymentTermId: any,
  clientPaymentTermName: string,
  
  clientCreditTermId: any,
  clientCreditTermName: string,
  
  contactPersons: Array<ContactPersons>,
};
export const initialValues: initialValuesStruct = {
  isActive: true, 

  clientId: null,
  clientName: '',
  address: '',
  emailAddress: '',
  phoneNumber: '',

  clientCategoryId: null,
  clientCategoryName: '',
  
  clientPaymentTermId: null,
  clientPaymentTermName: '',
  
  clientCreditTermId: null,
  clientCreditTermName: '',

  contactPersons: [initContactPersons],
};

export type Client = {
  isActive: boolean

  clientId: number
  clientName: string
  address: string
  emailAddress: string
  phoneNumber: string

  clientCategoryId: number|null
  clientCategoryName: string

  clientPaymentTermId: number|null
  clientPaymentTermName: string

  clientCreditTermId: number|null
  clientCreditTermName: string

  contactPersons: Array<ContactPersons>
}


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'clientsSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.clients = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.clients = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('client', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('client/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('client', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('client', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('client', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();