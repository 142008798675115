/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
// import moment from 'moment';

import axios from '../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, saveDate, prepareDate, isNumeric, round } from '../../utils/utils';
import Yup from '../../utils/yup';
import { RouteTaskActivity, MidPointType, CostType } from 'src/utils/enums';


export const fields = {
  status: {
    id: 'status',
    label: 'Status',
    placeholder: '',
  },
  jobDate: {
    id: 'jobDate',
    label: 'Job date',
    placeholder: 'Select date',
  },
  routeId: {
    id: 'routeId',
    label: 'Route',
    placeholder: 'Select saved route',
  },
  routeTaskName: {
    id: 'routeTaskName',
    label: 'Track name',
    placeholder: '',
  },
  consignor: {
    id: 'consignor',
    label: '',
    placeholder: 'Select consignor',
  },
  consignee: {
    id: 'consignee',
    label: '',
    placeholder: 'Select consignee',
  },
  loadItems: {
    id: 'loadItems',
    label: '',
    placeholder: 'Select items to load',
  },
  unloadItems: {
    id: 'unloadItems',
    label: '',
    placeholder: 'Select items to unload',
  },

  jobBookingTemplateId: {
    id: 'jobBookingTemplateId',
    label: '',
    placeholder: 'Select template',
  },
  jobBookingId: {
    id: 'jobBookingId',
    label: '',
    placeholder: 'Select template',
  },
  bookingDate: {
    id: 'bookingDate',
    label: 'Booking date',
    placeholder: 'Enter booking date',
  },
  bookingBy: {
    id: 'bookingBy',
    label: 'Booking by',
    placeholder: 'Enter the user name who book this',
  },
  bookingNumber: {
    id: 'bookingNumber',
    label: 'Booking #',
    placeholder: 'Enter booking number',
  },
  vendorId: {
    id: 'vendorId',
    label: 'Vendor',
    placeholder: 'Select vendor',
  },
  transporterId: {
    id: 'transporterId',
    label: 'Transporter',
    placeholder: 'Select transporter',
  },
  vendorEmail: {
    id: 'vendorEmail',
    label: 'Vendor\'s email',
    placeholder: 'Enter email address',
  },
  purchaseOrderNumber: {
    id: 'purchaseOrderNumber',
    label: 'PO #',
    placeholder: 'Enter purchase order number',
  },
  salesPerson: {
    id: 'salesPerson',
    label: 'Sales person',
    placeholder: 'Enter sales person',
  },
  bookingRemarks: {
    id: 'bookingRemarks',
    label: 'Remarks',
    placeholder: 'Enter remarks',
  },

  clientId: {
    id: 'clientId',
    label: 'Client',
    placeholder: 'Select client',
  },
  transportItems: {
    id: 'transportItems',
    label: 'Transport items',
    placeholder: 'Enter transport content',
  },
  weight: {
    id: 'weight',
    label: 'Weight (ton)',
    placeholder: 'Enter weight',
  },
  cargoVolume: {
    id: 'cargoVolume',
    label: 'Cargo volume (m3)',
    placeholder: 'Enter cargo volume in m3',
  },
  totalVariableCharge: {
    id: 'totalVariableCharge',
    label: 'Total variable charge',
    placeholder: 'Sum of all variable charge',
  },
  totalFixedCharge: {
    id: 'totalFixedCharge',
    label: 'Total of all fixed charges',
    placeholder: 'Sum of all fixed charge',
  },
  transportRemarks: {
    id: 'transportRemarks',
    label: 'Remarks',
    placeholder: 'Enter remarks',
  },
  
  vehicleId: {
    id: 'vehicleId',
    label: '',
    placeholder: 'Select vehicle',
  },
  driverId: {
    id: 'driverId',
    label: 'Driver',
    placeholder: 'Select driver',
  },
  driverMobile: {
    id: 'driverMobile',
    label: 'Driver mobile',
    placeholder: 'Enter driver mobile',
  },
  cargoType: {
    id: 'cargoType',
    label: 'Cargo type',
    placeholder: 'Enter cargo type',
  },
  vehicleCargoVolume: {
    id: 'vehicleCargoVolume',
    label: 'Volume',
    placeholder: 'Enter cargo volume',
  },
  trailerId: {
    id: 'trailerId',
    label: 'Trailer ID',
    placeholder: 'Enter trailer ID',
  },
  billTo: {
    id: 'billTo',
    label: 'Bill to',
    placeholder: 'Select client',
  },
  variableTripCharge: {
    id: 'variableTripCharge',
    label: 'Trip charge',
    placeholder: 'Enter variable charge',
  },
  fixedTripCharge: {
    id: 'fixedTripCharge',
    label: 'Trip charge',
    placeholder: 'Enter fix charge',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    jobDate: Yup.string().nullable().required().minDate().label(fields.jobDate.label),
    routeId: Yup.number().nullable().required().label(fields.routeId.label),
    
    routeTasks: Yup.array().of(Yup.object({
      routeTaskName: Yup.string().nullable().label(fields.routeTaskName.label),
      clientId: Yup.number().nullable().label(fields.clientId.label),
      
      items: Yup.array().of(Yup.object({
        itemName: Yup.string().nullable().label(fields.loadItems.label),
      }))
      .hasEmpty('itemName'),
    })),

    jobBookingId: Yup.number().nullable().label(fields.jobBookingId.label),
    bookingDate: Yup.string().nullable().required().maxDate().label(fields.bookingDate.label),
    bookingBy: Yup.string().nullable().label(fields.bookingBy.label),
    bookingNumber: Yup.string().nullable().label(fields.bookingNumber.label),
    vendorId: Yup.number().nullable().label(fields.vendorId.label),
    transporterId: Yup.number().nullable().label(fields.transporterId.label),
    vendorEmail: Yup.string().nullable().email().label(fields.vendorEmail.label),
    purchaseOrderNumber: Yup.string().nullable().label(fields.purchaseOrderNumber.label),
    salesPerson: Yup.string().nullable().label(fields.salesPerson.label),
    bookingRemarks: Yup.string().nullable().label(fields.bookingRemarks.label),

    clientId: Yup.number().nullable().label(fields.clientId.label),
    transportItems: Yup.string().nullable().label(fields.transportItems.label),
    weight: Yup.number().nullable().min(0).label(fields.weight.label),
    cargoVolume: Yup.number().nullable().min(0).label(fields.cargoVolume.label),
    totalVariableCharge: Yup.number().nullable().min(0).label(fields.totalVariableCharge.label),
    totalFixedCharge: Yup.number().nullable().min(0).label(fields.totalFixedCharge.label),
    transportRemarks: Yup.string().nullable().label(fields.transportRemarks.label),

    assignedVehicles: Yup.array().of(Yup.object({
      vehicleId: Yup.number().nullable().label(fields.vehicleId.label),
      driverId: Yup.number().when(['vehicleId'], ([vehicleId]) => {
        if(vehicleId){
          return Yup.number().nullable().required().label(fields.driverId.label);
        } else {
          return Yup.number().nullable().label(fields.driverId.label);
        }
      }),
      driverMobile: Yup.string().nullable().label(fields.driverMobile.label),
      cargoType: Yup.string().nullable().label(fields.cargoType.label),
      cargoVolume: Yup.number().nullable().min(0).label(fields.vehicleCargoVolume.label),
      trailerId: Yup.string().nullable().label(fields.trailerId.label),
      clientId: Yup.number().nullable().label(fields.billTo.label),
      
      variableTripCharges: Yup.array().of(Yup.object({
        cost: Yup.number().nullable().min(0).label(fields.variableTripCharge.label),
      }))
      .hasEmpty('cost'),

      fixedTripCharges: Yup.array().of(Yup.object({
        cost: Yup.number().nullable().min(0).label(fields.fixedTripCharge.label),
      }))
      .hasEmpty('cost'),
    }))
    .hasEmpty('vehicleId')
    .unique('vehicleId'),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null, jobBookingTemplateId: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let route = (form.route) ? form.route : null;
    let routeId = (route && route.routeId) ? route.routeId : null;
    let routeName = (route && route.routeName && route.routeName !== '') ? route.routeName : '';
    let jobDate = prepareDate(form.jobDate);

    let routeTasks = (form.routeTasks && form.routeTasks.length > 0)
      ?
      form.routeTasks.map((item: any) => {
        let midPointGeofenceId = (item && item.midPointGeofenceId) ? item.midPointGeofenceId : null;
        let routeTaskName = (item && item.routeTaskName && item.routeTaskName !== '') ? item.routeTaskName : '';

        let routeClient = (item && item.client) ? item.client : null;
        let routeClientId = (routeClient && routeClient.clientId) ? routeClient.clientId : null;
        let routeClientName = (routeClient && routeClient.clientName && routeClient.clientName !== '') ? routeClient.clientName : '';

        let items: Array<LoadItem> = [];
        if((item && item.items && item.items.length > 0)){
          item.items.forEach((subItem: any, j: number) => {
            let itemName = (subItem && subItem.itemName && subItem.itemName !== '') ? subItem.itemName : '';

            items.push({
              itemName: itemName,
              sortOrder: subItem.sortOrder,
            });
          });
        }
        if(items && items.length === 0){
          items = [initLoadItem];
        }

        let routeTask: RouteTask = {
          midPointGeofenceId: midPointGeofenceId,
          routeTaskName: routeTaskName,
          isStartPoint: item.isStartPoint,
          isEndPoint: item.isEndPoint,
          clientId: routeClientId,
          clientName: routeClientName,
          activity: item.activity,
          items: items,
          sortOrder: item.sortOrder
        };
        return routeTask
      })
    :
    [initRouteTask];


    let bookingDate = prepareDate(form.bookingDate);
    let bookingBy = (form.bookingBy && form.bookingBy !== '') ? form.bookingBy : '';
    let bookingNumber = (form.bookingNumber && form.bookingNumber !== '') ? form.bookingNumber : '';

    let vendor = (form.vendor) ? form.vendor : null;
    let vendorId = (vendor && vendor.clientId) ? vendor.clientId : null;
    let vendorName = (vendor && vendor.clientName && vendor.clientName !== '') ? vendor.clientName : '';

    let transporter = (form.transporter) ? form.transporter : null;
    let transporterId = (transporter && transporter.clientId) ? transporter.clientId : null;
    let transporterName = (transporter && transporter.clientName && transporter.clientName !== '') ? transporter.clientName : '';

    let vendorEmail = (form.vendorEmail && form.vendorEmail !== '') ? form.vendorEmail : '';
    let purchaseOrderNumber = (form.purchaseOrderNumber && form.purchaseOrderNumber !== '') ? form.purchaseOrderNumber : '';
    let salesPerson = (form.salesPerson && form.salesPerson !== '') ? form.salesPerson : '';
    let bookingRemarks = (form.bookingRemarks && form.bookingRemarks !== '') ? form.bookingRemarks : '';

    let client = (form.client) ? form.client : null;
    let clientId = (client && client.clientId) ? client.clientId : null;
    let clientName = (client && client.clientName && client.clientName !== '') ? client.clientName : '';
    let transportItems = (form.transportItems && form.transportItems !== '') ? form.transportItems : '';
    let weight = (form.weight) ? form.weight : null;
    let cargoVolume = (form.cargoVolume) ? form.cargoVolume : null;
    let totalVariableCharge = (form.totalVariableCharge) ? form.totalVariableCharge : null;
    let totalFixedCharge = (form.totalFixedCharge) ? form.totalFixedCharge : null;
    let transportRemarks = (form.transportRemarks && form.transportRemarks !== '') ? form.transportRemarks : '';

    let assignedVehicles = (form.assignedVehicles && form.assignedVehicles.length > 0)
      ?
      form.assignedVehicles.map((item: any) => {
        let vehicle = (item && item.vehicle) ? item.vehicle : null;
        let vehicleId = (vehicle && vehicle.vehicleId) ? vehicle.vehicleId : null;
        let vehicleName = (vehicle && vehicle.vehicleName && vehicle.vehicleName !== '') ? vehicle.vehicleName : '';

        let driver = (item && item.driver) ? item.driver : null;
        let driverId = (driver && driver.driverId) ? driver.driverId : null;
        let driverName = (driver && driver.driverName && driver.driverName !== '') ? driver.driverName : '';

        let driverMobile = (item && item.driverMobile && item.driverMobile !== '') ? item.driverMobile : '';
        let cargoType = (item && item.cargoType && item.cargoType !== '') ? item.cargoType : '';
        let cargoVolume = (item && item.cargoVolume) ? item.cargoVolume : null;
        let trailerId = (item && item.trailerId && item.trailerId !== '') ? item.trailerId : '';
      
        let vehicleClient = (item && item.client) ? item.client : null;
        let vehicleClientId = (vehicleClient && vehicleClient.clientId) ? vehicleClient.clientId : null;
        let vehicleClientName = (vehicleClient && vehicleClient.clientName && vehicleClient.clientName !== '') ? vehicleClient.clientName : '';
        
        let variableTripCharges: Array<VariableTrip> = [];
        let fixedTripCharges: Array<FixTrip> = [];
        if((item && item.costs && item.costs.length > 0)){
          item.costs.forEach((subItem: any, j: number) => {
            let cost = (subItem && subItem.cost) ? subItem.cost : null;

            if(subItem.type === CostType.FixedCost){
              fixedTripCharges.push({
                cost: cost,
                type: subItem.type,
                sortOrder: subItem.sortOrder,
              });
            } else {
              variableTripCharges.push({
                cost: cost,
                type: subItem.type,
                sortOrder: subItem.sortOrder,
              });
            }
          });
        }
        if(variableTripCharges && variableTripCharges.length === 0){
          variableTripCharges = [initVariableTrip];
        }
        if(fixedTripCharges && fixedTripCharges.length === 0){
          fixedTripCharges = [initFixTrip];
        }

        let assignedVehicle: AssignedVehicles = {
          vehicleId: vehicleId,
          vehicleName: vehicleName,
          driverId: driverId,
          driverName: driverName,
          driverMobile: driverMobile,
          cargoType: cargoType,
          cargoVolume: cargoVolume,
          trailerId: trailerId,
          clientId: vehicleClientId,
          clientName: vehicleClientName,
          variableTripCharges: variableTripCharges,
          fixedTripCharges: fixedTripCharges,
          sortOrder: item.sortOrder,
        };
        return assignedVehicle
      })
    :
    [initAssignedVehicles];


    let totalDistance = (form.totalDistance) ? form.totalDistance : null;


    if(jobBookingTemplateId){
      data['jobBookingTemplateId'] = jobBookingTemplateId;
    }

    if(form.jobBookingId){
      data['jobBookingId'] = form.jobBookingId;
    }

    data['routeId'] = routeId;
    data['routeName'] = routeName;
    data['jobDate'] = jobDate;
    data['routeTasks'] = routeTasks;

    data['bookingDate'] = bookingDate;
    data['bookingBy'] = bookingBy;
    data['bookingNumber'] = bookingNumber;
    data['vendorId'] = vendorId;
    data['vendorName'] = vendorName;
    data['transporterId'] = transporterId;
    data['transporterName'] = transporterName;
    data['vendorEmail'] = vendorEmail;
    data['purchaseOrderNumber'] = purchaseOrderNumber;
    data['salesPerson'] = salesPerson;
    data['bookingRemarks'] = bookingRemarks;
    
    data['clientId'] = clientId;
    data['clientName'] = clientName;
    data['transportItems'] = transportItems;
    data['weight'] = weight;
    data['cargoVolume'] = cargoVolume;
    data['totalVariableCharge'] = totalVariableCharge;
    data['totalFixedCharge'] = totalFixedCharge;
    data['transportRemarks'] = transportRemarks;

    data['assignedVehicles'] = assignedVehicles;
    data['totalDistance'] = totalDistance;
  }
  
  return data;
};
export const prepareData = (values: any = null, jobBookingTemplateId: any = null) => {
  let data: any = {};

  if(values){
    if(jobBookingTemplateId == null){
      if(values.jobBookingId){
        data['jobBookingId'] = values.jobBookingId;
      }
    }
    
    let jobDate = saveDate(values.jobDate);
    let bookingDate = saveDate(values.bookingDate);
    
    data['routeId'] = values.routeId;
    data['jobDate'] = jobDate;

    let routeTasks: Array<any> = [];
    if(values.routeTasks && values.routeTasks.length > 0){
      values.routeTasks.forEach((item: any, i: number) => {
        if(
          (values.routeTasks.length == 1) &&
          (item.midPointGeofenceId === null || item.midPointGeofenceId === undefined)
        ){
          //nop
        } else {
          let midPointGeofenceId = (item && item.midPointGeofenceId) ? item.midPointGeofenceId : null;
          let routeTaskName = (item && item.routeTaskName && item.routeTaskName !== '') ? item.routeTaskName : '';
          let routeClientId = (item && item.clientId) ? item.clientId : null;

          let items: Array<any> = [];
          if((item && item.items && item.items.length > 0)){
            item.items.forEach((subItem: any, j: number) => {
              if(subItem.itemName != null && subItem.itemName != undefined && subItem.itemName != ''){
                items.push({
                  itemName: subItem.itemName,
                  sortOrder: j,
                });
              }
            });
          }

          let routeTask: any = {
            midPointGeofenceId: midPointGeofenceId,
            routeTaskName: routeTaskName,
            isStartPoint: item.isStartPoint,
            isEndPoint: item.isEndPoint,
            clientId: routeClientId,
            activity: item.activity,
            items: items,
            sortOrder: i
          };
          routeTasks.push(routeTask);
        }
      });
    }
    data['routeTasks'] = routeTasks;

    data['bookingDate'] = bookingDate;
    data['bookingBy'] = values.bookingBy;
    data['bookingNumber'] = values.bookingNumber;
    data['vendorId'] = values.vendorId;
    data['transporterId'] = values.transporterId;
    data['vendorEmail'] = values.vendorEmail;
    data['purchaseOrderNumber'] = values.purchaseOrderNumber;
    data['salesPerson'] = values.salesPerson;
    data['bookingRemarks'] = values.bookingRemarks;

    data['clientId'] = values.clientId;
    data['transportItems'] = values.transportItems;
    data['weight'] = values.weight;
    data['cargoVolume'] = values.cargoVolume;
    data['totalVariableCharge'] = values.totalVariableCharge;
    data['totalFixedCharge'] = values.totalFixedCharge;
    data['transportRemarks'] = values.transportRemarks;

    let assignedVehicles: Array<any> = [];
    if(values.assignedVehicles && values.assignedVehicles.length > 0){
      values.assignedVehicles.forEach((item: any, i: number) => {
        if(
          (values.assignedVehicles.length == 1) &&
          (item.vehicleId === null || item.vehicleId === undefined)
        ){
          //nop
        } else {
          let vehicleId = (item && item.vehicleId) ? item.vehicleId : null;
          let driverId = (item && item.driverId) ? item.driverId : null;
          let driverMobile = (item && item.driverMobile && item.driverMobile !== '') ? item.driverMobile : '';
          let cargoType = (item && item.cargoType && item.cargoType !== '') ? item.cargoType : '';
          let cargoVolume = (item && item.cargoVolume) ? item.cargoVolume : null;
          let trailerId = (item && item.trailerId && item.trailerId !== '') ? item.trailerId : '';
          let vehicleClientId = (item && item.clientId) ? item && item.clientId : null;
          
          let costs: Array<any> = [];
          if((item && item.variableTripCharges && item.variableTripCharges.length > 0)){
            item.variableTripCharges.forEach((subItem: any, j: number) => {
              let cost = isNumeric(subItem.cost) ? round(subItem.cost, 2) : 0;

              subItem.cost = cost;
              subItem.sortOrder = j;
              subItem.type = CostType.OtherCost;
              costs.push(subItem);
            });
          }
          if((item && item.fixedTripCharges && item.fixedTripCharges.length > 0)){
            item.fixedTripCharges.forEach((subItem: any, j: number) => {
              let cost = isNumeric(subItem.cost) ? round(subItem.cost, 2) : 0;

              subItem.cost = cost;
              subItem.sortOrder = j;
              subItem.type = CostType.FixedCost;
              costs.push(subItem);
            });
          }

          let assignedVehicle: any = {
            vehicleId: vehicleId,
            driverId: driverId,
            driverMobile: driverMobile,
            cargoType: cargoType,
            cargoVolume: cargoVolume,
            trailerId: trailerId,
            clientId: vehicleClientId,
            costs: costs,
            sortOrder: i,
          };
          assignedVehicles.push(assignedVehicle)
        }
      });
    }
    data['assignedVehicles'] = assignedVehicles;
  }

  return data;
};
export const makeFakeData = (count: number = 1, date: string) => {
  let data: Array<any> = [];

  for(let i = 0; i < count; i++){
    let routeTasks: Array<any> = [];
    for(let j = 0; j < 3; j++){
      let midPointGeofenceId = (j == 0) ? 2638 : (j == 2) ? 2639 : 2623;
      let routeTaskName = 'Route name ' + (j+1);
      let routeClientId = (j == 0) ? 28 : 29;
  
      let items: Array<any> = [];
      for(let k = 0; k < 3; k++){
        items.push({
          itemName: 'Item name ' + (k+1),
          sortOrder: k,
        });
      }
  
      let routeTask: any = {
        midPointGeofenceId: midPointGeofenceId,
        routeTaskName: routeTaskName,
        isStartPoint: (j == 0) ? true : false,
        isEndPoint: (j == 2) ? true : false,
        clientId: routeClientId,
        activity: 0,
        items: items,
        sortOrder: j
      };
      routeTasks.push(routeTask);
    }
  
    let assignedVehicles: Array<any> = [];
    for(let j = 0; j < 1; j++){
      let vehicleId = 26;
      let driverId = 6;
      let driverMobile = '037-350-20-76';
      let cargoType = 'Cargo Type';
      let cargoVolume = 1500;
      let trailerId = '1234';
      let vehicleClientId = 25;
      
      let costs: Array<any> = [];
      for(let k = 0; k < 2; k++){
        costs.push({
          cost: 10+k,
          type: CostType.OtherCost,
          sortOrder: k,
        });
      }
  
      for(let k = 0; k < 2; k++){
        costs.push({
          cost: 20+k,
          type: CostType.FixedCost,
          sortOrder: k,
        });
      }
  
      let assignedVehicle: any = {
        vehicleId: vehicleId,
        driverId: driverId,
        driverMobile: driverMobile,
        cargoType: cargoType,
        cargoVolume: cargoVolume,
        trailerId: trailerId,
        clientId: vehicleClientId,
        costs: costs,
        sortOrder: j,
      };
      assignedVehicles.push(assignedVehicle)
    }
  
    let jobDate = saveDate(date);
    let bookingDate = saveDate(date);

    let obj = {
      routeId: 45,
      jobDate: jobDate,
      routeTasks: routeTasks,
  
      bookingDate: bookingDate,
      bookingBy: 'Milan',
      bookingNumber: null,
      vendorId: 26,
      transporterId: 25,
      vendorEmail: 'vendor' + (i+1) + '@gmail.com',
      purchaseOrderNumber: '1234-' + (i+1),
      salesPerson: 'Salesman',
      bookingRemarks: 'Booking Remarks',
  
      clientId: 25,
      transportItems: 'Electronics',
      weight: 600,
      cargoVolume: 1200,
      totalVariableCharge: 500,
      totalFixedCharge: 300,
      transportRemarks: 'Transport Remarks',
  
      assignedVehicles: assignedVehicles,
    }

    data.push(obj);
  }

  return data;
};
export const prepareSaveAsTemplateData = (values: any = null, templateName: any = null) => {
  let data: any = {};

  if(values){
    data['templateName'] = templateName;
    
    data['routeId'] = values.routeId;

    let routeTasks: Array<any> = [];
    if(values.routeTasks && values.routeTasks.length > 0){
      values.routeTasks.forEach((item: any, i: number) => {
        if(
          (values.routeTasks.length == 1) &&
          (item.midPointGeofenceId === null || item.midPointGeofenceId === undefined)
        ){
          //nop
        } else {
          let midPointGeofenceId = (item && item.midPointGeofenceId) ? item.midPointGeofenceId : null;
          let routeTaskName = (item && item.routeTaskName && item.routeTaskName !== '') ? item.routeTaskName : '';
          let routeClientId = (item && item.clientId) ? item.clientId : null;

          let items: Array<any> = [];
          if((item && item.items && item.items.length > 0)){
            item.items.forEach((subItem: any, j: number) => {
              if(subItem.itemName != null && subItem.itemName != undefined && subItem.itemName != ''){
                items.push({
                  itemName: subItem.itemName,
                  sortOrder: j,
                });
              }
            });
          }

          let routeTask: any = {
            midPointGeofenceId: midPointGeofenceId,
            routeTaskName: routeTaskName,
            isStartPoint: item.isStartPoint,
            isEndPoint: item.isEndPoint,
            clientId: routeClientId,
            activity: item.activity,
            items: items,
            sortOrder: i
          };
          routeTasks.push(routeTask);
        }
      });
    }
    data['routeTasks'] = routeTasks;

    data['bookingBy'] = values.bookingBy;
    data['vendorId'] = values.vendorId;
    data['transporterId'] = values.transporterId;
    data['vendorEmail'] = values.vendorEmail;
    data['purchaseOrderNumber'] = values.purchaseOrderNumber;
    data['salesPerson'] = values.salesPerson;
    data['bookingRemarks'] = values.bookingRemarks;

    data['clientId'] = values.clientId;
    data['transportItems'] = values.transportItems;
    data['weight'] = values.weight;
    data['cargoVolume'] = values.cargoVolume;
    data['totalVariableCharge'] = values.totalVariableCharge;
    data['totalFixedCharge'] = values.totalFixedCharge;
    data['transportRemarks'] = values.transportRemarks;

    let assignedVehicles: Array<any> = [];
    if(values.assignedVehicles && values.assignedVehicles.length > 0){
      values.assignedVehicles.forEach((item: any, i: number) => {
        if(
          (values.assignedVehicles.length == 1) &&
          (item.vehicleId === null || item.vehicleId === undefined)
        ){
          //nop
        } else {
          let vehicleId = (item && item.vehicleId) ? item.vehicleId : null;
          let driverId = (item && item.driverId) ? item.driverId : null;
          let driverMobile = (item && item.driverMobile && item.driverMobile !== '') ? item.driverMobile : '';
          let cargoType = (item && item.cargoType && item.cargoType !== '') ? item.cargoType : '';
          let cargoVolume = (item && item.cargoVolume) ? item.cargoVolume : null;
          let trailerId = (item && item.trailerId && item.trailerId !== '') ? item.trailerId : '';
          let vehicleClientId = (item && item.clientId) ? item && item.clientId : null;
          
          let costs: Array<any> = [];
          if((item && item.variableTripCharges && item.variableTripCharges.length > 0)){
            item.variableTripCharges.forEach((subItem: any, j: number) => {
              let cost = isNumeric(subItem.cost) ? round(subItem.cost, 2) : 0;
              
              subItem.cost = cost;
              subItem.sortOrder = j;
              subItem.type = CostType.OtherCost;
              costs.push(subItem);
            });
          }
          if((item && item.fixedTripCharges && item.fixedTripCharges.length > 0)){
            item.fixedTripCharges.forEach((subItem: any, j: number) => {
              let cost = isNumeric(subItem.cost) ? round(subItem.cost, 2) : 0;

              subItem.cost = cost;
              subItem.sortOrder = j;
              subItem.type = CostType.FixedCost;
              costs.push(subItem);
            });
          }

          let assignedVehicle: any = {
            vehicleId: vehicleId,
            driverId: driverId,
            driverMobile: driverMobile,
            cargoType: cargoType,
            cargoVolume: cargoVolume,
            trailerId: trailerId,
            clientId: vehicleClientId,
            costs: costs,
            sortOrder: i,
          };
          assignedVehicles.push(assignedVehicle)
        }
      });
    }
    data['assignedVehicles'] = assignedVehicles;
  }

  return data;
};
export const prepareRouteForm = (form: any = null) => {
  if(form){
    let routeTasks: Array<RouteTask> = [];
    let count = 0;
    
    let startPoint: any = {
      midPointGeofenceId: form.startPointGeofenceId,
      routeTaskName: form.startPointName,
      sortOrder: count,
      isStartPoint: true,
      isEndPoint: false,
      clientId: null,
      clientName: '',
      activity: RouteTaskActivity.Load,
      items: [initLoadItem]
    }
    routeTasks.push(startPoint);

    if(form.routeMidPoints && form.routeMidPoints.length > 0){
      form.routeMidPoints.filter((x: any) => x.type === MidPointType.MidPoint).forEach((item: any, i: number) => {
        count = count+1;

        let midPoint: any = {
          midPointGeofenceId: item.midPointGeofenceId,
          routeTaskName: item.midPointName,
          sortOrder: count,
          isStartPoint: false,
          isEndPoint: false,
          clientId: null,
          clientName: '',
          activity: RouteTaskActivity.Unload,
          items: [initLoadItem]
        }
        routeTasks.push(midPoint);
      });
    }

    count = count+1;
    let endPoint: any = {
      midPointGeofenceId: form.endPointGeofenceId,
      routeTaskName: form.endPointName,
      sortOrder: count,
      isStartPoint: false,
      isEndPoint: true,
      clientId: null,
      clientName: '',
      activity: RouteTaskActivity.Unload,
      items: [initLoadItem]
    }
    routeTasks.push(endPoint);

    return routeTasks;
  } else {
    return [initRouteTask]
  }
};
export const prepareVehicleForm = (form: any = null, totalDistance: any = null) => {
  if(form){
    let defaultDriver = (form.defaultDriver) ? form.defaultDriver : null;
    let driverId = (defaultDriver && defaultDriver.driverId) ? defaultDriver.driverId : null;
    let driverName = (defaultDriver && defaultDriver.driverName && defaultDriver.driverName !== '') ? defaultDriver.driverName : '';
    let phoneNumber = (defaultDriver && defaultDriver.phoneNumber && defaultDriver.phoneNumber !== '') ? defaultDriver.phoneNumber : '';
    let trailerIdName = (form.trailerIdName && form.trailerIdName !== '') ? form.trailerIdName : '';
    
    let fuelCost = isNumeric(form.fuelCost) ? round(form.fuelCost, 2) : 0;
    let tyreCost = isNumeric(form.tyreCost) ? round(form.tyreCost, 2) : 0;
    
    let variableCost = fuelCost + tyreCost;
    let fixedCost = 0;

    let variableTripCharges: Array<VariableTrip> = [];
    let fixedTripCharges: Array<FixTrip> = [];
    if(form.vehicleCosts && form.vehicleCosts.length > 0){
      form.vehicleCosts.forEach((item: any, i: number) => {
        let cost = isNumeric(item.cost) ? round(item.cost, 2) : 0;

        if(item.type == CostType.FixedCost){
          fixedCost = fixedCost + cost;
        } else {
          variableCost = variableCost + cost;
        }
      });
    }
  
    let totalDist = isNumeric(totalDistance) ? round(totalDistance, 2) : null;
    variableCost = totalDist ? variableCost * totalDist : variableCost;
    variableTripCharges.push({
      cost: variableCost,
      type: CostType.OtherCost,
      sortOrder: 0
    });

    fixedTripCharges.push({
      cost: fixedCost,
      type: CostType.FixedCost,
      sortOrder: 0
    });
    
    return {
      driverId: driverId,
      driverName: driverName,
      driverMobile: phoneNumber,
      trailerId: trailerIdName,
      variableTripCharges: variableTripCharges,
      fixedTripCharges: fixedTripCharges,
    };
  } else {
    return null
  }
};
export const calcRouteTotalCost = (detailsRoute: any = null) => {
  if(detailsRoute){
    let totalDist = isNumeric(detailsRoute.totalDistance) ? round(detailsRoute.totalDistance, 2) : null;
    let fuelCost = isNumeric(detailsRoute.fuelCost) ? round(detailsRoute.fuelCost, 2) : 0;
    let tyreCost = isNumeric(detailsRoute.tyreCost) ? round(detailsRoute.tyreCost, 2) : 0;
    
    let totalVariableCharge = fuelCost + tyreCost;
    let totalFixedCharge = 0;

    if(detailsRoute.routeCosts && detailsRoute.routeCosts.length > 0){
      detailsRoute.routeCosts.forEach((item: any, i: number) => {
        let cost = isNumeric(item.cost) ? round(item.cost, 2) : 0;

        if(item.type == CostType.FixedCost){
          totalFixedCharge = totalFixedCharge + cost;
        } else {
          totalVariableCharge = totalVariableCharge + cost;
        }
      });
    }

    totalVariableCharge = totalDist ? totalVariableCharge * totalDist : totalVariableCharge;

    return {
      totalVariableCharge: round(totalVariableCharge, 2),
      totalFixedCharge: round(totalFixedCharge, 2),
    }
  } else {
    return null;
  }
}
export const calcVehicleTotalCost = (form: any = null, totalDistance: any = null, isFromDetails: boolean = true) => {
  if(form){
    let variableCost = 0;
    let fixedCost = 0;

    if(isFromDetails){
      let fuelCost = isNumeric(form.fuelCost) ? round(form.fuelCost, 2) : 0;
      let tyreCost = isNumeric(form.tyreCost) ? round(form.tyreCost, 2) : 0;
      
      variableCost = fuelCost + tyreCost;
      fixedCost = 0;

      if(form.vehicleCosts && form.vehicleCosts.length > 0){
        form.vehicleCosts.forEach((item: any, i: number) => {
          let cost = isNumeric(item.cost) ? round(item.cost, 2) : 0;

          if(item.type == CostType.FixedCost){
            fixedCost = fixedCost + cost;
          } else {
            variableCost = variableCost + cost;
          }
        });
      }
    
      let totalDist = isNumeric(totalDistance) ? round(totalDistance, 2) : null;
      variableCost = totalDist ? variableCost * totalDist : variableCost;
    } else {

    }

    return {
      variableCost: round(variableCost, 2),
      fixedCost: round(fixedCost, 2),
    }
  } else {
    return null
  }
};
export const calcVehicleRouteTotalCost = (assignedVehicles: any = [], totalDistance: any = null, totalVariableCharge: any = 0, totalFixedCharge: any = 0) => {
  let totalVariableC = isNumeric(totalVariableCharge) ? round(totalVariableCharge, 2) : 0;
  let totalFixedC = isNumeric(totalFixedCharge) ? round(totalFixedCharge, 2) : 0;
  
  if(assignedVehicles && assignedVehicles.length > 0){
    assignedVehicles.forEach((vehicleData: any) => {
      let cost = calcVehicleTotalCost(vehicleData, totalDistance, false)
      if(cost){
        let variableCost = isNumeric(cost.variableCost) ? round(cost.variableCost, 2) : 0;
        let fixedCost = isNumeric(cost.fixedCost) ? round(cost.fixedCost, 2) : 0;

        totalVariableC = totalVariableC + variableCost;
        totalFixedC = totalFixedC + fixedCost;
      }
    });

    return {
      totalVariableCharge: round(totalVariableC, 2),
      totalFixedCharge: round(totalFixedC, 2),
    }
  }
};
export const getLoadItems = (form: any = null) => {
  let arr: any = [];

  if(form){
    if(form.routeTasks && form.routeTasks.length > 0){
      let item = form.routeTasks[0];
      
      if(item.items && item.items.length > 0){
        item.items.forEach((item: any, i: number) => {
          if(item.itemName != ''){
            arr.push({
              value: item.itemName,
              label: item.itemName,
            })
          }
        });
      }
    }

    return arr
  } else {
    return arr;
  }
}


interface VariableTrip {
  cost: number|null,
  type: number|null,
  sortOrder: number|null,
};
export const initVariableTrip: VariableTrip = {
  cost: null,
  type: null,
  sortOrder: 0,
};

interface FixTrip {
  cost: number|null,
  type: number|null,
  sortOrder: number|null,
};
export const initFixTrip: FixTrip = {
  cost: null,
  type: null,
  sortOrder: 0,
};

interface LoadItem {
  itemName: string|null,
  sortOrder: number|null,
};
export const initLoadItem: LoadItem = {
  itemName: '',
  sortOrder: 0,
};

interface RouteTask {
  midPointGeofenceId: number|null,
  routeTaskName: string|null,
  isStartPoint: boolean|null,
  isEndPoint: boolean|null,
  clientId: any,
  clientName: string|null,
  activity: number|null,
  items: Array<LoadItem>,
  sortOrder: number|null,
};
export const initRouteTask: RouteTask = {
  midPointGeofenceId: null,
  routeTaskName: '',
  isStartPoint: false,
  isEndPoint: false,
  clientId: null,
  clientName: '',
  activity: null,
  items: [initLoadItem],
  sortOrder: 0
};

interface AssignedVehicles {
  vehicleId: any,
  vehicleName: string|null,
  driverId: any,
  driverName: string|null,
  driverMobile: string|null,
  cargoType: string|null,
  cargoVolume: number|null,
  trailerId: string|null,
  clientId: any,
  clientName: string|null,
  variableTripCharges: Array<VariableTrip>,
  fixedTripCharges: Array<FixTrip>,
  sortOrder: number|null,
};
export const initAssignedVehicles: AssignedVehicles = {
  vehicleId: null,
  vehicleName: '',
  driverId: null,
  driverName: '',
  driverMobile: '',
  cargoType: '',
  cargoVolume: null,
  trailerId: '',
  clientId: null,
  clientName: '',
  variableTripCharges: [initVariableTrip],
  fixedTripCharges: [initFixTrip],
  sortOrder: 0,
};

export interface initialValuesStruct {
  jobDate: string
  routeId: any
  routeName: string
  routeTasks: Array<RouteTask>

  jobBookingTemplateId: any,
  templateName: string,
  
  jobBookingId: any,
  bookingDate: string,
  bookingBy: string,
  bookingNumber: string,
  vendorId: any,
  vendorName: string,
  transporterId: any,
  transporterName: string,
  vendorEmail: string,
  purchaseOrderNumber: string,
  salesPerson: string,
  bookingRemarks: string,

  clientId: any
  clientName: string
  transportItems: string,
  weight: number|null,
  cargoVolume: number|null,
  totalVariableCharge: number|null,
  totalFixedCharge: number|null,
  transportRemarks: string,
  
  assignedVehicles: Array<AssignedVehicles>,

  totalDistance: any,
};
export const initialValues: initialValuesStruct = {
  jobDate: '',
  routeId: null,
  routeName: '',
  routeTasks: [initRouteTask],

  jobBookingTemplateId: null,
  templateName: '',

  jobBookingId: null,
  bookingDate: '',
  bookingBy: '',
  bookingNumber: '',
  vendorId: null,
  vendorName: '',
  transporterId: null,
  transporterName: '',
  vendorEmail: '',
  purchaseOrderNumber: '',
  salesPerson: '',
  bookingRemarks: '',

  clientId: null,
  clientName: '',
  transportItems: '',
  weight: null,
  cargoVolume: null,
  totalVariableCharge: null,
  totalFixedCharge: null,
  transportRemarks: '',
  
  assignedVehicles: [initAssignedVehicles],

  totalDistance: null,
};

export type JobBooking = {
  jobBookingId: number
  bookingNumber: string
  bookingBy: string
  bookingDate: string

  vendorId: any
  vendorName: string

  purchaseOrderNumber: string
  salesPerson: string

  clientId: any
  clientName: string
  transportItems: string

  jobDate: string
  routeId: any
  routeName: string
}


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingRoute: boolean,
  detailsRoute: any,

  isLoadingVehicle: boolean,
}

function NewReducer() {
  const name = 'jobBookingSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingRoute: false,
    detailsRoute: null,

    isLoadingVehicle: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.jobs = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.jobs = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDelete: (state: InitState) => {
      state.isLoading = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startRouteDetails: (state: InitState) => {
      state.isLoadingRoute = true;
      state.detailsRoute = null;
    },
    finishRouteDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingRoute = false;
      state.detailsRoute = action.payload;
    },

    startVehicleDetails: (state: InitState) => {
      state.isLoadingVehicle = true;
    },
    finishVehicleDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingVehicle = false;
    },

    startCreateTemplate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreateTemplate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDetailsTemplate: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetailsTemplate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startVehiclesDetails: (state: InitState) => {
      state.isLoadingVehicle = true;
    },
    finishVehiclesDetails: (state: InitState) => {
      state.isLoadingVehicle = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('job-booking', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('job-booking/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('job-booking', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('job-booking', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('job-booking', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callRouteDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRouteDetails());
  
        await axios.get('route/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRouteDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRouteDetails(null));
        });
    },

    callVehicleDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startVehicleDetails());
  
        await axios.get('vehicle/id/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishVehicleDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishVehicleDetails(null));
        });
    },
    
    callCreateTemplateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreateTemplate());
  
        await axios.post('job-booking-template', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreateTemplate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreateTemplate(null));
        });
    },

    callDetailsTemplateApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetailsTemplate());
  
        await axios.get('job-booking-template/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetailsTemplate(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetailsTemplate(null));
        });
    },

    callVehiclesDetailsApi: (ids: Array<any>, total: any, callback: (state: boolean, id: any, data: any) => void, postCallback: (data: any, totalVariableCharge: any, totalFixedCharge: any) => void) => async (dispatch: any) => {
        dispatch(actions.startVehiclesDetails());
  
        const promises = ids.map((id: any) => {
          return axios.get('vehicle/id/' + id).then((result: any) => {
            let data = result.data;

            if (callback) {
              callback(true, id, data)
            }

            return data
          }).catch((error: any) => {
            if (callback) {
              callback(false, id, null)
            }

            return null
          });
      });

      Promise.all(promises).then(results => {
        if (typeof postCallback === 'function') {
          let totalVariableCharge = 0;
          let totalFixedCharge = 0;

          if(total){
            totalVariableCharge = total.totalVariableCharge;
            totalFixedCharge = total.totalFixedCharge;
          }

          postCallback(results, totalVariableCharge, totalFixedCharge);
        }

        dispatch(actions.finishVehiclesDetails());
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();