import PropTypes from 'prop-types';

import { 
    Container,
    Row,
    Col,
} from 'react-bootstrap';

// ----------------------------------------------------------------------

export default function ErrorLayout({ children }: any) {
  return <Container fluid className={'overflow-hidden'}>
    <Row className={'align-items-center justify-content-center text-center min-vh-100'}>
      <Col xs={12}>
        <Container>{children}</Container>
      </Col>
    </Row>
  </Container>
}

ErrorLayout.propTypes = {
  children: PropTypes.node,
};
