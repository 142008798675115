/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { PATH_AFTER_LOGIN } from '../utils/enums';

import AuthGuard from '../auth/auth-guard.js';
import LoadingScreen from '../components/loading/LoadingScreen.tsx';
import PrivateLayout from '../layouts/PrivateLayout.tsx';

// ----------------------------------------------------------------------

// MENU
const DesignSystemPage = lazy(() => import('../pages/designSystem/DesignSystem.tsx'));
const DashboardPage = lazy(() => import('../pages/dashboard/Dashboard.tsx'));
const VehiclesPage = lazy(() => import('../pages/vehicles/Page.tsx'));
const RoutesPage = lazy(() => import('../pages/routes/Page.tsx'));
const ClientsPage = lazy(() => import('../pages/clients/Page.tsx'));
const JobsPage = lazy(() => import('../pages/jobBooking/Page.tsx'));

// ----------------------------------------------------------------------

export const privateRoutes = [
  {
    path: '/',
    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
  },

  {
    path: 'design-system',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DesignSystemPage />, index: true },
      { path: 'design-system', element: <DesignSystemPage /> },
    ],
  },

  {
    path: 'vehicles',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <VehiclesPage />, index: true },
      { path: 'vehicles', element: <VehiclesPage /> },
    ],
  },

  {
    path: 'routes',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <RoutesPage />, index: true },
      { path: 'routes', element: <RoutesPage /> },
    ],
  },

  {
    path: 'jobs',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <JobsPage />, index: true },
      { path: 'jobs', element: <JobsPage /> },
    ],
  },

  {
    path: 'clients',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ClientsPage />, index: true },
      { path: 'clients', element: <ClientsPage /> },
    ],
  },

];
