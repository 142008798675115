import { SliceCaseReducers, ValidateSliceCaseReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';
import { errorAPI } from '../../../utils/utils';

import { type MRT_ColumnDef } from 'material-react-table';


export const getOptions = (slice: any = null, args: any = {}) => {
  let params = null;

  if(slice){
    const { currentPage, pageSize, sortColumn, sortDir, searchQuery, isIncludeInactive, isInactive, statusFilter, fromDate, toDate } = slice;

    let obj = {
      currentPage: currentPage,
      pageSize: pageSize,
      sortColumn: sortColumn,
      sortDir: sortDir,
      searchQuery: searchQuery,
      isIncludeInactive: isIncludeInactive,
      isInactive: isInactive,
      statusFilter: statusFilter,
      fromDate: fromDate,
      toDate: toDate,
    }

    params = (args) ? Object.assign({}, obj, args) : obj;
  }

  return params;
}

export interface InitState<T> {
  tableIsLoading: boolean,
  tableTotal: number,
  tableData: T[],
  tableColumns: MRT_ColumnDef<any>[],

  currentPage:number|null,
  pageSize:number|null,
  sortColumn:string|null,
  sortDir:string|null,
  searchQuery:string|null,
  isIncludeInactive: boolean|null,
  isInactive: boolean|null,

  statusFilter: any,
  fromDate: any,
  toDate: any,
}


const CURRENT_PAGE = 1;
const PAGE_SIZE = 10;
const SORT_COLUMN = 'created';
const SORT_DIR = 'desc';
const SEARCH_QUERY = null;
const IS_INCLUDE_INACTIVE = true;
const IS_INACTIVE = null;

const createGenericSlice = <T,>(sliceName: string) => {
  const initialState: InitState<T> = {
    tableIsLoading: false,
    tableTotal: 0,
    tableData: [],
    tableColumns: [],

    currentPage: CURRENT_PAGE,
    pageSize: PAGE_SIZE,
    sortColumn: SORT_COLUMN,
    sortDir: SORT_DIR,
    searchQuery: SEARCH_QUERY,
    isIncludeInactive: IS_INCLUDE_INACTIVE,
    isInactive: IS_INACTIVE,
    statusFilter: null,
    fromDate: null,
    toDate: null,
  };


  const reducers: ValidateSliceCaseReducers<InitState<T>, SliceCaseReducers<InitState<T>>> = {
    setColumns: (state, action) => {
      state.tableColumns = action.payload;
    },
    setData: (state, action) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      state.tableData = data;
      state.tableTotal = total;
    },

    setOptions: (state, action) => {
      let options = action.payload;
      if(options){
        state.currentPage = (options.currentPage != undefined) ? options.currentPage : state.currentPage;
        state.pageSize = (options.pageSize != undefined) ? options.pageSize : state.pageSize;
        state.sortColumn = (options.sortColumn != undefined) ? options.sortColumn : state.sortColumn;
        state.sortDir = (options.sortDir != undefined) ? options.sortDir : state.sortDir;
        state.searchQuery = (options.searchQuery != undefined) ? options.searchQuery : state.searchQuery;
        state.isIncludeInactive = (options.isIncludeInactive != undefined) ? options.isIncludeInactive : state.isIncludeInactive;
        state.isInactive = (options.isInactive != undefined) ? options.isInactive : state.isInactive;
        state.statusFilter = (options.statusFilter != undefined) ? options.statusFilter : state.statusFilter;
        state.fromDate = (options.fromDate != undefined) ? options.fromDate : state.fromDate;
        state.toDate = (options.toDate != undefined) ? options.toDate : state.toDate;
      }
    },

    startRead: (state, action) => {
      state.tableIsLoading = true;
    },
    finishRead: (state, action) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      state.tableData = data;
      state.tableTotal = total;

      state.tableIsLoading = false;
    },
    
    startDownload: (state, action) => {
      state.tableIsLoading = true;
    },
    finishDownload: (state, action) => {
      state.tableIsLoading = false;
    },
  };


  const { reducer, actions } = createSlice({
    name: sliceName,
    initialState,
    reducers
  })
  

  const apis = {
    callSetColumns: (columns: MRT_ColumnDef<any>[]) => async (dispatch: any) => {
      dispatch(actions.setColumns(columns));
    },

    callSetData: (data: any) => async (dispatch: any) => {
      dispatch(actions.setData(data));
    },

    callSetOptions: (options: any) => async (dispatch: any) => {
      dispatch(actions.setOptions(options));
    },

    callReadApi: (path: string, args: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      dispatch(actions.startRead(null));

      let slice = getState()[sliceName];
      let params = getOptions(slice, args);
      
      try {
        const result = await axios.get(path, { params: params });
        let data = result.data.data;
        let total = result.data.total;

        callback(true, data);
        dispatch(actions.finishRead({ data, total }));
      } catch (error) {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishRead({ data: [], total: 0 }));
      }
    },

    callDownloadApi: (path: string, args: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDownload(null));

      try {
        const result = await axios.get(path, { params: args });
        let data = result.data;

        callback(true, data);
        dispatch(actions.finishDownload({ data }));
      } catch (error) {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishDownload({ data: null }));
      }
    },
  };


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default createGenericSlice;