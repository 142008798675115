import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import GuestGuard from '../auth/guest-guard.js';
import PublicLayout from '../layouts/PublicLayout.tsx';
import LoadingScreen from '../components/loading/LoadingScreen.tsx';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('../pages/auth/Login.tsx'));
const LoginToken = lazy(() => import('../pages/auth/LoginToken.tsx'));
const RegisterPage = lazy(() => import('../pages/auth/Register.tsx'));
const ForgotPasswordPage = lazy(() => import('../pages/auth/ForgotPassword.tsx'));

// ----------------------------------------------------------------------

const auth = {
  path: '/auth',
  element: (
    <GuestGuard>
      <PublicLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </PublicLayout>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'login-token',
      element: <LoginToken />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
  ],
};

export const authRoutes = [
  {
    path: '/',
    children: [auth],
  },
];
