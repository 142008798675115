import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitState {
  isLoading: boolean,
  show: boolean,
  title: string,
  body: string,
  cancelText: string,
  cancelVariant: string,
  confirmText: string,
  confirmVariant: string,
}


function NewReducer() {
  const name = 'confirmSlice';

  
  let callback: (() => void) | null = null;


  const initialState: InitState = {
    isLoading: false,
    show: false,
    title: 'Remove',
    body: 'Are you sure?',
    cancelText: 'Cancel',
    cancelVariant: 'custom-outline',
    confirmText: 'Confirm',
    confirmVariant: 'custom-danger',
  };


  const reducers = {
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ title: string, body: string, cancelText: string, cancelVariant: string, confirmText: string, confirmVariant: string }>) => {
        state.isLoading = false;
        state.title = action.payload.title;
        state.body = action.payload.body;
        state.cancelText = action.payload.cancelText;
        state.cancelVariant = action.payload.cancelVariant;
        state.confirmText = action.payload.confirmText;
        state.confirmVariant = action.payload.confirmVariant;
        state.show = true;
    },
    setHide: (state: InitState) => {
        callback = null;
        state.isLoading = false;
        state.show = false;
    },
  };


  const apis = {
    show: (callbackFunc: () => void, options: any = null) => async (dispatch: any) => {
        callback = callbackFunc || null;
        let opt = options ? options : {
            title: initialState.title,
            body: initialState.body,
            cancelText: initialState.cancelText,
            cancelVariant: initialState.cancelVariant,
            confirmText: initialState.confirmText,
            confirmVariant: initialState.confirmVariant,
        }
        dispatch(actions.setShow(opt));
    },
    confirm: () => async () => {
        if(callback){
            callback();
        }
    }
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();