import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import axios from '../../utils/axiosAuth';
import { successAPI, errorAPI } from '../../utils/utils';


interface InitState {
  isLoading: boolean,
  user: any,
}

function NewReducer() {
  const name = 'loginSlice';


  const initialState: InitState = {
    isLoading: false,
    user: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    
    logout: (state: InitState) => {
      state.isLoading = false;
      state.user = null;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.user = action.payload;
    },

    startLogin: (state: InitState) => {
      state.isLoading = true;
    },
    finishLogin: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.user = action.payload;
    },

    startRegister: (state: InitState) => {
      state.isLoading = true;
    },
    finishRegister: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callDetailsApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await axios.get('account/authenticate-token').then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },

    callLogoutApi: () => async (dispatch: any) => {
      dispatch(actions.logout());
    },

    callLoginApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startLogin());

      await axios.post('account/login', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishLogin(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishLogin(null));
      });
    },
    
    callRegisterApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRegister());

      await axios.post('account/register', params).then(result => {
        let data = result.data;

        successAPI(data);

        callback(true, data);
        dispatch(actions.finishRegister(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishRegister(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();