import React from 'react';

import {
  Button,
} from 'react-bootstrap';

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    className={'d-inline-block'}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export const CustomToggleBtn = React.forwardRef(({ children, onClick, variant }, ref) => (
  <Button
    className={'d-inline-block'}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    variant={variant}
  >
    {children}
  </Button>
));

export const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled mb-0">
          {React.Children.toArray(children).filter(
            (child) => child.props.children,
          )}
        </ul>
      </div>
    );
  },
);