import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../../utils/axios';
import { successAPI, errorAPI } from '../../../utils/utils';
import { GPSServer } from '../../../utils/enums';
import Yup from '../../../utils/yup';


export const fields = {
  gpsServerType: {
    id: 'gpsServerType',
    label: 'GPS server',
    placeholder: 'Choose GPS server',
  },
  gpsGateUrl: {
    id: 'gpsGateUrl',
    label: 'Endpoint URL',
    placeholder: 'Enter enpoint url',
  },
  gpsGateAuthToken: {
    id: 'gpsGateAuthToken',
    label: 'Token ID',
    placeholder: 'Enter token id',
  },
  gpsGateAppId: {
    id: 'gpsGateAppId',
    label: 'App ID',
    placeholder: 'Enter application id',
  },
  gpsGateVehicleViewId: {
    id: 'gpsGateVehicleViewId',
    label: 'Vehicle list',
    placeholder: 'Choose vehicle',
  },
  gpsGateGeofenceViewId: {
    id: 'gpsGateGeofenceViewId',
    label: 'Geofence list',
    placeholder: 'Choose geofence',
  },
};

export const formSchema = (isGpsGateConnected: boolean) => {
  return Yup.object().shape({
    gpsServerType: Yup.number().nullable().min(0).required().label(fields.gpsServerType.label),
    gpsGateUrl: Yup.string().nullable().url().required().label(fields.gpsGateUrl.label),
    gpsGateAuthToken: Yup.string().nullable().required().label(fields.gpsGateAuthToken.label),
    gpsGateAppId: Yup.string().nullable().required().label(fields.gpsGateAppId.label),
    gpsGateVehicleViewId: Yup.number().nullable().label(fields.gpsGateVehicleViewId.label),
    gpsGateGeofenceViewId: Yup.number().nullable().label(fields.gpsGateGeofenceViewId.label),
  })
}

export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    const formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export interface initialValuesStruct {
  gpsServerType: any,
  gpsGateUrl: any,
  gpsGateAuthToken: any,
  gpsGateAppId: any,
  gpsGateVehicleViewId: any,
  gpsGateVehicleViewName: string,
  gpsGateGeofenceViewId: any,
  gpsGateGeofenceViewName: string,
};
export const initialValues: initialValuesStruct = {
  gpsServerType: GPSServer.GPSGate,
  gpsGateUrl: '',
  gpsGateAuthToken: '',
  gpsGateAppId: '',
  gpsGateVehicleViewId: null,
  gpsGateVehicleViewName: '',
  gpsGateGeofenceViewId: null,
  gpsGateGeofenceViewName: '',
};


interface InitState {
  isLoading: boolean,
  show: boolean,

  isLoadingDisconnect: boolean,
  showDisconnect: boolean,
}


function NewReducer() {
  const name = 'connectSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,

    isLoadingDisconnect: false,
    showDisconnect: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = false;
      state.show = action.payload;
    },
    
    setLoadingDisconnect: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingDisconnect = action.payload;
    },
    setShowDisconnect: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingDisconnect = false;
      state.showDisconnect = action.payload;
    },


    startConnect: (state: InitState) => {
      state.isLoading = true;
    },
    finishConnect: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDisconnect: (state: InitState) => {
      state.isLoadingDisconnect = true;
    },
    finishDisconnect: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDisconnect = false;
    },
  };


  const apis = {
    callConnectApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startConnect());

      await axios.post('account/connect-gps-gate', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishConnect(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishConnect(null));
      });
    },
    callDisconnectApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDisconnect());

      await axios.post('account/disconnect-gps-gate', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDisconnect(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDisconnect(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();