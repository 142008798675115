import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import liveSlice from './liveSlice';

import confirmSlice from '../components/modal/confirmSlice';
import downloadSlice from '../components/download/downloadSlice';
import createGenericSlice from '../components/table/withSlice/tableSlice';

import loginSlice from '../pages/auth/slice';
import vehiclesSlice from '../pages/vehicles/slice';
import connectSlice from '../pages/vehicles/connect/connectSlice';
import routesSlice from '../pages/routes/slice';
import clientsSlice, { Client } from '../pages/clients/slice';
import jobBookingSlice, { JobBooking } from '../pages/jobBooking/slice';
import jobsSlice, { Jobs } from '../pages/jobs/slice';
import mapRouteSlice from '../pages/jobs/mapRoute/mapRouteSlice';
import jobDetailsSlice from '../pages/jobs/jobDetails/jobDetailsSlice';


export const Tables = {
  Client: 'tableClient',
  JobBooking: 'tableJobBooking',
  Jobs: 'tableJobs',
  JobsCompleted: 'tableJobsCompleted',
}


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


const store = configureStore({
  reducer: {
    liveSlice: liveSlice.reducer,

    confirmSlice: confirmSlice.reducer,
    downloadSlice: downloadSlice.reducer,

    tableClient: createGenericSlice<Client>(Tables.Client).reducer,
    tableJobBooking: createGenericSlice<JobBooking>(Tables.JobBooking).reducer,
    tableJobs: createGenericSlice<Jobs>(Tables.Jobs).reducer,
    tableJobsCompleted: createGenericSlice<Jobs>(Tables.JobsCompleted).reducer,
    
    loginSlice: loginSlice.reducer,
    vehiclesSlice: vehiclesSlice.reducer,
    connectSlice: connectSlice.reducer,
    routesSlice: routesSlice.reducer,
    clientsSlice: clientsSlice.reducer,
    jobBookingSlice: jobBookingSlice.reducer,
    jobsSlice: jobsSlice.reducer,
    mapRouteSlice: mapRouteSlice.reducer,
    jobDetailsSlice: jobDetailsSlice.reducer,
  },
});

export { store };

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
