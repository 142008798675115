import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import ErrorLayout from '../layouts/ErrorLayout.tsx';
import LoadingScreen from '../components/loading/LoadingScreen.tsx';

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('../pages/error/500.tsx'));
const Page403 = lazy(() => import('../pages/error/403.tsx'));
const Page404 = lazy(() => import('../pages/error/404.tsx'));
const MaintenancePage = lazy(() => import('../pages/error/Maintenance.tsx'));

// ----------------------------------------------------------------------

export const errorRoutes = [
  {
    element: (
      <ErrorLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </ErrorLayout>
    ),
    children: [
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
