import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';

import {
  Row,
  Col,
} from 'react-bootstrap';

import axios from '../../../utils/axios';
import { successAPI, errorAPI, convertCoordToLatLng, convertLatLngToCoord, validateLatLng, isNumeric, viewDateTime } from '../../../utils/utils';
import { MidPointType } from '../../../utils/enums';

import { addViewRoute, addRouteMarker, routeMarkerSVGIcon, addCircle, clearAllCircles, addPolygon, clearAllPolygons, clearRoute } from '../../../components/googleMap/GoogleMap';


let currentInfoWindow: any = null;
export const getCurrentInfoWindows = () => {
  return currentInfoWindow;
}
export const setCurrentInfoWindows = (item: any) => {
  currentInfoWindow = item;
}

const InfoWindowContent = ({ routeLabel, routeTaskName, clientLabel, clientName, arrivalTimestamp, departureTimestamp, duration, items }: any) => {
  return <div style={{ overflow: 'hidden', maxWidth: '300px' }}>
    <Row className='g-8'>
      <Col xs={12}><span className='semibold'>{routeLabel}:</span> {routeTaskName}</Col>
      <Col xs={12}><span className='semibold'>{clientLabel}:</span> {clientName}</Col>
      <Col xs={12}><span className='semibold'>Arrival time:</span> {arrivalTimestamp}</Col>
      <Col xs={12}><span className='semibold'>Departure time:</span> {departureTimestamp}</Col>
      <Col xs={12}><span className='semibold'>Duration:</span> {duration}</Col>
      <Col xs={12}><span className='semibold'>Items:</span> {items.map((x: any) => x.itemName).join(', ')}</Col>
    </Row>
  </div>
}


export const showRoute = (details: any = null, values: any = null, preserveViewport: boolean = true, onFinishCallback: any = null) => {
  setTimeout(() => {
    if(values){
      let startPointPolygonCoordinates = convertCoordToLatLng(values.startPointPolygonCoordinates);
      let startPointCoordinates = convertCoordToLatLng(values.startPointCoordinates);
      let startPoint = values.startPointHasCoordinate ? startPointCoordinates : startPointPolygonCoordinates;
      
      let endPointPolygonCoordinates = convertCoordToLatLng(values.endPointPolygonCoordinates);
      let endPointCoordinates = convertCoordToLatLng(values.endPointCoordinates);
      let endPoint = values.endPointHasCoordinate ? endPointCoordinates : endPointPolygonCoordinates;
      
      let midPoints: Array<any> = [];
      if(startPoint && endPoint){
        if(values.routeMidPoints && values.routeMidPoints.length > 0){
          values.routeMidPoints.forEach((item: any, i: number) => {
            let midPointPolygonCoordinates = convertCoordToLatLng(item.midPointPolygonCoordinates);
            let midPointCoordinates = convertCoordToLatLng(item.midPointCoordinates);
            let midPoint = item.midPointHasCoordinate ? midPointCoordinates : midPointPolygonCoordinates;
            if(midPoint){
              midPoints.push({
                location: midPoint,
                stopover: (item.type === MidPointType.Track) ? false : true,
              })
            } else {
              if(values.routeMidPoints.length > 1){
                midPoints.push({
                  location: null,
                  stopover: (item.type === MidPointType.Track) ? false : true,
                })
              }
            }
          });
        }
    
        addViewRoute(preserveViewport, startPoint, endPoint, midPoints, values.routeColor, values.routeRadius,
          // Get prev directions
          () => {
            return {
              oldDirections: values.oldDirections,
            }
          },

          // Set total and directions
          (total: any, directions: any) => {
            // formikContext.setFieldValue('totalDistance', total);
            // formikContext.setFieldValue('oldDirections', directions);
          },

          // Change coordinates in form
          (requestRoute: any, responseRoute: any) => {
            clearAllCircles();
            clearAllPolygons();
        
            let routeMidPoints: Array<any> = [];
            if(requestRoute && requestRoute.midPoints && requestRoute.midPoints.length > 0){
              let routeMidPointsPrev = _.cloneDeep(values.routeMidPoints);

              for(let i = 0; i < requestRoute.midPoints.length; i++) {
                let item = requestRoute.midPoints[i];

                let trackPointLatitude = item.position.lat;
                let trackPointLongitude = item.position.lng;

                let trackPointHasCoordinate = validateLatLng(trackPointLatitude, trackPointLongitude) ? true : false;
                let trackPointCoordinates = convertLatLngToCoord(trackPointLatitude, trackPointLongitude);
                
                let prevItem: any = routeMidPointsPrev.find((x: any) => {
                  if(x.midPointHasCoordinate){
                    let coordObj = convertCoordToLatLng(x.midPointCoordinates);
                    return coordObj ? (coordObj.lat == trackPointLatitude) && (coordObj.lng == trackPointLongitude) : false
                  } else {
                    let coordObj = convertCoordToLatLng(x.midPointPolygonCoordinates);
                    return coordObj ? (coordObj.lat == trackPointLatitude) && (coordObj.lng == trackPointLongitude) : false
                  }
                });
                
                if(prevItem){
                  routeMidPoints.push(prevItem);
                } else {
                  let trackColor = item.stopover ? '#505363' : '#ffffff';
                  let trackName = item.stopover ? '' : '';

                  let newItem: any = {
                    midPointColor: trackColor,
                    midPointName: trackName,
                    midPointPolygonLatitude: trackPointLatitude,
                    midPointPolygonLongitude: trackPointLongitude,
                    midPointLatitude: trackPointLatitude,
                    midPointLongitude: trackPointLongitude,
                    midPointHasCoordinate: trackPointHasCoordinate,
                    midPointCoordinates: trackPointCoordinates,
                    sortOrder: item.sortOrder,
                    type: item.stopover ? MidPointType.MidPoint : MidPointType.Track,
                    midPointGeofenceId: null,
                    midPointGeofenceName: trackName,
                    midPointRadius: null
                  };
                  routeMidPoints.push(newItem);
                }
              }
            }
            
            // Add radius circle
            // Start Point radius
            if(requestRoute && requestRoute.startPoint && requestRoute.startPoint.position){
              if(values.startPointHasCoordinate){
                addPolygon(null, {
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  fillOpacity: 0,
                });

                let circle: any = addCircle({
                  center: requestRoute.startPoint.position,
                  radius: (values.startPointRadius && values.startPointRadius != '') ? parseInt(values.startPointRadius) : 0,
                  fillColor: values.startPointColor,
                  fillOpacity: 0.25,
                  strokeColor: values.startPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 1,
                });
              } else {
                addPolygon(values.startPointPolygon, {
                  strokeColor: values.startPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  fillColor: values.startPointColor,
                  fillOpacity: 0.25,
                });
                
                addCircle({
                  radius: 0,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                })
              }
            }

            // Mid Points radius
            if(routeMidPoints && routeMidPoints.length > 0){
              for(let i = 0; i < routeMidPoints.length; i++) {
                let item = routeMidPoints[i];
                
                if(item.type === MidPointType.MidPoint){
                  if(item.midPointHasCoordinate){
                    let coordObj = (item.midPointHasCoordinate) ? convertCoordToLatLng(item.midPointCoordinates) : convertCoordToLatLng(item.midPointPolygonCoordinates);
                    
                    addPolygon(null, {
                      strokeOpacity: 0,
                      strokeWeight: 0,
                      fillOpacity: 0,
                    });

                    let circle: any = addCircle({
                      center: { lat: coordObj?.lat, lng: coordObj?.lng },
                      radius: (item.midPointRadius && item.midPointRadius != '') ? parseInt(item.midPointRadius) : 0,
                      fillColor: item.midPointColor,
                      fillOpacity: 0.25,
                      strokeColor: item.midPointColor,
                      strokeOpacity: 1.0,
                      strokeWeight: 1,
                    });
                  } else {
                    addPolygon(item.midPointPolygon, {
                      strokeColor: item.midPointColor,
                      strokeOpacity: 1.0,
                      strokeWeight: 2,
                      fillColor: item.midPointColor,
                      fillOpacity: 0.25,
                    });

                    addCircle({
                      radius: 0,
                      fillOpacity: 0,
                      strokeOpacity: 0,
                    })
                  }
                } else {
                  addPolygon(null, {
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    fillOpacity: 0,
                  });

                  addCircle({
                    radius: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0,
                  })
                }
              }
            }

            // End Point radius
            if(requestRoute && requestRoute.endPoint && requestRoute.endPoint.position){
              if(values.endPointHasCoordinate){
                addPolygon(null, {
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  fillOpacity: 0,
                });
                
                let circle: any = addCircle({
                  center: requestRoute.endPoint.position,
                  radius: (values.endPointRadius && values.endPointRadius != '') ? parseInt(values.endPointRadius) : 0,
                  fillColor: values.endPointColor,
                  fillOpacity: 0.25,
                  strokeColor: values.endPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 1,
                });
              } else {
                addPolygon(values.endPointPolygon, {
                  strokeColor: values.endPointColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  fillColor: values.endPointColor,
                  fillOpacity: 0.25,
                });

                addCircle({
                  radius: 0,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                })
              }
            }
            

            // Return colors
            let midPointColors: Array<any> = [];
            if(routeMidPoints && routeMidPoints.length > 0){
              routeMidPoints.forEach((item: any, i: number) => {
                let midPointPolygonCoordinates = convertCoordToLatLng(item.midPointPolygonCoordinates);
                let midPointCoordinates = convertCoordToLatLng(item.midPointCoordinates);
                let midPoint = midPointCoordinates ? midPointCoordinates : midPointPolygonCoordinates;
                if(midPoint){
                  midPointColors.push(item.midPointColor ? item.midPointColor : '#505363')
                }
              });
            }

            return {
              startPointColor: values.startPointColor ? values.startPointColor : '#CB3A31',
              midPointColors: midPointColors,
              endPointColor: values.endPointColor ? values.endPointColor : '#185CFF',
            }
          },

          //Show Marker on map
          (obj: any) => {
            let routeTasks: any = (details && details.routeTasks && details.routeTasks.length > 0) ? details.routeTasks : [];

            let routeTaskName: string = '';
            let isStartPoint: boolean = false;
            let isEndPoint: boolean = false;
            let routeLabel: string = '';
            let clientLabel: string = '';

            let clientName: string = '';
            let arrivalTimestamp: any = '';
            let departureTimestamp: any = '';
            let duration: any = '';
            let items: any = [];

            if(routeTasks && routeTasks.length > 0 && routeTasks[obj.count]){
              let item = routeTasks[obj.count];

              routeTaskName = (item && item.routeTaskName != '') ? item.routeTaskName : '';
              isStartPoint = (item && ((item.isStartPoint === false) || (item.isStartPoint === true))) ? item.isStartPoint : false;
              isEndPoint = (item && ((item.isEndPoint === false) || (item.isEndPoint === true))) ? item.isEndPoint : false;

              if(isStartPoint){
                routeLabel = 'Loading point';
                clientLabel = 'Consignor';
              } else {
                routeLabel = 'Unloading point';
                clientLabel = 'Consignee';
              }

              let client = (item && item.client) ? item.client : null;
              clientName = (client && client.clientName != '') ? client.clientName: '';

              arrivalTimestamp = (item && item.arrivalTimestamp) ? viewDateTime(item.arrivalTimestamp) : null;
              departureTimestamp = (item && item.departureTimestamp) ? viewDateTime(item.departureTimestamp) : null;
              duration = (item && item.duration) ? item.duration : null;
              items = (item && item.items && item.items.length > 0) ? item.items : [];  
            }

            let marker: any = addRouteMarker({
              map: obj.map,
              position: obj.position,
              draggable: obj.draggable,
              icon: {
                url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon(obj.color, obj.cls),
                scaledSize: new google.maps.Size(26, 32),
                labelOrigin: new google.maps.Point(13, 41),
              },
              zIndex: 999,
              label: {
                text: routeTaskName,
                className: 'marker-label bg-secondary',
              },
            }, obj.count, obj.requestPosition, obj.responsePosition, obj.type, obj.callback);

            try {
              marker.addListener('click', () => {
                if (currentInfoWindow) {
                  currentInfoWindow.close();
                }
  
                const htmlContent = ReactDOMServer.renderToString(<InfoWindowContent 
                  routeLabel={routeLabel}
                  routeTaskName={routeTaskName}
                  clientLabel={clientLabel}
                  clientName={clientName}
                  arrivalTimestamp={arrivalTimestamp}
                  departureTimestamp={departureTimestamp}
                  duration={duration}
                  items={items}
                />);
                const infoWindow = new window.google.maps.InfoWindow({ content: htmlContent });
                infoWindow.open(obj.map, marker);
                currentInfoWindow = infoWindow;
              });
            }catch(e){}
          },

          //On Move marker in polygon
          (obj: any) => {},

          onFinishCallback
        )
      } else {
        clearRoute();
      }
    }
  });
}


interface InitState {
  id: number|null,
  show: boolean,

  isLoading: boolean,
  details: any,
  
  isLoadingVehicle: boolean,
  detailsVehicle: any,
}


function NewReducer() {
  const name = 'mapRouteSlice';


  const initialState: InitState = {
    id: null,
    show: false,

    isLoading: false,
    details: null,

    isLoadingVehicle: false,
    detailsVehicle: null,
  };


  const reducers = {
    setShow: (state: InitState, action: PayloadAction<number|null>) => {
      state.isLoading = false;
      state.details = null;

      state.isLoadingVehicle = false;
      state.detailsVehicle = null;

      let id = action.payload;
      if(isNumeric(id)){
        state.id = id;
        state.show = true;
      } else {
        state.id = null;
        state.show = false;
      }
    },


    startDetails: (state: InitState) => {
      state.isLoading = true;
      state.details = null;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startDetailsVehicle: (state: InitState) => {
      state.isLoadingVehicle = true;
      state.detailsVehicle = null;
    },
    finishDetailsVehicle: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingVehicle = false;
      state.detailsVehicle = action.payload;
    },
  };


  const apis = {
    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await axios.get('job/' + id).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },
    
    callDetailsVehicleApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsVehicle());

      await axios.get('vehicle/' + id).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetailsVehicle(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetailsVehicle(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();