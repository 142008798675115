/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, convertCoordToLatLng, convertLatLngToCoord, getTimeZoneFromSettings, dateTimeFormat, validateLatLng } from '../../utils/utils';
import Yup from '../../utils/yup';
import { CostType } from 'src/utils/enums';

import { DEFAULT_CENTER } from '../../components/googleMap/GoogleMap';


export const fields = {
  gpsGateVehicleId: {
    id: 'gpsGateVehicleId',
    label: 'Vehicle ID',
    placeholder: 'Choose vehicle',
  },
  trailerIdName: {
    id: 'trailerIdName',
    label: 'Trailer ID',
    placeholder: 'Enter trailer ID',
  },

  gpsGateGeofenceId: {
    id: 'gpsGateGeofenceId',
    label: 'Yard',
    placeholder: 'Select geofence',
  },
  yardCoordinates: {
    id: 'yardCoordinates',
    label: 'Coordinates',
    placeholder: 'Coordinates',
  },
  yardRadius: {
    id: 'yardRadius',
    label: 'Radius',
    placeholder: 'Radius',
  },
  yardName: {
    id: 'yardName',
    label: 'Yard',
    placeholder: 'Enter yard name',
  },

  odometer: {
    id: 'odometer',
    label: 'Odometer',
    placeholder: 'Odometer',
  },
  currentPosition: {
    id: 'currentPosition',
    label: 'Current position',
    placeholder: 'Current position',
  },
  utc: {
    id: 'utc',
    label: 'Last update',
    placeholder: 'Last update',
  },

  vehicleGroupId: {
    id: 'vehicleGroupId',
    label: 'Group',
    placeholder: 'Choose group',
  },
  vehicleTransporterClientId: {
    id: 'vehicleTransporterClientId',
    label: 'Transporter',
    placeholder: 'Choose transporter',
  },

  fuelCost: {
    id: 'fuelCost',
    label: 'Fuel cost',
    placeholder: 'Enter fuel cost',
  },
  tyreCost: {
    id: 'tyreCost',
    label: 'Tyre cost',
    placeholder: 'Enter tyre cost',
  },
  
  otherCost: {
    id: 'otherCost',
    label: 'Other cost',
    placeholder: 'Enter other cost',
  },
  fixedCost: {
    id: 'fixedCost',
    label: 'Fixed cost',
    placeholder: 'Enter fixed cost',
  },

  driverId: {
    id: 'driverId',
    label: 'Name',
    placeholder: 'Choose driver name',
  },
  phoneNumber: {
    id: 'phoneNumber',
    label: 'Phone number',
    placeholder: 'Enter phone number',
  },
};

export const formSchema = (id: number|null= null, isGpsGateConnected: boolean) => {
  if(!isGpsGateConnected){
    return Yup.object();
  }

  return Yup.object().shape({
    gpsGateVehicleId: Yup.number().nullable().required().label(fields.gpsGateVehicleId.label),
    trailerIdName: Yup.string().nullable().label(fields.trailerIdName.label),

    yardCoordinates: Yup.string().nullable().coordinates().label(fields.yardCoordinates.label),
    yardRadius: Yup.number().nullable().min(0).max(1000).label(fields.yardRadius.label),

    yardHasCoordinate: Yup.bool().oneOf([true, false]),
    gpsGateGeofenceId: Yup.string().when(['yardHasCoordinate'], ([yardHasCoordinate]) => {
      if(!yardHasCoordinate){
        return Yup.number().nullable().required().label(fields.gpsGateGeofenceId.label);
      } else {
        return Yup.number().nullable().label(fields.gpsGateGeofenceId.label);
      }
    }),
    yardName: Yup.string().when(['yardHasCoordinate'], ([yardHasCoordinate]) => {
      if(yardHasCoordinate){
        return Yup.string().nullable().required().label(fields.yardName.label)
      } else {
        return Yup.string().nullable().label(fields.yardName.label)
      }
    }),


    odometer: Yup.number().nullable().label(fields.odometer.label),
    currentPosition: Yup.string().nullable().label(fields.currentPosition.label),
    utc: Yup.string().nullable().label(fields.utc.label),

    vehicleGroupId: Yup.number().nullable().label(fields.vehicleGroupId.label),
    vehicleTransporterClientId: Yup.number().nullable().label(fields.vehicleTransporterClientId.label),

    fuelCost: Yup.number().nullable().min(0).label(fields.fuelCost.label),
    tyreCost: Yup.number().nullable().min(0).label(fields.tyreCost.label),

    otherCost: Yup.array().of(Yup.object({
      cost: Yup.number().nullable().min(0).label(fields.otherCost.label),
      type: Yup.number().nullable().min(0),
    }))
    .hasEmpty('cost'),

    fixedCost: Yup.array().of(Yup.object({
      cost: Yup.number().nullable().min(0).label(fields.fixedCost.label),
      type: Yup.number().nullable().min(0),
    }))
    .hasEmpty('cost'),

    driverId: Yup.number().nullable().label(fields.driverId.label),
    phoneNumber: Yup.string().nullable().label(fields.phoneNumber.label),
  })
}


export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    const formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const cloneForm = (values: any = null) => {
  let form = _.cloneDeep(values);
  
  if(form){
    form['vehicleId'] = null;

    form['gpsGateVehicleId'] = null;
    form['gpsGateVehicleName'] = '';

    form['odometer'] = null;
    form['currentPosition'] = '';
    form['utc'] = '';

    return form;
  } else {
    return values;
  }
}
export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let vehicleId = (form.vehicleId && form.vehicleId !== '') ? form.vehicleId : null;

    let gpsGateVehicle = (form.gpsGateVehicle) ? form.gpsGateVehicle : null;
    let gpsGateVehicleId = (gpsGateVehicle && gpsGateVehicle.id && gpsGateVehicle.id !== '') ? gpsGateVehicle.id : null;
    let gpsGateVehicleName = (gpsGateVehicle && gpsGateVehicle.name && gpsGateVehicle.name !== '') ? gpsGateVehicle.name : '';

    let trailerIdName = (form.trailerIdName && form.trailerIdName !== '') ? form.trailerIdName : '';

    let gpsGateGeofenceId = (form.gpsGateGeofenceId && form.gpsGateGeofenceId !== '') ? form.gpsGateGeofenceId : null;
    let gpsGateGeofenceName = (form.gpsGateGeofenceName && form.gpsGateGeofenceName !== '') ? form.gpsGateGeofenceName : '';
    
    let yardLatitude = (form.yardLatitude && form.yardLatitude !== '') ? form.yardLatitude : null;
    let yardLongitude = (form.yardLongitude && form.yardLongitude !== '') ? form.yardLongitude : null;
    let yardHasCoordinate = validateLatLng(yardLatitude, yardLongitude) ? true : false;

    let yardGeofence: Array<any> = [];
    if(form && form.yardGeofence && form.yardGeofence.polygonShape && form.yardGeofence.polygonShape.vertices && form.yardGeofence.polygonShape.vertices.length > 0){
      yardGeofence = form.yardGeofence.polygonShape.vertices.map((item: any) => {
        return { lat: item.latitude, lng: item.longitude }
      });
    }

    let yardCoordinates = convertLatLngToCoord(yardLatitude, yardLongitude);
    let yardRadius = (form.yardRadius && form.yardRadius !== '') ? form.yardRadius : null;
    let yardName = (form.yardName && form.yardName !== '') ? form.yardName : '';

    let trackPoint = (gpsGateVehicle && gpsGateVehicle.trackPoint) ? gpsGateVehicle.trackPoint : null;
    let position = (trackPoint && trackPoint.position) ? trackPoint.position : null;

    let odometer = (gpsGateVehicle) ? gpsGateVehicle.odometer : null;
    let utc = (trackPoint) ? trackPoint.utc : '';
    let currentPosition = (position) ? convertLatLngToCoord(position.latitude, position.longitude) : '';

    let transporterClient = (form.transporterClient) ? form.transporterClient : null;
    let vehicleTransporterClientId = (transporterClient && transporterClient.clientId && transporterClient.clientId !== '') ? transporterClient.clientId : null;
    let vehicleTransporterClientName = (transporterClient && transporterClient.clientName && transporterClient.clientName !== '') ? transporterClient.clientName : '';

    let fuelCost = (form.fuelCost && form.fuelCost !== '') ? form.fuelCost : null;
    let tyreCost = (form.tyreCost && form.tyreCost !== '') ? form.tyreCost : null;

    let vehicleCosts = (form.vehicleCosts && form.vehicleCosts.length > 0) ? form.vehicleCosts.sort((a: any, b: any) => a.sortOrder - b.sortOrder) : [];
    let otherCost = (vehicleCosts && vehicleCosts.length > 0) ? vehicleCosts.filter((x: any) => x.type === CostType.OtherCost).sort((a: any, b: any) => a.sortOrder - b.sortOrder) : [initOtherCost];
    let fixedCost = (vehicleCosts && vehicleCosts.length > 0) ? vehicleCosts.filter((x: any) => x.type === CostType.FixedCost).sort((a: any, b: any) => a.sortOrder - b.sortOrder) : [initFixedCost];
    
    let group = (form.group) ? form.group : null;
    let vehicleGroupId = (group && group.vehicleGroupId && group.vehicleGroupId !== '') ? group.vehicleGroupId : null;
    let vehicleGroupName = (group && group.vehicleGroupName && group.vehicleGroupName !== '') ? group.vehicleGroupName : '';

    let defaultDriver = (form.defaultDriver) ? form.defaultDriver : null;
    let driverId = (defaultDriver && defaultDriver.driverId && defaultDriver.driverId !== '') ? defaultDriver.driverId : null;
    let driverName = (defaultDriver && defaultDriver.driverName && defaultDriver.driverName !== '') ? defaultDriver.driverName : '';
    let phoneNumber = (defaultDriver && defaultDriver.phoneNumber && defaultDriver.phoneNumber !== '') ? defaultDriver.phoneNumber : '';


    data['vehicleId'] = vehicleId;

    data['gpsGateVehicleId'] = gpsGateVehicleId;
    data['gpsGateVehicleName'] = gpsGateVehicleName;

    data['trailerIdName'] = trailerIdName;

    data['gpsGateGeofenceId'] = gpsGateGeofenceId;
    data['gpsGateGeofenceName'] = gpsGateGeofenceName;

    data['yardHasCoordinate'] = yardHasCoordinate;
    data['yardCoordinates'] = yardCoordinates;
    data['yardRadius'] = yardRadius;
    data['yardName'] = yardName;
    data['yardGeofence'] = yardGeofence;

    data['odometer'] = odometer;
    data['currentPosition'] = currentPosition;
    data['utc'] = utc;

    data['vehicleGroupId'] = vehicleGroupId;
    data['vehicleGroupName'] = vehicleGroupName;

    data['vehicleTransporterClientId'] = vehicleTransporterClientId;
    data['vehicleTransporterClientName'] = vehicleTransporterClientName;
    
    data['fuelCost'] = fuelCost;
    data['tyreCost'] = tyreCost;
    data['otherCost'] = otherCost;
    data['fixedCost'] = fixedCost;

    data['driverId'] = driverId;
    data['driverName'] = driverName;
    data['phoneNumber'] = phoneNumber;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.vehicleId){
      data['vehicleId'] = values.vehicleId;
    }

    data['vehicleGroupId'] = values.vehicleGroupId;
    data['vehicleTransporterClientId'] = values.vehicleTransporterClientId;

    data['gpsGateVehicleId'] = values.gpsGateVehicleId;
    data['vehicleName'] = values.gpsGateVehicleName;

    data['trailerIdName'] = values.trailerIdName;
    data['driverId'] = values.driverId;

    data['gpsGateGeofenceId'] = values.gpsGateGeofenceId;
    
    let position = convertCoordToLatLng(values.yardCoordinates);
    data['yardLatitude'] = (position) ? position.lat : null;
    data['yardLongitude'] = (position) ? position.lng : null;
    data['yardRadius'] = values.yardRadius;
    data['yardName'] = values.yardName;
    
    data['fuelCost'] = values.fuelCost;
    data['tyreCost'] = values.tyreCost;

    let count: number = 0;
    let vehicleCosts: Array<Cost> = [];
    if(values.otherCost && values.otherCost.length > 0){
      values.otherCost.forEach((item: any, i: number) => {
        if((values.otherCost.length == 1) && (item.cost === null || item.cost === undefined || item.cost === '')){
          //nop
        } else {
          vehicleCosts.push({
            cost: item.cost,
            type: item.type,
            sortOrder: count,
          })
          count = count+1;
        }
      });
    }
    if(values.fixedCost && values.fixedCost.length > 0){
      values.fixedCost.forEach((item: any, i: number) => {
        if((values.fixedCost.length == 1) && (item.cost === null || item.cost === undefined || item.cost === '')){
          //nop
        } else {
          vehicleCosts.push({
            cost: item.cost,
            type: item.type,
            sortOrder: count,
          })
          count = count+1;
        }
      });
    }
    data['vehicleCosts'] = vehicleCosts;
  }

  return data;
};



interface Cost {
  cost: number|null,
  type: number|null,
  sortOrder: number,
};
export const initOtherCost: Cost = {
  cost: null,
  type: CostType.OtherCost,
  sortOrder: 0,
};
export const initFixedCost: Cost = {
  cost: null,
  type: CostType.FixedCost,
  sortOrder: 0,
};


export interface initialValuesStruct {
  vehicleId: number|null,

  gpsGateVehicleId: number|null,
  gpsGateVehicleName: string,

  trailerIdName: string,
  
  gpsGateGeofenceId: number|null,
  gpsGateGeofenceName: string,

  yardHasCoordinate: boolean,
  yardLatitude: number|null,
  yardLongitude: number|null,
  yardRadius: number|null,
  yardName: string,

  odometer: number|null,
  currentPosition: string,
  utc: string,

  vehicleGroupId: number|null,
  vehicleGroupName: string,

  vehicleTransporterClientId: number|null,
  vehicleTransporterClientName: string,

  fuelCost: number|null,
  tyreCost: number|null,
  otherCost: Array<Cost>,
  fixedCost: Array<Cost>,

  driverId: number|null,
  driverName: string,
  phoneNumber: string,

  data: any,
};
export const initialValues: initialValuesStruct = {
  vehicleId: null,

  gpsGateVehicleId: null,
  gpsGateVehicleName: '',

  trailerIdName: '',

  gpsGateGeofenceId: null,
  gpsGateGeofenceName: '',

  yardHasCoordinate: false,
  yardLatitude: null,
  yardLongitude: null,
  yardRadius: null,
  yardName: '',

  odometer: null,
  currentPosition: '',
  utc: '',

  vehicleGroupId: null,
  vehicleGroupName: '',

  vehicleTransporterClientId: null,
  vehicleTransporterClientName: '',

  fuelCost: null,
  tyreCost: null,
  otherCost: [initOtherCost],
  fixedCost: [initFixedCost],
  
  driverId: null,
  driverName: '',
  phoneNumber: '',

  data: null,
};


interface InitState {
  isLoading: boolean,
  isCopy: boolean,
  id: any|null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,

  isLoadingVehicles: boolean,
  vehicles: Array<any>
  vehicleCenter: any,

  isLoadingPhoneNumber: boolean,
  isLoadingTimeZone: boolean,
  
  isLoadingGeofenceDetails: boolean,
  geofenceDetails: any,
}

function NewReducer() {
  const name = 'vehiclesSlice';


  const initialState: InitState = {
    isLoading: false,
    isCopy: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,

    isLoadingVehicles: false,
    vehicles: [],
    vehicleCenter: DEFAULT_CENTER,
    
    isLoadingPhoneNumber: false,
    isLoadingTimeZone: false,
    
    isLoadingGeofenceDetails: false,
    geofenceDetails: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsCopy: (state: InitState, action: PayloadAction<boolean>) => {
      state.isCopy = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any|null>) => {
      state.id = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },


    setVehicles: (state: InitState, action: PayloadAction<any>) => {
      state.vehicles = action.payload;
    },
    setVehicleCenter: (state: InitState, action: PayloadAction<any>) => {
      state.vehicleCenter = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoadingVehicles = true;
      state.vehicles = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingVehicles = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.vehicles = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;

      if(!state.isCopy){
        state.details = action.payload;
      } else {
        state.isCopy = false;
      }
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startUpdatePhoneNumber: (state: InitState) => {
      state.isLoadingPhoneNumber = true;
    },
    finishUpdatePhoneNumber: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingPhoneNumber = false;
    },
    
    startUpdateTimeZone: (state: InitState) => {
      state.isLoadingTimeZone = true;
    },
    finishUpdateTimeZone: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingTimeZone = false;
    },
    
    startGeofenceDetails: (state: InitState) => {
      state.isLoadingGeofenceDetails = true;
    },
    finishGeofenceDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingGeofenceDetails = false;
      state.geofenceDetails = action.payload;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('vehicle/list', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('vehicle/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('vehicle', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('vehicle', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('vehicle', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callUpdatePhoneNumberApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdatePhoneNumber());
  
        await axios.put('driver', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishUpdatePhoneNumber(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdatePhoneNumber(null));
        });
    },

    callUpdateTimeZoneApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdateTimeZone());
  
        await axios.put('account/update/timezone', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishUpdateTimeZone(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdateTimeZone(null));
        });
    },

    callGeofenceDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startGeofenceDetails());
  
        await axios.get('geofence/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishGeofenceDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishGeofenceDetails(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();