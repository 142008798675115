/* eslint-disable react/jsx-curly-brace-presence */
import { Navigate, useRoutes } from 'react-router-dom';

import { PATH_AFTER_LOGIN } from '../utils/enums';

import { authRoutes } from './auth';
import { privateRoutes } from './private';
import { errorRoutes } from './error';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // ----------------------------------------------------------------------

    // Auth routes
    ...authRoutes,

    // Private routes
    ...privateRoutes,

    // Main routes
    ...errorRoutes,

    // ----------------------------------------------------------------------

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
