import PropTypes from 'prop-types';

import { 
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import PublicHeader from '../sections/PublicHeader';

import { MAIN_BREAKPOINT } from '../utils/enums';

// ----------------------------------------------------------------------

const StyledContent = styled.div`
  display: flex;
  
  ${up(MAIN_BREAKPOINT)} {
    // padding-top: 112px;
    min-height: calc(100vh - 100px);
  }

  ${down(MAIN_BREAKPOINT)} {
    min-height: calc(100vh - 100px);
  }
`;
const StyledChildren = styled.div`
  width: 100%;
`;

// ----------------------------------------------------------------------

export default function PublicLayout({ children }: any) {
  return <Container fluid={'xxxl'} className={'overflow-hidden'}>
    <Row>
      <Col xs={12} className={'g-0'}>
        <PublicHeader />

        <StyledContent className={'align-items-center'}>
          <StyledChildren>{children}</StyledChildren>
        </StyledContent>
      </Col>
    </Row>
  </Container>
}

PublicLayout.propTypes = {
  children: PropTypes.node,
};
