import React from 'react';
import _ from 'lodash';
import { Wrapper } from "@googlemaps/react-wrapper";

import {
    Dropdown,
} from 'react-bootstrap';

import { convertLatLngToCoord, convertCoordToLatLng, isMarkerExist, copyToClipboard } from '../../utils/utils.tsx';
import { MidPointType } from '../../utils/enums';

import MarkerIcon from "../../assets/icons/marker.svg";
import MarkerRouteIcon from "../../assets/icons/marker-route.svg";

import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";


const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;


export const DEFAULT_ZOOM = 2;
export const DEFAULT_CENTER = { lat: 0.12463108622583872, lng: 10.621379999999983 };

export let MAP: any = null;
export const setMap = (ref, options) => {
    MAP = null;
    if(ref && ref.current){
        MAP = new window.google.maps.Map(ref.current, options);
    }
    return MAP;
}


export const addMarker = (markersRef, item) => {
    try {
        let marker = null;
        if(item && item.map){
            marker = new window.google.maps.Marker(item)
            markersRef.current.push(marker);
        }
        return marker;
    } catch(e) {}
}
export const clearAllMarkers = (markersRef) => {
    try {
        if(markersRef.current.length > 0){
            markersRef.current.forEach((marker) => {
                marker.setMap(null);
            });
            markersRef.current = [];
        }
    } catch(e) {}
}

export let CIRCLES: Array<google.maps.Circle> = [];
export const addCircle = (item) => {
    try {
        let circle = null;
        if(item){
            circle = new window.google.maps.Circle({ map: MAP, ...item })
            CIRCLES.push(circle);
        }
        return circle;
    } catch(e) {}
}
export const getCircle = (index) => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            return CIRCLES[index];
        }
    } catch(e) {}
}
export const zoomCircleByCircle = (circle: any) => {
    try {
        if(MAP && circle){
            const bounds = circle.getBounds();
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const setStartCircleRadius = (radius: any = 0) => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            CIRCLES[0].setRadius(parseInt(radius));
        }
    } catch(e) {}
}
export const setStartCircleColor = (color: any = '#CB3A31') => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            CIRCLES[0].setOptions({
              fillColor: color,
              fillOpacity: 0.25,
              strokeColor: color,
              strokeOpacity: 1.0,
              strokeWeight: 1,
            });
        }
    } catch(e) {}
}
export const zoomStartCircle = () => {
    try {
        if(MAP && (CIRCLES && CIRCLES.length > 0)){
            let circle = CIRCLES[0];
            const bounds = circle.getBounds();
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const setEndCircleRadius = (radius: any = 0) => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            CIRCLES[CIRCLES.length-1].setRadius(parseInt(radius));
        }
    } catch(e) {}
}
export const setEndCircleColor = (color: any = '#185CFF') => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            CIRCLES[CIRCLES.length-1].setOptions({
              fillColor: color,
              fillOpacity: 0.25,
              strokeColor: color,
              strokeOpacity: 1.0,
              strokeWeight: 1,
            });
        }
    } catch(e) {}
}
export const zoomEndCircle = () => {
    try {
        if(MAP && (CIRCLES && CIRCLES.length > 0)){
            let circle = CIRCLES[CIRCLES.length-1];
            const bounds = circle.getBounds();
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const setMidCircleRadius = (index: any, radius: any = 0) => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            CIRCLES[index].setRadius(parseInt(radius));
        }
    } catch(e) {}
}
export const setMidCircleColor = (index: any, color: any = '#505363') => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            CIRCLES[index].setOptions({
                fillColor: color,
                fillOpacity: 0.25,
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 1,
            });
        }
    } catch(e) {}
}
export const zoomMidCircle = (index: number) => {
    try {
        if(MAP && (CIRCLES && CIRCLES.length > 0)){
            let circle = CIRCLES[index];
            const bounds = circle.getBounds();
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const clearAllCircles = () => {
    try {
        if(CIRCLES && CIRCLES.length > 0){
            CIRCLES.forEach((circle) => {
                circle.setMap(null);
            });
            CIRCLES = [];
        }
    } catch(e) {}
}
export const clearCircles = (index) => {
    try {
        if(CIRCLES && (CIRCLES.length > 0) && (index > -1)){
            CIRCLES[index].setMap(null);
            CIRCLES.splice(index, 1);
        }
    } catch(e) {}
}


export let POLYGONS: Array<google.maps.Polygon> = [];
export const addPolygon = (coordinates, item) => {
    let polygon = null;
    if(MAP){
        let data = item;
        if(coordinates && coordinates.length > 0){
            data['path'] = coordinates;
        }
        polygon = new window.google.maps.Polygon(data);
        polygon.setMap(MAP);
        POLYGONS.push(polygon);
    }
    return polygon;
}
export const zoomPolygon = (index: number) => {
    try {
        if(MAP && (POLYGONS && POLYGONS.length > 0) && (index > -1)){
            let bounds = new google.maps.LatLngBounds();
            POLYGONS[index].getPath().forEach(function(latLng) {
                bounds.extend(latLng);
            });
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const zoomPolygonByPolygon = (polygon: any) => {
    try {
        if(MAP && polygon){
            let bounds = new google.maps.LatLngBounds();
            polygon.getPath().forEach(function(latLng) {
                bounds.extend(latLng);
            });
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const clearAllPolygons = () => {
    try {
        if(POLYGONS && POLYGONS.length > 0){
            POLYGONS.forEach((polygon) => {
                polygon.setMap(null);
            });
            POLYGONS = [];
        }
    } catch(e) {}
}
export const clearPolygon = (index: number) => {
    try {
        if(MAP && (POLYGONS && POLYGONS.length > 0) && (index > -1)){
            POLYGONS[index].setMap(null);
            POLYGONS.splice(index, 1);
        }
    } catch(e){}
}
export const optionsPolygon = (index: number, options: any = null) => {
    try {
        if(POLYGONS && options && (index > -1)){
            POLYGONS[index].setOptions(options);
        }
    } catch(e) {}
}
export const zoomStartPolygon = () => {
    try {
        if(MAP && POLYGONS){
            let bounds = new google.maps.LatLngBounds();
            POLYGONS[0].getPath().forEach(function(latLng) {
                bounds.extend(latLng);
            });
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const clearStartPolygon = () => {
    try {
        if(POLYGONS && (POLYGONS.length > 0)){
            POLYGONS[0].setMap(null);
            POLYGONS[0] = null;
        }
    } catch(e) {}
}
export const optionsStartPolygon = (options: any = null) => {
    try {
        if(POLYGONS && (POLYGONS.length > 0) && options){
            POLYGONS[0].setOptions(options);
        }
    } catch(e) {}
}
export const zoomEndPolygon = () => {
    try {
        if(MAP && POLYGONS){
            let bounds = new google.maps.LatLngBounds();
            POLYGONS[POLYGONS.length-1].getPath().forEach(function(latLng) {
                bounds.extend(latLng);
            });
    
            const additionalDistance = 2.0;
            const extendedBounds = extendBounds(bounds, additionalDistance);

            MAP.fitBounds(extendedBounds);
        }
    } catch(e){}
}
export const clearEndPolygon = () => {
    try {
        if(POLYGONS && (POLYGONS.length > 0)){
            POLYGONS[POLYGONS.length-1].setMap(null);
            POLYGONS[POLYGONS.length-1] = null;
        }
    } catch(e) {}
}
export const optionsEndPolygon = (options: any = null) => {
    try {
        if(POLYGONS && (POLYGONS.length > 0) && options){
            POLYGONS[POLYGONS.length-1].setOptions(options);
        }
    } catch(e) {}
}
export const extendBounds = (bounds, additionalDistance) => {
    const latDelta = additionalDistance * (bounds.getNorthEast().lat() - bounds.getSouthWest().lat());
    const lngDelta = additionalDistance * (bounds.getNorthEast().lng() - bounds.getSouthWest().lng());

    const extendedBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(bounds.getSouthWest().lat() - latDelta, bounds.getSouthWest().lng() - lngDelta),
        new google.maps.LatLng(bounds.getNorthEast().lat() + latDelta, bounds.getNorthEast().lng() + lngDelta)
    );

    return extendedBounds;
}


export const addPolyline = (map, coordinates) => {
    let polyline = null;
    if(map){
        polyline = new window.google.maps.Polyline({
            path: coordinates,
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            editable: true,
        });
        polyline.setMap(map);
    }
    return polyline;
}
export const addPolylineListener = (polyline, callback) => {
    if(polyline){
      window.google.maps.event.addListener(polyline, 'mouseup', function (e: any) {
        let newPolyline = polyline.getPath().getArray();
        if(callback){
            callback(newPolyline);
        }
      });

      window.google.maps.event.addListener(polyline, 'click', function (e: any) {
        var path = polyline.getPath();
        for (var i = 0; i < path.getLength(); i++) {
            if (path.getAt(i).equals(e.latLng)) {
                path.removeAt(i);
                break;
            }
        }
      });
    }
}


export let DIRECTION_RENDER: google.maps.DirectionsRenderer|null = null;
export let ROUTE_MARKERS: Array<google.maps.Marker> = [];
export let ROUTE_POLYLINES: Array<google.maps.Polyline> = [];
export const setDirectionRender = (directionRender: any) => {
  DIRECTION_RENDER = directionRender;
};
export const RouteMarkerType = {
    None: 0,
    StartPoint: 1,
    EndPoint: 2,
    MidPoint: 3,
    TrackPoint: 4,
}

export const addRoute = (preserveViewport: boolean = true, startPoint: any, endPoint: any, midPoints: Array<any>, routeColor: any = '#9179ED', routeRadius: any = 4, directionsChangedCallback: () => any, totalDistanceCallback: (total: any, directions: any) => any, routeCallback: (newRoute: any, oldRoute: any) => any, markerCallback: (obj: any) => any, onFinishCallback: () => any) => {
    if(MAP){
        clearRoute();
    
        let directionsService = new window.google.maps.DirectionsService();
        let directionsDisplay = new window.google.maps.DirectionsRenderer({
            draggable: true,
            preserveViewport: preserveViewport,
            provideRouteAlternatives: false,
            map: MAP,
            // suppressMarkers: true,
            suppressInfoWindows: false,
            polylineOptions: {
                strokeColor: routeColor,
                strokeWeight: routeRadius,
                strokeOpacity: 1.0,
            },
            markerOptions: {
                draggable: false,
                icon: {
                    url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"><circle cx="0.5" cy="0.5" r="0.5" fill="transparent"/></svg>',
                    scaledSize: new window.google.maps.Size(29, 34),
                    labelOrigin: new window.google.maps.Point(13, 41),
                },
                // zIndex: -1,
            }
        });
        setDirectionRender(directionsDisplay);

        // On every direction change i render all markers and get all coordinates
        window.google.maps.event.addListener(directionsDisplay, 'directions_changed', async function () {
            let directions = directionsDisplay.getDirections();
            
            // Get total distance
            if(totalDistanceCallback){
                let total = computeTotalDistance(directions);
                totalDistanceCallback(total, directions);
            }

            // Get prev directions object
            let oldDirections = null;
            if(directionsChangedCallback){
                let obj = directionsChangedCallback();
                oldDirections = (obj && obj.oldDirections) ? obj.oldDirections : null;
            }

            // Magic
            if(routeCallback){
                clearRouteMarkers();
                clearRouteMarkersPolylines();
                clearAllCircles();

                let requestRoute = await renderRouteFromRequest(directions);
                let responseRoute = await renderRouteFromResponse(directions, oldDirections);
                let colors = routeCallback(requestRoute, responseRoute);

                let count = 0;
                addRouteMarker({
                    map: MAP,
                    position: requestRoute.startPoint.position,
                    draggable: true,
                    icon: {
                        url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon(colors.startPointColor, 'start-point-icon'),
                        scaledSize: new google.maps.Size(26, 32),
                        labelOrigin: new google.maps.Point(13, 41),
                    },
                    zIndex: 3,
                }, count, requestRoute.startPoint.position, responseRoute.startPoint.position, RouteMarkerType.StartPoint, markerCallback);

                if(requestRoute && requestRoute.midPoints && requestRoute.midPoints.length > 0){
                    requestRoute.midPoints.forEach((midPoint, i) => {
                        count = count+1;
                        if(midPoint.position && midPoint.stopover){
                            addRouteMarker({
                                map: MAP,
                                position: midPoint.position,
                                draggable: true,
                                icon: {
                                    url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon(colors.midPointColors[i], 'mid-point-icon mid-point-' + count),
                                    scaledSize: new google.maps.Size(26, 32),
                                    labelOrigin: new google.maps.Point(13, 41),
                                },
                                zIndex: 3,
                            }, count, (midPoint.stopover ? midPoint.position : null), responseRoute.midPoints[i].position, RouteMarkerType.MidPoint, markerCallback);
                        }
                    });
                }
                
                count = count+1;
                addRouteMarker({
                    map: MAP,
                    position: requestRoute.endPoint.position,
                    draggable: true,
                    icon: {
                        url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon(colors.endPointColor, 'end-point-icon'),
                        scaledSize: new google.maps.Size(26, 32),
                        labelOrigin: new google.maps.Point(13, 41),
                    },
                    zIndex: 3,
                }, count, requestRoute.endPoint.position, responseRoute.endPoint.position, RouteMarkerType.EndPoint, markerCallback);

                if(onFinishCallback){
                    onFinishCallback();
                }
            }

            // Change marker icon
            // setTimeout(() => {
            //     const elements = document.querySelectorAll('#map .gm-style > div:nth-child(1) > div:nth-child(1) > div:nth-child(4) > div:has(img) img');
            //     if(elements && elements.length > 0){
            //         let count = 0;
            //         elements.forEach((img, i) => {
            //             if(i === 0){
            //                 const svgElement = routeMarkerIcon('green', 'start-point-icon');
            //                 img.parentNode.appendChild(svgElement, img);
            //             } else if(i === (elements.length-1)){
            //                 const svgElement = routeMarkerIcon('red', 'end-point-icon');
            //                 img.parentNode.appendChild(svgElement, img);
            //             } else {
            //                 const svgElement = routeMarkerIcon('blue', 'mid-point-icon mid-point-' + count);
            //                 img.parentNode.appendChild(svgElement, img);
            //                 count = count+1;
            //             }
            //         });
            //     }
            // }, 100);
        });


        var start = new window.google.maps.LatLng(startPoint);
        var end = new window.google.maps.LatLng(endPoint);
        var waypoints = midPoints.map(waypoint => ({
            location: waypoint.location,
            stopover: waypoint.stopover,
        }));

        
        directionsService.route(
            {
              origin: start,
              destination: end,
              waypoints: waypoints,
              travelMode: 'DRIVING',
            },
            function (response, status) {
              if (status === 'OK') {
                directionsDisplay.setDirections(response);
              } else {
                console.info('Directions request failed due to ' + status);
              }
            }
        );
    }
}
export const addViewRoute = (preserveViewport: boolean = true, startPoint: any, endPoint: any, midPoints: Array<any>, routeColor: any = '#9179ED', routeRadius: any = 4, directionsChangedCallback: () => any, totalDistanceCallback: (total: any, directions: any) => any, routeCallback: (newRoute: any, oldRoute: any) => any, showMarkersCallback: (obj: any) => any , markerCallback: (obj: any) => any, onFinishCallback: () => any) => {
    if(MAP){
        clearRoute();
    
        let directionsService = new window.google.maps.DirectionsService();
        let directionsDisplay = new window.google.maps.DirectionsRenderer({
            draggable: false,
            preserveViewport: preserveViewport,
            provideRouteAlternatives: false,
            map: MAP,
            // suppressMarkers: true,
            suppressInfoWindows: false,
            polylineOptions: {
                strokeColor: routeColor,
                strokeWeight: routeRadius,
                strokeOpacity: 1.0,
            },
            markerOptions: {
                draggable: false,
                icon: {
                    url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"><circle cx="0.5" cy="0.5" r="0.5" fill="transparent"/></svg>',
                    scaledSize: new window.google.maps.Size(29, 34),
                    labelOrigin: new window.google.maps.Point(13, 41),
                },
                // zIndex: -1,
            }
        });
        setDirectionRender(directionsDisplay);

        // On every direction change i render all markers and get all coordinates
        window.google.maps.event.addListener(directionsDisplay, 'directions_changed', async function () {
            let directions = directionsDisplay.getDirections();
            
            // Get total distance
            if(totalDistanceCallback){
                let total = computeTotalDistance(directions);
                totalDistanceCallback(total, directions);
            }

            // Get prev directions object
            let oldDirections = null;
            if(directionsChangedCallback){
                let obj = directionsChangedCallback();
                oldDirections = (obj && obj.oldDirections) ? obj.oldDirections : null;
            }

            // Magic
            if(routeCallback){
                clearRouteMarkers();
                clearRouteMarkersPolylines();
                clearAllCircles();

                let requestRoute = await renderRouteFromRequest(directions);
                let responseRoute = await renderRouteFromResponse(directions, oldDirections);
                let colors = routeCallback(requestRoute, responseRoute);

                // Show markers
                if(showMarkersCallback){
                    let count = 0;
                    showMarkersCallback({
                        map: MAP,
                        position: requestRoute.startPoint.position,
                        draggable: false,
                        icon: {
                            url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon(colors.startPointColor, 'start-point-icon'),
                            scaledSize: new google.maps.Size(26, 32),
                            labelOrigin: new google.maps.Point(13, 41),
                        },
                        zIndex: 3,
                        cls: 'start-point-icon',
                        count: count,
                        index: null,
                        color: colors.startPointColor,
                        requestPosition: requestRoute.startPoint.position, 
                        responsePosition: responseRoute.startPoint.position, 
                        type: RouteMarkerType.StartPoint, 
                        callback: markerCallback
                    });

                    if(requestRoute && requestRoute.midPoints && requestRoute.midPoints.length > 0){
                        requestRoute.midPoints.forEach((midPoint, i) => {
                            count = count+1;
                            if(midPoint.position && midPoint.stopover){
                                showMarkersCallback({
                                    map: MAP,
                                    position: midPoint.position,
                                    draggable: false,
                                    icon: {
                                        url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon(colors.midPointColors[i], 'mid-point-icon mid-point-' + count),
                                        scaledSize: new google.maps.Size(26, 32),
                                        labelOrigin: new google.maps.Point(13, 41),
                                    },
                                    zIndex: 3,
                                    cls: 'mid-point-icon mid-point-' + count,
                                    index: i,
                                    count: count, 
                                    color: colors.midPointColors[i],
                                    requestPosition: (midPoint.stopover ? midPoint.position : null), 
                                    responsePosition: responseRoute.midPoints[i].position, 
                                    type: RouteMarkerType.MidPoint, 
                                    callback: markerCallback
                                });
                            }
                        });
                    }
                    
                    count = count+1;
                    showMarkersCallback({
                        map: MAP,
                        position: requestRoute.endPoint.position,
                        draggable: false,
                        icon: {
                            url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon(colors.endPointColor, 'end-point-icon'),
                            scaledSize: new google.maps.Size(26, 32),
                            labelOrigin: new google.maps.Point(13, 41),
                        },
                        zIndex: 3,
                        cls: 'end-point-icon',
                        index: null,
                        count: count, 
                        color: colors.endPointColor,
                        requestPosition: requestRoute.endPoint.position, 
                        responsePosition: responseRoute.endPoint.position, 
                        type: RouteMarkerType.EndPoint, 
                        callback: markerCallback
                    });
                }


                if(onFinishCallback){
                    onFinishCallback();
                }
            }

            // Change marker icon
            // setTimeout(() => {
            //     const elements = document.querySelectorAll('#map .gm-style > div:nth-child(1) > div:nth-child(1) > div:nth-child(4) > div:has(img) img');
            //     if(elements && elements.length > 0){
            //         let count = 0;
            //         elements.forEach((img, i) => {
            //             if(i === 0){
            //                 const svgElement = routeMarkerIcon('green', 'start-point-icon');
            //                 img.parentNode.appendChild(svgElement, img);
            //             } else if(i === (elements.length-1)){
            //                 const svgElement = routeMarkerIcon('red', 'end-point-icon');
            //                 img.parentNode.appendChild(svgElement, img);
            //             } else {
            //                 const svgElement = routeMarkerIcon('blue', 'mid-point-icon mid-point-' + count);
            //                 img.parentNode.appendChild(svgElement, img);
            //                 count = count+1;
            //             }
            //         });
            //     }
            // }, 100);
        });


        var start = new window.google.maps.LatLng(startPoint);
        var end = new window.google.maps.LatLng(endPoint);
        var waypoints = midPoints.map(waypoint => ({
            location: waypoint.location,
            stopover: waypoint.stopover,
        }));

        
        directionsService.route(
            {
              origin: start,
              destination: end,
              waypoints: waypoints,
              travelMode: 'DRIVING',
            },
            function (response, status) {
              if (status === 'OK') {
                directionsDisplay.setDirections(response);
              } else {
                console.info('Directions request failed due to ' + status);
              }
            }
        );
    }
}
export const renderRouteFromRequest = (directions: any) => {
    try {
        let startPoint = { position: null, sortOrder: 0, stopover: true };
        let endPoint = { position: null, sortOrder: 1, stopover: true };
        let midPoints = [];

        let count = 0;
        if(directions && directions.request && directions.request.origin && directions.request.origin.location){
            let start = { lat: directions.request.origin.location.lat(), lng: directions.request.origin.location.lng() };
            startPoint.position = start;
            startPoint.sortOrder = count;
            startPoint.stopover = true;
        }

        if(directions && directions.request && directions.request.waypoints && directions.request.waypoints.length > 0){
            directions.request.waypoints.forEach((midPoint, i) => {
                count = count+1;
                let mid = { lat: null, lng: null };
                try {
                    mid = { lat: midPoint.location.location.lat(), lng: midPoint.location.location.lng() };
                }catch(e){
                    if(mid){
                        try {
                            mid = { lat: midPoint.location.lat(), lng: midPoint.location.lng() };
                        }catch(e){}
                    }
                }

                midPoints.push({
                    position: mid,
                    sortOrder: count,
                    stopover: midPoint.stopover,
                });
            });
        }

        count = count+1;
        if(directions && directions.request && directions.request.destination && directions.request.destination.location){
            let end = { lat: directions.request.destination.location.lat(), lng: directions.request.destination.location.lng() };
            endPoint.position = end;
            endPoint.sortOrder = count;
            endPoint.stopover = true;
        }
        
        return {
            startPoint: startPoint,   
            endPoint: endPoint,   
            midPoints: midPoints,   
        }
    } catch(e){
        return {
            startPoint: { position: null, sortOrder: 0, stopover: true },   
            endPoint: { position: null, sortOrder: 1, stopover: true },   
            midPoints: [],
        }
    }
}
export const renderRouteFromResponse = (directions: any, oldRoute: any) => {
    try {
        // Prepare empty start/end/mid
        let startPoint = { position: null, sortOrder: 0, stopover: true };
        let endPoint = { position: null, sortOrder: 1, stopover: true };
        let midPoints = [];

        if(directions && directions.routes && directions.routes.length > 0 && directions.routes[0] && directions.routes[0].legs && directions.routes[0].legs.length > 0){
            let length = directions.routes[0].legs.length;
            
            // Loop through legs array from directions object
            for(let i = 0; i < length; i++) {
                let leg = directions.routes[0].legs[i];

                // If there is only 1 leg, then just use start/stop and all Track points
                if(length === 1){
                    // Start Point
                    let start = { lat: leg.start_location.lat(), lng: leg.start_location.lng() };
                    startPoint.position = start;
                    startPoint.sortOrder = 0;
                    startPoint.stopover = true;

                    // Loop through Track Point
                    if(leg.via_waypoints && leg.via_waypoints.length > 0){
                        for(let j = 0; j < leg.via_waypoints.length; j++) {
                            let track = leg.via_waypoints[j];
                            let coordinate = { lat: track.lat(), lng: track.lng() };

                            midPoints.push({ position: coordinate, sortOrder: (j+1), stopover: false });
                        }
                    }

                    // End Point
                    let end = { lat: leg.end_location.lat(), lng: leg.end_location.lng() };
                    endPoint.position = end;
                    endPoint.sortOrder = (midPoints.length+1);
                    endPoint.stopover = true;

                } else {
                    // If there is more the 1 legs, then do some logic

                    // If first leg, then use Start Point, then use all Track Points and use Mid Point
                    if(i === 0){
                        // Start Point
                        let start = { lat: leg.start_location.lat(), lng: leg.start_location.lng() };
                        startPoint.position = start;
                        startPoint.sortOrder = 0;
                        startPoint.stopover = true;

                        // Help us with sortOrder
                        let startLength = (midPoints && midPoints.length > 0) ? midPoints.length : 0;
                        let midLength = (leg.via_waypoints && leg.via_waypoints.length > 0) ? leg.via_waypoints.length : 0;

                        // Track Points
                        if(leg.via_waypoints && leg.via_waypoints.length > 0){
                            for(let j = 0; j < leg.via_waypoints.length; j++) {
                                let track = leg.via_waypoints[j];
                                let coordinate = { lat: track.lat(), lng: track.lng() };
                                
                                midPoints.push({ position: coordinate, sortOrder: (startLength+(j+1)), stopover: false });
                            }
                        }

                        // Mid Point
                        let mid = { lat: leg.end_location.lat(), lng: leg.end_location.lng() };
                        midPoints.push({ position: mid, sortOrder: (startLength+(midLength+1)), stopover: true });

                    } else if(i === (length-1)){
                        // If last leg, then use all Track Points and End Point

                        // Help us with sortOrder
                        let startLength = (midPoints && midPoints.length > 0) ? midPoints.length : 0;
                        let midLength = (leg.via_waypoints && leg.via_waypoints.length > 0) ? leg.via_waypoints.length : 0;

                        // Track Points
                        if(leg.via_waypoints && leg.via_waypoints.length > 0){
                            for(let j = 0; j < leg.via_waypoints.length; j++) {
                                let track = leg.via_waypoints[j];
                                let coordinate = { lat: track.lat(), lng: track.lng() };
                            
                                midPoints.push({ position: coordinate, sortOrder: (startLength+(j+1)), stopover: false });
                            }
                        }

                        // End Point
                        let end = { lat: leg.end_location.lat(), lng: leg.end_location.lng() };
                        endPoint.position = end;
                        endPoint.sortOrder = (midLength+1);
                        endPoint.stopover = true;

                    } else {
                        // If some leg between first and last, then use all Track Points and Mid Point

                        // Help us with sortOrder
                        let startLength = (midPoints && midPoints.length > 0) ? midPoints.length : 0;
                        let midLength = (leg.via_waypoints && leg.via_waypoints.length > 0) ? leg.via_waypoints.length : 0;

                        // Track Points
                        if(leg.via_waypoints && leg.via_waypoints.length > 0){
                            for(let j = 0; j < leg.via_waypoints.length; j++) {
                                let track = leg.via_waypoints[j];
                                let coordinate = { lat: track.lat(), lng: track.lng() };
                            
                                midPoints.push({ position: coordinate, sortOrder: (startLength+(j+1)), stopover: false });
                            }
                        }

                        // Mid Point
                        let mid = { lat: leg.end_location.lat(), lng: leg.end_location.lng() };
                        midPoints.push({ position: mid, sortOrder: (startLength+(midLength+1)), stopover: true });
                    }
                }
            }
        }

        // There is Start/End/Mid Points with order from directions service object
        return {
            startPoint: startPoint,   
            endPoint: endPoint,   
            midPoints: midPoints,
        }
    } catch(e){
        return {
            startPoint: { position: null, sortOrder: 0, stopover: true },
            endPoint: { position: null, sortOrder: 1, stopover: true },
            midPoints: [],
        }
    }
}
export const routeMarkerSVGIcon = (color = '#505363', cls = '') => {
    return encodeURIComponent(`<svg class="${cls}" width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M27.1818 14.0909C27.1818 24.2727 14.0909 33 14.0909 33C14.0909 33 1 24.2727 1 14.0909C1 6.861 6.861 1 14.0909 1C21.3208 1 27.1818 6.861 27.1818 14.0909Z" fill="${color}" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0907 18.4543C16.5007 18.4543 18.4543 16.5007 18.4543 14.0907C18.4543 11.6807 16.5007 9.72705 14.0907 9.72705C11.6807 9.72705 9.72705 11.6807 9.72705 14.0907C9.72705 16.5007 11.6807 18.4543 14.0907 18.4543Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`)
}
export const routeMarkerIcon = (color = '#505363', cls = '') => {
    const svgHTML = `<svg class="${cls}" width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="fill" fill-rule="evenodd" clip-rule="evenodd" d="M27.1818 14.0909C27.1818 24.2727 14.0909 33 14.0909 33C14.0909 33 1 24.2727 1 14.0909C1 6.861 6.861 1 14.0909 1C21.3208 1 27.1818 6.861 27.1818 14.0909Z" fill="${color}" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0907 18.4543C16.5007 18.4543 18.4543 16.5007 18.4543 14.0907C18.4543 11.6807 16.5007 9.72705 14.0907 9.72705C11.6807 9.72705 9.72705 11.6807 9.72705 14.0907C9.72705 16.5007 11.6807 18.4543 14.0907 18.4543Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
    const div = document.createElement('div');
    div.innerHTML = svgHTML;
    return div.querySelector('svg');
}
export const changeRouteColor = (routeColor, routeRadius) => {
    try {
        if(DIRECTION_RENDER){
            let directions = DIRECTION_RENDER.getDirections();
            DIRECTION_RENDER.setOptions({
                polylineOptions: {
                    strokeColor: routeColor,
                    strokeWeight: routeRadius,
                    strokeOpacity: 1.0,
                }
            }); 
            DIRECTION_RENDER.setDirections(directions);
        }
    } catch(e){}
}
export const computeTotalDistance = (result) => {
    var total = 0;
    try {
        var myroute = result.routes[0];
        for (var i = 0; i < myroute.legs.length; i++) {
            total += myroute.legs[i].distance.value;
        }
        total = total / 1000;
    } catch(e){}
    return total;
}
export const clearRoute = () => {
    try {
        if(DIRECTION_RENDER){
            DIRECTION_RENDER.setMap(null);
            DIRECTION_RENDER = null;
        }
    } catch(e) {}

    clearRouteMarkers();
    clearRouteMarkersPolylines();
    clearAllCircles();
    clearAllPolygons();
}
export const clearRouteMarkers = () => {
    try {
        if(ROUTE_MARKERS && ROUTE_MARKERS.length > 0){
            ROUTE_MARKERS.forEach((marker) => {
                marker.setMap(null);
            });
            ROUTE_MARKERS = [];
        }
    } catch(e) {}
}
export const clearRouteMarkersPolylines = () => {
    try {
        if(ROUTE_POLYLINES && ROUTE_POLYLINES.length > 0){
            ROUTE_POLYLINES.forEach((polyline) => {
                polyline.setMap(null);
            });
            ROUTE_POLYLINES = [];
        }
    } catch(e) {}
}
export const addRouteMarker = (item, index, startPoint, endPoint, type, markerCallback: (obj: any) => void) => {
    try {
        let marker = null;
        if(item && item.map && startPoint){
            let isExist = isMarkerExist(ROUTE_MARKERS, startPoint);

            if(!isExist){
                marker = new window.google.maps.Marker(item)
                ROUTE_MARKERS.push(marker);
    
                marker.addListener('dragstart', (event) => {
                    clearRoutePolyline(index);
                    clearCircles(index);
                });
    
                marker.addListener('dragend', (event) => {
                    addRoutePolyline([event.latLng, endPoint], index);

                    if(markerCallback){
                        markerCallback({
                            index: index,
                            item: item,
                            position: event.latLng,
                            type, type
                        });
                    }
                });
    
                addRoutePolyline([startPoint, endPoint]);
            }
        }
        return marker;
    } catch(e) {}
}
export const optionsRouteMarker = (index: number, options: any = null) => {
    try {
        if(ROUTE_MARKERS && options && (index > -1)){
            ROUTE_MARKERS[index].setOptions(options);
        }
    } catch(e) {}
}
export const optionsEndRouteMarker = (options: any = null) => {
    try {
        if(ROUTE_MARKERS && ROUTE_MARKERS.length > 0){
            ROUTE_MARKERS[ROUTE_MARKERS.length-1].setOptions(options);
        }
    } catch(e) {}
}
export const optionsStartRouteMarker = (options: any = null) => {
    try {
        if(ROUTE_MARKERS && ROUTE_MARKERS.length > 0){
            ROUTE_MARKERS[0].setOptions(options);
        }
    } catch(e) {}
}
export const clearRouteMarker = (index) => {
    try {
        if(ROUTE_MARKERS && (ROUTE_MARKERS.length > 0) && (index > -1)){
            ROUTE_MARKERS[index].setMap(null);
            ROUTE_MARKERS.splice(index, 1);
        }
    } catch(e) {}

    clearRoutePolyline();
}
export const addRoutePolyline = (path, index = null) => {
    try {
        var dashedLine = new google.maps.Polyline({
            map: MAP,
            path: path,
            geodesic: true,
            strokeColor: '#000000',
            strokeOpacity: 0,
            icons: [{
                icon: {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: 0.5,
                    scale: 2
                },
                offset: '0',
                repeat: '10px'
            }]
        });

        if(index != null){
            ROUTE_POLYLINES.splice(index, 0, dashedLine);
        } else {
            ROUTE_POLYLINES.push(dashedLine);
        }
    } catch(e) {}
}
export const clearRoutePolyline = (index) => {
    try {
        if(ROUTE_POLYLINES && (ROUTE_POLYLINES.length > 0) && (index > -1)){
            ROUTE_POLYLINES[index].setMap(null);
            ROUTE_POLYLINES.splice(index, 1);
        }
    } catch(e) {}
}


const GoogleMap = ({ canConextMenu, conextMenu, customButtons = [], ...props }: any) => {
    const [showContextMenu, setShowContextMenu] = React.useState(null);
  

    React.useEffect(() => {
        const handleOutsideClick = (event) => {
            try {
                setShowContextMenu(null);
            }catch(e){}
        };
    
        document.addEventListener('mouseup', handleOutsideClick);
    
        return () => {
          document.removeEventListener('mouseup', handleOutsideClick);
        };
    }, []);


    const handleContextMenu = (event: any) => {
        event.domEvent.preventDefault();
        
        if(canConextMenu){
            try {
                let lat = event.latLng.lat();
                let lng = event.latLng.lng();
                let coordinate = convertLatLngToCoord(lat, lng);
    
                setShowContextMenu({
                    position: { 
                        x: event.domEvent.clientX,
                        y: event.domEvent.clientY,
                    },
                    location: { 
                        lat: lat,
                        lng: lng,
                        coordinate: coordinate,
                    }
                });
            }catch(e){}
        }
    };

    const handleOptionSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            copyToClipboard(showContextMenu?.location?.coordinate);
            setShowContextMenu(null);
        }catch(e){}
    };
    
    return <Wrapper>
        {showContextMenu ? conextMenu ? conextMenu(showContextMenu) : <div
            className="custom-map-context-menu"
            style={{ top: showContextMenu?.position?.y, left: showContextMenu?.position?.x }}
        >
            <Dropdown.Menu show>
                <Dropdown.Item onMouseDown={handleOptionSelect}><CopyIcon /><small className='ps-2'>{showContextMenu?.location?.coordinate}</small></Dropdown.Item>
            </Dropdown.Menu>
        </div> : null}

        <Gmap {...props} onContextMenu={handleContextMenu} customButtons={customButtons} />
    </Wrapper>
}

const Gmap = ({ options, onLoad, onZoom, onContextMenu, customButtons }) => {
    const ref = React.useRef(null);

    React.useEffect(() => {
      if (ref.current) {
        let map = setMap(ref, options);

        if (map) {
            if(onLoad){
                onLoad(map, ref);
            }
            
            map.addListener('contextmenu', (event) => {
                if (onContextMenu) {
                    onContextMenu(event);
                }
            });
        }
      }
    }, [ref]);

    React.useEffect(() => {
        if (MAP) {
            if(customButtons && customButtons.length > 0){
                const btnContainer = document.createElement('div');
                btnContainer.innerHTML = '';
                customButtons.forEach((item: any, i: number) => {
                    createButtonControl(MAP, btnContainer, item)
                });
            }
        }
    }, [customButtons]);
    

    const createButtonControl = (map = null, btnContainer = null, { element = 'button', text = '', className = '', visible = true, disabled = false, callback = (e) => {} }: any) => {
        try {
            if(map && btnContainer){
                let btn = document.createElement(element);
                btn.className = className;
                btn.textContent = text;
                btn.disabled = disabled;
                btn.style.display = visible ? 'inline-block' : 'none';
                btn.addEventListener("click", callback);

                btnContainer.appendChild(btn);
                const existingContainer = map.controls[window.google.maps.ControlPosition.TOP_LEFT].getAt(0);
                if(existingContainer){
                    while (existingContainer.firstChild) {
                        existingContainer.removeChild(existingContainer.lastChild);
                    }
                    existingContainer.appendChild(btnContainer);
                } else {
                    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(btnContainer);
                }
            }
        }catch(e){}
    }


    return <div ref={ref} id="map" style={{ width: '100%', height: '100%' }} />;
}


export default GoogleMap;
