/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { info } from './utils';


interface InitState {
  isLive: boolean,
  liveItems: Array<any>,

  liveValue: number,

  nextLiveRefresh: any,
  liveTimeLeft: any,
}

function NewReducer() {
  const name = 'liveSlice';


  let intervalId: any = null;

  const defaultIndex = 1;
  const defaultLiveItems = [ 
    {
      value: 0,
      label: 'None',
    },
    {
      value: ((60 * 1000) * 1),
      label: 'Every 1 min',
    },
    {
      value: ((60 * 1000) * 5),
      label: 'Every 5 min',
    },
    {
      value: ((60 * 1000) * 10),
      label: 'Every 10 min',
    },
    {
      value: ((60 * 1000) * 15),
      label: 'Every 15 min',
    },
    // {
    //   value: ((60 * 1000) * 30),
    //   label: 'Every 30 min',
    // }
  ]


  const initialState: InitState = {
    liveItems: defaultLiveItems,
    isLive: false,

    liveValue: defaultLiveItems[defaultIndex].value,

    nextLiveRefresh: null,
    liveTimeLeft: null,
  };


  const reducers = {
    setLive: (state: InitState, action: PayloadAction<any>) => {
      state.isLive = action.payload;

      if(action.payload){
        let nextDate: any =  new Date(new Date().getTime() + state.liveValue);
        state.nextLiveRefresh = (state.liveValue > 0) ? nextDate.toISOString() : null;
        state.liveTimeLeft = (nextDate > 0) ? Math.floor((nextDate - Date.now()) / 1000) : null;
      }
    },

    setDefaultValue: (state: InitState, action: PayloadAction<number>) => {
      let liveItems = state.liveItems;
      state.liveValue = liveItems[action.payload].value;
    },

    setLiveItems: (state: InitState, action: PayloadAction<any>) => {
      state.liveItems = (action.payload && action.payload.length > 0) ? action.payload : defaultLiveItems;
    },

    selectedValue: (state: InitState, action: PayloadAction<any>) => {
      const item = (action.payload) ? action.payload : null;
      let val = item ? item.value : defaultLiveItems[defaultIndex].value;

      state.liveTimeLeft = null;
      state.liveValue = val;

      let nextDate: any =  new Date(new Date().getTime() + val);
      state.nextLiveRefresh = (val > 0) ? nextDate.toISOString() : null;
      state.liveTimeLeft = (nextDate > 0) ? Math.floor((nextDate - Date.now()) / 1000) : null;
    },

    updateTimeLeft: (state: InitState) => {
      if(state.isLive){
        let nextDate: any = new Date(state.nextLiveRefresh)
        state.liveTimeLeft = (nextDate > 0) ? Math.floor((nextDate - Date.now()) / 1000) : null;
      }
    },

    resetTimeLeft: (state: InitState) => {
      let nextDate: any =  new Date(new Date().getTime() + state.liveValue);
      state.nextLiveRefresh = (state.liveValue > 0) ? nextDate.toISOString() : null;
      state.liveTimeLeft = (nextDate > 0) ? Math.floor((nextDate - Date.now()) / 1000) : null;
    },
  };


  const apis = {
    setLiveRefresh: (callback: () => void) => async (dispatch: any, getState: any) => {
      const { liveValue, liveTimeLeft } = getState().liveSlice;

      if(liveValue > 0){
        if(liveTimeLeft == 0){
          dispatch(actions.resetTimeLeft());
          callback();
        } else {
          if(liveTimeLeft == 15){
            info('Info', 'List will be refreshed in 15 seconds');
          }
        }
      }
    },

    setLiveTimer: (state: boolean) => async (dispatch: any, getState: any) => {
      if(state){
        intervalId = setInterval(() => {
          const { liveValue } = getState().liveSlice;
          if(liveValue > 0){
            dispatch(actions.updateTimeLeft());
          }
        }, 1000);
      } else {
        clearInterval(intervalId);
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();